import React, { useState } from "react";
import PopUp from "./PopUp";
import { Link } from "react-router-dom";
import OpenPositions from "Components/career/OpenPositions";
export default function Careers() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  return (
    <>
      <div
        className="flex-col flex justify-center bg-background-dark md:pt-20"
        style={{ marginTop: "0rem", paddingTop: "0rem" }}
      >
        <div
          className="grid grid-cols-1 lg:grid-cols-2 pt-6 lg:px-8 sm:pt-28"
          style={{
            background:
              "linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)",
          }}
        >
          <div
            className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6"
            style={{ maxWidth: "100%" }}
          >
            <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">
              CAREERS AT RAPTORX.AI
            </div>
            <div className="text-4xl lg:text-5xl text-white font-semibold">
              Join our team and make a difference.
            </div>
            <div className="fraud-handling-info">
              Join a rapidly growing team of innovative, tech-driven thinkers
              who are transforming trust and safety technology. Explore open
              positions, check out our benefits, and learn about our culture.
            </div>
            <Link to="/careers/openpositions">
              <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-0 border-none text-white">
                See Open Positions
              </button>
            </Link>
          </div>
          <div className="mac-image-wrapper mt-8">
            <img src="../images/mac-image.svg" className="" />
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol above headerTextAlign-center">
                <p id="offers" className="preHeading">
                  WHY PANOPLIA?
                </p>
                <h2 className="header-text text-center text-white">
                  Learn to power your possible
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center text-white opacity-75">
                    We strive to create an environment where employees feel
                    cared for, their ideas are valued, and they have
                    opportunities to grow.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="panopila icon identity verification"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text text-white ">
                  Full Spectrum Benefits
                </h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    We care about our employees and their families. That's why
                    we offer comprehensive health benefits that include medical,
                    dental, vision, and more.
                  </p>
                </div>
                <div className="multicolLinkRow row align-items-end"></div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="lock credit card"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text text-white ">Retirement Plans</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    PANOPLIA can help you achieve your long-term financial
                    goals. We offer various saving plans, including 401(k) with
                    employer match.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Account Protection Shield and Star"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text text-white">
                  Paid Time Off & Holidays
                </h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    In addition to paid holidays and time off, we also cover
                    volunteer hours with any organization you choose.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="transaction-image-wrapper">
          <div
            className="authorized-section md:order-last"
            style={{ maxWidth: "38rem" }}
          >
            <img src="../images/mac-image.svg"></img>
          </div>
          <div className="wallet-info">
            <div id="personalized-solution">OUR CULTURE</div>
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Balance between work and play
            </div>
            <div className="fraud-handling-info">
              We know that the best results comes from employees who have a
              healthy work-life balance. That's why we offer flexible work
              schedules with some remote opportunities. We also understand the
              importance of connection and host frequent team-building outings.
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="transaction-image-wrapper">
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
          <div className="wallet-info">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Open positions at PANOPLIA
            </div>
            <div className="fraud-handling-info">
              We're always looking for smart, creative people to join our team.
              And as part of the Equifax family, we have a wide range of
              opportunities to offer you. Visit the Equifax careers page to view
              open positions.
            </div>
            <Link to="/careers/openpositions">
              <div className="btnColor rounded-md flex items-start px-6 py-3 justify-centers gap-4 mt-0 border-none text-white cursor-pointer ">
                View open positions
              </div>
            </Link>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
