import React, { useState } from "react";
import PopUp from "../PopUp";
export default function About() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  const gradientStyle = {
    background: 'linear-gradient(45deg, #2ecc71, #3498db)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
  const background ={
    background: '#070707e6',
  }
  return (
    <>
      <div
        style={background}
        className="text-white text-center  lg:mt-[5rem] xl:mt-[5rem]"
      >
        <div className="w-full pt-20 pb-10 pl-3 pr-3">
          <div className="font-extrabold"><span style={gradientStyle}>OUR MISSION</span></div>
          <div className="text-3xl sm:text-base md:text-3xl lg:text-3xl xl:text-6xl">
            Delivering <span className="font-bold" style={gradientStyle}>confidence</span> <br />
            in every interaction
          </div>{" "}
          <br />
          <div>
            We help businesses grow safely and confidently — no matter the
            industry or the challenges <br />
            they face. And we make that process as simple, accurate, and
            efficient as possible for our <br />
            clients.
          </div>
        </div>
      </div>
      <div
        className="content-container text-white sm:mt-[1rem] lg:mt-[5rem] xl:mt-[5rem]"
      >
        <div className="transaction-image-wrapper mb-10">
          <div className="wallet-info">
            <div className="font-bold text-5xl"> <span style={gradientStyle}>Our story</span></div>
            <p>
              Kount’s earliest innovations began in 1999. Dr. Timothy Barber
              developed and patented multi-layer device fingerprinting and proxy
              piercing to detect fraud on a retail platform. Originally, Kount
              was intended to be a payment gateway for that platform. And fraud
              control was part of the solution.
            </p>
            <p>
              However, after multiple interactions with public companies that
              struggled to keep chargeback rates down, the team realized fraud
              control was a bigger part of the picture than they thought — and
              the core fraud technologies developed by Dr. Barber were the
              foundation of the solution that the market actually needed.
            </p>
            <p>
              {" "}
              In 2006, the idea for Kount was created during a sushi lunch
              between Dr. Barber and current Kount General Manager Brad
              Wiskirchen. Just like in the movies, the idea was literally drawn
              up on a napkin. After more than a decade of exponential growth and
              innovation, Kount was acquired by Equifax in 2021.
            </p>
            Today, Kount is trust and safety technology. We use data-driven
            decisions, advanced machine learning, and decades of experience to
            overcome the challenges that commonly plague today’s growing
            businesses. Learn about our leadership team.
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/aboutus1.webp"></img>
          </div>
        </div>
      </div>
      <div className="pt-12 sm:pt-16" style={background}>
        <div className="pb-12 mt-10  sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2"></div>
            <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto">
                <dl className=" rounded-lg shadow-lg sm:grid sm:grid-cols-3">
                  <div className="flex flex-col p-6 text-center border-b border-teal-700 sm:border-0 sm:border-r">
                    <dt
                      className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500"
                      id="item-1"
                    >
                      Raptor Launches
                    </dt>
                    <dd
                      className="order-1 text-5xl font-extrabold leading-none text-teal-400"
                      aria-describedby="item-1"
                    >
                      4670+
                    </dd>
                  </div>
                  <div className="flex flex-col p-6 text-center border-t border-b border-teal-700 sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                      Equifax acquires Kount
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold leading-none text-teal-400">
                      80k+
                    </dd>
                  </div>
                  <div className="flex flex-col p-6 text-center border-t border-teal-700 sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                      Midigator joins Kount
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold leading-none text-teal-400">
                      100+
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="max-w-4xl mx-auto">
                <dl className=" rounded-lg shadow-lg sm:grid sm:grid-cols-3">
                  <div className="flex flex-col p-6 text-center border-b border-teal-700 sm:border-0 sm:border-r">
                    <dt
                      className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500"
                      id="item-1"
                    >
                      Raptor Launches
                    </dt>
                    <dd
                      className="order-1 text-5xl font-extrabold leading-none text-teal-400"
                      aria-describedby="item-1"
                    >
                      4670+
                    </dd>
                  </div>
                  <div className="flex flex-col p-6 text-center border-t border-b border-teal-700 sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                      Equifax acquires Kount
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold leading-none text-teal-400">
                      80k+
                    </dd>
                  </div>
                  <div className="flex flex-col p-6 text-center border-t border-teal-700 sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                      Midigator joins Kount
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold leading-none text-teal-400">
                      100+
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="authorized-section mt-9">
        <img src="../images/aboutus2.webp"></img>
      </div>
      <div
        style={{ marginTop: "3rem", paddingBottom: "3rem", background: "#070707e6" }}
        className="text-white text-center p-3"
      >
        <div className="w-full pt-20 pb-10">
          <div className="text-teal-400 font-extrabold"><span style={gradientStyle} >OUR TEAM</span></div>
          <br />
          <div>
            We are a passionate bunch of developers, designers, and innovators.
            Trust and safety <br /> technology is our specialty. We love what we
            do, and our passion is evident in the products we <br /> build.{" "}
            <br /> <br /> If you want to be part of our revolutionary team,
            check out our job opportunities. <br /> <br />
            <span className=" font-extrabold" style={gradientStyle}>
              Explore careers at RaptorX.ai &gt;
            </span>
          </div>
        </div>
      </div>
      <div className="text-white  ">
        <div className="w-full pt-20 pb-10">
          <div className="t font-extrabold text-center"> <span style={gradientStyle}>TESTIMONIALS</span></div>
          <br />
          <div className="sm:text-lg md:text-lg lg:text-xl xl:text-5xl text-center">
            <span className="font-bold">What our clients are <span style={gradientStyle}>saying</span> </span>{" "}
            <br />
          </div>{" "}
          <br />
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <ul
              role="list"
              className="mx-auto mt-16 pl-0 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-10 lg:max-w-none lg:grid-cols-3"
            >
              <li>
                <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8 pl-0 ">
                  <li>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                      <svg
                        aria-hidden="true"
                        width={105}
                        height={78}
                        className="absolute left-6 top-6 fill-slate-100"
                      >
                        <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
                      </svg>
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                         As a professional athlete, I rely on high-performance gear for my training. This site offers a great selection of top-notch products.
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            Sheryl Berge
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-slate-50">
                          <img
                            alt=""
                            className="h-14 w-14 object-cover"
                            style={{ color: "transparent" }}
                            src="https://randomuser.me/api/portraits/men/15.jpg"
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </li>
              <li>
                <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8 pl-0">
                  <li>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                      <svg
                        aria-hidden="true"
                        width={105}
                        height={78}
                        className="absolute left-6 top-6 fill-slate-100"
                      >
                        <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
                      </svg>
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          As a professional athlete, I rely on high-performance
                          gear for my training. This site offers a great
                          selection of top-notch products.
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            Leland Kiehn
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-slate-50">
                          <img
                            alt=""
                            className="h-14 w-14 object-cover"
                            style={{ color: "transparent" }}
                            src="https://randomuser.me/api/portraits/women/15.jpg"
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </li>
              <li>
                <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8 pl-0">
                  <li>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                      <svg
                        aria-hidden="true"
                        width={105}
                        height={78}
                        className="absolute left-6 top-6 fill-slate-100"
                      >
                        <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
                      </svg>
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          The fitness apparel I bought here fits perfectly and
                          feels amazing. I highly recommend this store to anyone
                          looking for quality gear.
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            Peter Renolds
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-slate-50">
                          <img
                            alt=""
                            className="h-14 w-14 object-cover"
                            style={{ color: "transparent" }}
                            src="https://randomuser.me/api/portraits/men/10.jpg"
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-white text-center ">
        <div className="w-full pt-20 pb-10 ">
          <div className=" font-extrabold text-3xl sm:text-base md:text-3xl lg:text-3xl xl:text-5xl">
            <span style={gradientStyle}>Where we are located</span>
          </div>
          <br />
          <div className=" px-3 py-3 sm:text-lg md:text-lg lg:text-xl xl:text-xl">
            RaptorX.ai has locations throughout the U.S. We even have offices
            overseas! Join us at any of our <br /> amazing locations and be part
            of a technology-driven company.
            <br />
          </div>{" "}
          <br />
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <ul
              role="list"
              className="mx-auto pl-0 mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mx-auto sm:gap-8 lg:mt-10 lg:max-w-none lg:grid-cols-3"
            >
              <li>
                <div className="flex px-3 py-3">
                  <div className="max-w-sm rounded overflow-hidden shadow-lg bg-gray-800">
                    <img
                      className="w-full"
                      src="https://tailwindcss.com/img/card-top.jpg"
                      alt="Sunset in the mountains"
                    />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2 ">
                        <span style={gradientStyle}>EQUIFAX HEADQUARTERS</span>
                      </div>
                      <p className="text-white text-base">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatibus quia, nulla! Maiores et perferendis
                        eaque, exercitationem praesentium nihil.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex px-3 py-3 ">
                  <div className="max-w-sm rounded overflow-hidden shadow-lg bg-gray-800">
                    <img
                      className="w-full"
                      src="https://tailwindcss.com/img/card-top.jpg"
                      alt="Sunset in the mountains"
                    />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2 ">
                      <span style={gradientStyle}>EQUIFAX HEADQUARTERS</span>
                      </div>
                      <p className="text-white text-base">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatibus quia, nulla! Maiores et perferendis
                        eaque, exercitationem praesentium nihil.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex px-3 py-3 ">
                  <div className="max-w-sm rounded overflow-hidden shadow-lg bg-gray-800">
                    <img
                      className="w-full"
                      src="https://tailwindcss.com/img/card-top.jpg"
                      alt="Sunset in the mountains"
                    />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2 ">
                      <span style={gradientStyle}>EQUIFAX HEADQUARTERS</span>
                      </div>
                      <p className="text-white text-base">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatibus quia, nulla! Maiores et perferendis
                        eaque, exercitationem praesentium nihil.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}