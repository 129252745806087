import React, { useRef, useEffect } from "react";
import "./PopUp.css";

function PopUp({ isVisible, togglePopUp, closePopUp }) {
  const popUpRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        closePopUp();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closePopUp]);

  return (
    <>
      {isVisible && (
        <div className="pop-up-container">
          <div className="pop-up-flex-wrapper">
            <div ref={popUpRef} className="pop-up-wrapper">
              <h1>Ask for an online demonstration</h1>
              <p
                className="header-description"
                style={{ margin: "0 1.8rem 0.75rem" }}
              >
                Explore Panoplia's trust and safety tech with a demo tailored
                just for you. Pick a time that works for you!
              </p>
              <div className="input-wrapper" style={{ gap: "1rem" }}>
                <input type="text" placeholder="*Corporate email address" />
                <input type="text" placeholder="*First Name" />
                <input type="text" placeholder="*Last Name" />
                <input type="text" placeholder="*Company" />
                <input type="text" placeholder="*Phone" />
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="primary-button"
                  style={{ marginTop: "1.5rem" }}
                >
                  Request a Demo
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <div className="info" style={{ padding: "1rem" }}>
                  We'd like to stay in touch! By sharing your contact details,
                  you allow us to reach out regarding our products and services.
                  If you ever wish to stop receiving these messages, you can
                  easily unsubscribe. For details on how to do so and to learn
                  more about our commitment to keeping your information safe,
                  please review our Privacy Policy.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PopUp;
