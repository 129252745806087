import React from "react";

const missionWhyJoinUs = [
  {
    title: "Mission:",
    desc: "RaptorX.ai is on a mission to revolutionize fraud prevention and risk assessment using cutting-edge technologies. We are committed to creating innovative solutions that empower businesses to stay ahead of emerging threats and protect their assets.",
  },
  {
    title: "Why Join Us:",
    desc: "Joining RaptorX.ai means being part of a dynamic team at the forefront of innovation in the cybersecurity domain. As a member of our team, you will have the opportunity to work with the latest technologies and contribute to meaningful projects that have a real impact on businesses worldwide. We offer competitive salaries, ESOPs, and a collaborative work environment where your ideas are valued and encouraged.",
  },
];

const locationSalary = [
  {
    title: "Location:",
    desc: "Hyderabad, India",
  },
  {
    title: "Salary:",
    desc: "Competitive salary based on experience, with additional benefits including ESOPs.",
  },
];

export default function TechLead() {
  return (
    <>
      <div
        className="bg-[#11171F] h-[350px] flex flex-col lg:flex-row justify-center items-center lg:place-content-center px-8 lg:px-24 lg:gap-44 sm:mt-20 md:mt-20"
        style={{ marginTop: "0rem", paddingTop: "0rem" }}
      >
        <div className=" h-auto lg:h-[252px] lg:mr-0 flex flex-col justify-center gap-4 font-base sm:pt-20">
          <p className="font-gilroy text-[#1FA3ED] text-lg text-center mt-8">
            C A R E E R S
          </p>

          <p className="font-gilroy text-4xl font-medium text-white text-center mx-auto lg:mx-0">
            Hands On Tech Lead
          </p>

          <p className="font-gilroy text-gray-400 text-center mb-8">
            On-site. Hyderabad, India.
          </p>
        </div>
      </div>

      <div className="bg-[#020811] md:mx-16">
        <div className="pt-8 px-4 md:pt-20 md:px-20">
          <h1 className="font-gilroy text-2xl md:text-3xl text-white text-left">
            More Details
          </h1>
        </div>

        <div className="px-4 md:px-20">
          {missionWhyJoinUs.map((item, index) => (
            <div key={index} className="mt-6 md:mt-10">
              <div className="text-xl md:text-2xl font-gilroy text-white">
                {item.title}
              </div>
              <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
                {item.desc}
              </div>
            </div>
          ))}
        </div>

        <div className="px-4 md:px-20 mt-8 md:mt-10">
          <div>
            <div className="text-xl md:text-2xl font-gilroy text-white">
              Responsibilities:
            </div>
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              <ul className="list-disc pl-7">
                <li>
                  Lead the development of fintech solutions, focusing on fraud
                  prevention and AML, using Typescript, ReactJs, Python, and SQL
                  databases.
                </li>
                <li>
                  Architect and deploy secure, scalable applications on AWS or
                  Azure, adhering to the best practices in financial security
                  and data protection.
                </li>
                <li>
                  Design and manage databases with an emphasis on security,
                  integrity, and performance, ensuring compliance with fintech
                  regulatory standards.
                </li>
                <li>
                  Guide and mentor the development team, promoting a culture of
                  excellence, innovation, and continuous learning in the fintech
                  space.
                </li>
                <li>
                  Collaborate with stakeholders across the company, including
                  product management, design, and QA, to ensure project
                  alignment with business goals and regulatory requirements.
                </li>
                <li>
                  Keep abreast of the latest trends and technologies in fintech,
                  fraud prevention, and AML, applying this knowledge to drive
                  the company's objectives.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 md:mt-10">
            <div className="text-xl md:text-2xl font-gilroy text-white">
              Requirements:
            </div>
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              <ul className="list-disc pl-7">
                <li>
                  5-7 years of experience in software development, with a focus
                  on fintech solutions and a strong understanding of fraud
                  prevention and AML strategies.
                </li>
                <li>
                  Expertise in Typescript, ReactJs, and familiarity with Python.
                </li>
                <li>
                  Proven experience with SQL databases and cloud services (AWS
                  or Azure), with certifications in these areas being a plus.
                </li>
                <li>
                  Demonstrated ability to design and implement secure,
                  high-performance software architectures in the fintech domain.
                </li>
                <li>
                  Exceptional leadership and communication skills, with the
                  ability to inspire and lead a team towards achieving
                  excellence.
                </li>
                <li>
                  A bachelor's degree in Computer Science, Engineering, or a
                  related field, with additional certifications in fintech,
                  security, or compliance being highly regarded.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 md:mt-10">
            <div className="text-xl md:text-2xl font-gilroy text-white">
              Tech Stack:
            </div>
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              <ul className="list-disc pl-7">
                <li>JavaScript (ES6+), HTML5, CSS3</li>
                <li>React.JS, Node.JS</li>
                <li>Data Visualization</li>
                <li>Graph Databases</li>
                <li>Neo4J</li>
                <li>Data engineering and Machine Learning (ML)</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="px-4 md:px-20 mt-8 md:mt-10">
          {locationSalary.map((item, index) => (
            <div key={index} className="mt-6 md:mt-10">
              <div className="text-xl md:text-2xl font-gilroy text-white">
                {item.title}
              </div>
              <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
                {item.desc}
              </div>
            </div>
          ))}
        </div>

        <button className="btnColor rounded-md flex items-start px-6 py-2 md:mx-5 sm: justify-start gap-4 mt-6 ml-6 border-none text-white ">
          Apply Now
        </button>
      </div>
    </>
  );
}
