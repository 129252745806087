import React, { useState } from "react";
import PopUp from "./PopUp";

export default function AccountTakeOverPrevention() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div id="personalized-solution">LESS RISK. LESS FRICTION.</div>
            <div
              className="adjust-text d-flex align-items-start flex-column"
              id="adjust-text"
            >
              Provide seamless experiences with{" "}
              <b>account takeover prevention</b>
            </div>
            <div className="fraud-handling-info">
              <p>
                Our account protection technology detects suspicious activity
                and stops costly account takeover (ATO) fraud. Plus, we can make
                sure your customers have a better, seamless experience across
                your platforms.&nbsp;
              </p>
            </div>
            <div className="primary-button" onClick={togglePopUp}>
              See how it works
            </div>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img
              src="../images/mac-image.svg"
              alt="Identity Verification Hero"
            />
          </div>
        </div>
      </div>
      <div className="content-container">
        <img
          className="background-image-3"
          style={{ top: "210rem" }}
          src="../images/background-image-3.svg"
        ></img>
        <div id="_bg__frame_67">
          <div id="offers">ACCOUNTS PROTECTED BY PANOPILA</div>
          <div id="full_confidence_and_security__no_matter_what_comes_your_way_">
            How Account Takeover Fraud Prevention Works
          </div>
          <div className="fraud-handling-info text-center">
            We know how valuable customer relationships are — and how important
            it is to protect them. Our ATO detection technology works quickly
            and accurately to keep accounts safe.
          </div>
        </div>
        <div className="transaction-image-wrapper">
          <div className="authorized-section">
            <img src="../images/strategy-image.svg"></img>
          </div>
          <div className="wallet-info">
            <div className="transaction-title">
              <img src="../icons/1-icon.svg"></img>
              <h1>Identity details are collected.</h1>
            </div>
            <div className="fraud-handling-info">
              User and device attributes are gathered. Data elements can range
              from device type and IP address to user location and biometrics.
            </div>
            <div className="transaction-title">
              <img src="../icons/2-icon.svg"></img>
              <h1>Login attempt is accepted or challenged.</h1>
            </div>
            <div className="fraud-handling-info">
              Within milliseconds, our technology accepts, blocks, or challenges
              the login attempt. Challenging a login attempt means requiring
              additional steps to verify a user — such as multi-factor
              authentication (MFA).
            </div>
            <div className="transaction-title">
              <img src="../icons/3-icon.svg"></img>
              <h1>Account activity is monitored. </h1>
            </div>
            <div className="fraud-handling-info">
              Once the user gains access to the account, further actions are
              monitored for suspicious behavior — such as changes made to the
              delivery address or email.
            </div>
            <div className="transaction-title">
              <img src="../icons/4-icon.svg"></img>
              <h1>Your accounts are protected.</h1>
            </div>
            <div className="fraud-handling-info">
              You no longer have to worry about harmful events like data
              breaches and credential stuffing attacks. Our technology has you
              covered.
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol above headerTextAlign-center">
                <h2 className="header-text">
                  Unique features deliver needed benefits
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Panopila technology provides all the capabilities you need
                    to elevate your account protection strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                    <div className="colBGimg textAlignLeft">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="key and person"
                        width="1"
                        height="1"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h3 className="header-text">Identity verification</h3>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Determine how risky a customer’s digital identity is
                        during events like account creation and checkout. Spot
                        red flags that could indicate fraud.&nbsp;
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                    <div className="colBGimg textAlignLeft">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="machine learning perceptibility"
                        width="1"
                        height="1"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h3 className="header-text">Bot detection</h3>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Identify the use of bots — a software program that runs
                        automated tasks&nbsp; — to commit fraudulent activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                    <div className="colBGimg textAlignLeft">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="Relevant insights"
                        width="1"
                        height="1"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h3 className="header-text">Velocity checks</h3>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Spot fraud patterns by monitoring the number of times a
                        user tries to interact with your business within a short
                        period of time.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                    <div className="colBGimg textAlignLeft">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="Complete identity verification"
                        width="1"
                        height="1"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h3 className="header-text">Passwordless authentication</h3>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        When strong passwords aren't enough, authenticate users
                        with an alternate form of verification&nbsp;— such as
                        the secure biometrics on the device.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol above headerTextAlign-center">
                <p id="offers">WHY ACCOUNT TAKEOVER PREVENTION MATTERS</p>
                <h2 className="header-text text-center">
                  Benefits you can’t afford to miss
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center">
                    Customer loyalty and confidence is what helps your brand
                    flourish. Show your customers how much they mean to you with
                    account takeover fraud protection — and reap the benefits to
                    your business.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Protect sensitive account information"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">
                  Protect sensitive account information
                </h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Customers store payment information, loyalty points, and
                    sensitive data on their accounts. Maintain their trust by
                    keeping fraudsters out of user accounts.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="machine learning reactivity"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Enhance customer experiences</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Set up policies to eliminate friction for known customers
                    trying to log in or make purchases on your platforms. Give
                    your customers an easy, seamless experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Protected brand reputation"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">
                  Maintain positive brand reputation
                </h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Preventing ATO fraud keeps your brand image healthy. You
                    don’t have to worry about negative online reviews or news
                    stories that can damage your reputation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Stop card testing"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Eliminate ATO attacks</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Don’t waste time and money on recovering from an ATO attack.
                    Keep your valuable resources focused on innovating and
                    growing your business.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Improved data analysis"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Improve data analysis</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Enrich your own data with login trend data — including
                    device and IP information — that is often not available to
                    fraud teams. Evaluate the data in real time to update
                    policies and keep pace with evolving threats.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Reduce payment fraud"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Reduce payments fraud</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Account protection is the first step towards payment fraud
                    prevention — stopping fraudsters at the door. Block access
                    to stored debit or credit cards, stop unauthorized
                    transactions, and avoid chargebacks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol above headerTextAlign-center">
                <p id="offers">WHAT MAKES KOUNT SPECIAL</p>
                <h2 className="header-text text-center">
                  Added value achieves better results
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center">
                    Account protection just makes sense. But why choose
                    Panopila? Here’s what sets us apart from any other strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Expand service offerings"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Global data network</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Panopila has decades worth of data — and the most robust
                    data network you can find. As part of the Equifax family,
                    Panopila has access to global insights from both digital and
                    physical interactions. And the better your data, the better
                    your decisions will be.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Simplified authentication"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Simplified authentication</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Our technology analyzes billions of data elements to
                    identify risky account activity and suspicious login
                    attempts. And Panopila is one of the first platforms to
                    offer a frictionless — yet completely accurate —
                    authorization process. Less friction means better
                    experiences for your customers.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                <div className="colBGimg textAlignLeft">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Manage fraud strategies"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">
                  Advanced AI and machine learning
                </h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Panopila is the only service provider on the market today to
                    use two forms of machine learning — supervised and
                    unsupervised. More advanced technology produces better
                    results. Plus, the quality of data that feeds our machine
                    learning is unmatched. Thus, giving you reliable and
                    accurate decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
