import React, { useState } from "react";
import PopUp from "./PopUp";

export default function StreamingServices() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div id="personalized-solution">
              ONLINE STREAMING FRAUD PREVENTION
            </div>
            <div
              className="adjust-text d-flex align-items-start flex-column"
              id="adjust-text"
            >
              Trust and safety solutions for music, video, and online streaming
              services
            </div>
            <div className="fraud-handling-info">
              <p>
                Losing too much business from password sharing? Struggling to
                keep fraudsters from taking advantage of your business and
                turning a profit? Kount can help. We offer trust and safety
                solutions that protect your revenue and help grow your business.
              </p>
            </div>
            <div className="primary-button" onClick={togglePopUp}>
              See how it works
            </div>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img
              src="../images/mac-image.svg"
              alt="Identity Verification Hero"
            />
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol above headerTextAlign-center">
                <p id="offers" className="header">
                  WHAT PANOPILO DOES
                </p>
                <h2 className="header-text text-center">
                  Complete protection. <br />
                  No matter the risk.
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center">
                    Panopila offers a complete trust and safety strategy that
                    delivers confidence in every interaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Panopila icon keys"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Prevent account takeover</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Detect suspicious activity and block fraudsters attempting
                    to hack into customer accounts. Build trust and confidence
                    with your customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Protect recurring payments"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Protect recurring payments</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Communicate with banks to resolve disputes from customers
                    canceling recurring payments. Avoid unnecessary chargebacks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Decrease account sharing"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Decrease account sharing</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Discourage customers from sharing accounts by using identity
                    verification tools to challenge suspicious activity and
                    multiple log-in attempts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Stop artificial streaming"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Stop artificial streaming</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Block fraudsters from creating fake new accounts and
                    stealing revenue through inflated, bot-manipulated plays and
                    streams.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Prevent chargebacks"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Prevent chargebacks</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Prevent fraudsters from using stolen payment cards to sign
                    up for services. Avoid increases in your chargeback rate and
                    payment authorization costs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Drive subscriber growth"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Drive subscriber growth</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Expand into new markets with complete confidence and minimal
                    risk. Convert new subscribers with the right offers to
                    increase revenue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div
          className="container-fluid bgWhite zig_zag_row bgColorChange rowCount_4"
          style={{ paddingBottom: "380px" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="kountHeaderCol">
                  <p id="offers" className="preHeading">
                    RESULTS AT A GLANCE
                  </p>
                  <h2 className="header-text text-center">
                    What Panopila can do for you
                  </h2>
                  <p className="header-description">
                    Panopila is trust and safety technology that helps online
                    streaming services like yours secure customer accounts and
                    increase revenue. We understand the challenges unique to
                    your business. And we can help you solve them.
                  </p>
                  <div className="headerSpaceBar"></div>
                </div>
              </div>
            </div>
            <div className="row zigZagSubRow align-items-center">
              <div className="col-lg order-lg-last">
                <div className="kountContentCol">
                  <img
                    className="b-lazy hero-hexbg singleNeutralHex b-loaded"
                    alt="Panopila hexagon background"
                    src="../images/mac-image.svg"
                  />
                </div>
              </div>
              <div className="col-lg">
                <div className="kountContentCol">
                  <h3 className="header-text">
                    CD Baby cut down chargebacks and stopped artificial
                    streaming fraud with Panopila
                  </h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      When CD Baby came to Panopila, their chargeback rate was
                      at 2.6%. Between card testing, fake streams, and paying
                      commission out to fraudsters, revenue losses were
                      mounting.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row zigZagSubRow align-items-center">
              <div className="col-lg ">
                <div className="kountContentCol">
                  <img
                    className="b-lazy hero-hexbg singleNeutralHex b-loaded"
                    alt="Panopila hexagon background"
                    src="../images/mac-image.svg"
                  />
                </div>
              </div>
              <div className="col-lg">
                <div className="kountContentCol">
                  <div className="formattedTextBlock header-description">
                    <p>
                      But after implementing Panopila, CD Baby dropped their
                      chargeback rate down to less than 0.1%. And even better,
                      employees no longer had to dedicate countless hours to
                      stopping fraud.
                    </p>
                    <p>
                      Learn more about how Panopila cut CD Baby’s fraud losses.
                    </p>
                  </div>
                  <div className="buttons">
                    <a href="/resources/customers/cd-baby">
                      <div className="button simpleText">
                        Check out the case study
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
