import React, { useState } from "react";
import PopUp from "./PopUp";
export default function ComprehensiveProtection() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  return (
    <>
      <>
        <div className="flex-col flex justify-center bg-background-dark items-center md:pt-20" style={{ marginTop: "0rem" }}>
          <div className="grid grid-cols-1 lg:grid-cols-2  pt-4 md:pt-8 lg:pl-8  bg-[#11171F]" style={{ background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>

            <div className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6" style={{ maxWidth: '100%' }}>
              <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">THE PROTECTION YOU DESERVE</div>
              <div className='text-4xl lg:text-5xl text-white font-semibold'>
                Empowering Your Business with Unmatched Confidence.

              </div>
              <div className="fraud-handling-info">
                Leave uncertainty behind and embark on a journey of boundless growth. We offer a comprehensive solution that ensures your business's success.
              </div>
              <a href="#formData">
              <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-0 border-none text-white">
                See How It Works
              </button>
              </a>
              
            </div>
            <div className="flex justify-center">
              <img src="../images/compreProtc1.svg" className="w-7/12" style={{ marginBottom: "3rem" }}></img>
            </div>
          </div>

        </div>
        <div className="flex-col flex justify-center bg-background-dark items-center pt-2 md:pt-14 lg:pt-10 ">
          <div className="container-fluid content-container rowCount_2">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8">
                  <div className="checklistColumns headerTextAlign-center">
                    <p id="offers">WHAT'S AT STAKE</p>
                    <h2 className="text-center text-white font-semibold leading-6 text-xl md:text-3xl font-Gilroy-SemiBold">
                      {" "}
                      Ushering in a New Era of Fraud Protection
                    </h2>
                    <div className="header-description text-center text-[#FFFFFFB2]">
                      <p>
                        Our vision for fraud prevention is rooted in modernity and practicality. Experience the epitome of convenience with our cohesive, user-centric solution. It's more than security; it's the RaptorX.ai standard.                       </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* new data started */}
              <div className="row ">
                <div className="col-lg-12 ">
                  <div className="row multicol justify-content-center flex-column columnStyle-checklistColumns gap-0 md:gap-14 mb-10" >
                    <div
                      className="col-lg col-md-6 col-sm-12  grid grid-cols-1 md:grid-cols-2 align-items-center justify-between gap-0 md:gap-16 mt-0 md:mt-8"
                      style={{ width: "100%" }}
                    >
                      <div className="flex items-center justify-center max-w-[100%] md:max-w-[100%]">
                        <img src="../images/postponedImg.svg" />
                      </div>

                      <div className="d-flex flex-column h-100 checklist mt-16">
                        <h3 className="header-text flex justify-center md:justify-start mx-2" id="adjust-text">
                          Traditional Paradigm
                        </h3>
                        <div className="formattedTextBlock header-description mt-0 md:mt-4 grid md:grid-cols-2 grid-cols-1 md:gap-3 gap-0 mx-3">
                          <ul className="text-[#FFFFFFB2]">
                            <li className="flex items-center liWarning gap-3 mt-3">
                              <img src="../icons/cross-icon.svg" />
                              <span>Revenue Drainage</span>
                            </li>
                            <li className="flex items-center liWarning gap-3 mt-3">
                              <img src="../icons/cross-icon.svg" />
                              Time Consumption
                            </li>
                            <li className="flex items-center liWarning gap-3 mt-3">
                              <img src="../icons/cross-icon.svg" />
                              Complexity Overload
                            </li>
                            <li className="flex items-center liWarning gap-3 mt-3">
                              <img src="../icons/cross-icon.svg" />
                              Guesswork-Based
                            </li>
                          </ul>
                          <ul className="text-[#FFFFFFB2]">
                            <li className="flex items-center liWarning gap-3 mt-3">
                              <img src="../icons/cross-icon.svg" />
                              <span>Manual Workforce</span>
                            </li>
                            <li className="flex items-center liWarning gap-3 mt-3">
                              <img src="../icons/cross-icon.svg" />
                              Disjointed Operations
                            </li>
                            <li className="flex items-center liWarning gap-3 mt-3">
                              <img src="../icons/cross-icon.svg" />
                              High Costs
                            </li>
                            <li className="flex items-center liWarning gap-3 mt-3">
                              <img src="../icons/cross-icon.svg" />
                              Limited Outreach
                            </li>
                          </ul>


                        </div>
                      </div>

                    </div>
                    <div
                      className="col-lg col-md-6 col-sm-12 grid grid-cols-1 md:grid-cols-2 pt-10 md:pt-10 lg:pt-10 align-items-center justify-content-between"
                      style={{ width: "100%" }}
                    >
                    <img src="../images/postponed2Img.svg" className="md:order-last mb-4"/>
                      <div className="d-flex flex-column h-100 checklist mt-2 md:mt-16">
                        <h3 className="header-text flex justify-center md:justify-start mx-2" id="adjust-text">
                          RaptorX.ai's Vision

                        </h3>
                        <div className="formattedTextBlock header-description mt-4 grid md:grid-cols-2 grid-cols-1 md:gap-3 gap-0">
                          <ul className="text-[#FFFFFFB2] mx-3">
                            <li className="liSolution flex items-center gap-3 ">
                              <img src="../icons/tick-icon.svg" />
                              Revenue Amplification
                            </li>
                            <li className="liSolution flex items-center gap-3 mt-3">
                              <img src="../icons/tick-icon.svg" />
                              Operational Efficiency
                            </li>
                            <li className="liSolution flex items-center gap-3 mt-3">
                              <img src="../icons/tick-icon.svg" />
                              Cost-Efficiency
                            </li>
                            <li className="liSolution flex items-center gap-3 mt-3">
                              <img src="../icons/tick-icon.svg" />
                              Data-Driven Decision-<br></br> Making
                            </li>

                          </ul>
                          <ul className="text-[#FFFFFFB2] mx-3">
                            <li className="liSolution flex items-center gap-3 ">
                              <img src="../icons/tick-icon.svg" />
                              Automated Excellence
                            </li>
                            <li className="liSolution flex items-center gap-3 mt-3">
                              <img src="../icons/tick-icon.svg" />
                              Unified Workflow
                            </li>
                            <li className="liSolution flex items-center gap-3 mt-3">
                              <img src="../icons/tick-icon.svg" />
                              User-Friendly Approach
                            </li>
                            <li className="liSolution flex items-center gap-3 mt-3">
                              <img src="../icons/tick-icon.svg" />
                              Endless Scalability
                            </li>

                          </ul>

                        </div>
                      </div>

                      
                    </div>
                  </div>
                </div>
              </div>
              {/* ned data ends */}
            </div>
          </div>
        </div>
        <div className="content-container mx-0" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)', paddingTop: "2rem",paddingBottom:"2rem" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="above headerTextAlign-center">
                  <p className="text-center font-medium text-base text-[#35adf4] tracking-[4px]">SOLUTIONS</p>
                  <h2 className="text-white flex justify-center mx-1 font-medium text-3xl leading-tight text-center mb-3">Complete Security</h2>
                  <div className="header-description text-center mb-10 text-[#FFFFFFB2]">
                    <p className="mx-3">
                      Elevate your business with RaptorX.ai – where confidence meets limitless <br className="hidden md:flex"></br>potential. Our adaptive platform prioritizes your needs for seamless growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center textAlignLeft mx-3">
              <div className="col-lg col-md-6 col-sm-12">
                <div className="d-flex flex-column h-100 mb-14 mx-2 md:mx-0">

                  <div className="mr-0">
                    <img
                      className="w-14"
                      alt="Icon of a shield and card"
                      src="../icons/paymentFraud.svg"
                    />
                  </div>
                  <h3 className="header-text mt-4 text-[#FFFFFF]">Payments Fraud</h3>

                  <div className="header-description text-[#FFFFFFB2]">
                    <p>
                      Access the tools and strategies necessary to combat fraud and minimize chargebacks across the entire customer journey.
                    </p>
                  </div>
                  <div className="row align-items-end mt-3">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <div className="d-flex flex-column h-100 mb-14 mx-2 md:mx-0">

                  <div className="mr-0">
                    <img
                      className="w-14"
                      alt="Icon of identity verification"
                      src="../icons/identityProtection.svg"
                    />
                  </div>
                  <h3 className="header-text mt-4 text-[#FFFFFF]">Identity Protection</h3>

                  <div className="header-description text-[#FFFFFFB2]">
                    <p>

                      Understand the true identities behind brand interactions. Engage with the right customers at the right moments while ensuring their experience remains secure and seamless.
                    </p>
                  </div>
                  <div className="row align-items-end">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <div className="d-flex flex-column h-100  mx-2 md:mx-0">

                  <div className="mr-0">
                    <img
                      className="w-14"
                      alt="Icon of a legal document"
                      src="../icons/regularityComp.svg"
                    />
                  </div>
                  <h3 className="header-text mt-4 text-[#FFFFFF]">Regulatory Compliance</h3>

                  <div className="header-description text-[#FFFFFFB2]">
                    <p>

                      Efficiently navigate the complex landscape of rules and regulations, minimizing disruptions for end-users while maintaining strict compliance.
                      Learn about card testing fraud prevention
                    </p>
                  </div>
                  <div className="row align-items-end">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
