//import logo from "./logo.svg";
import Header from "./Header";
import Content from "./WhyraptorX";
import "./App.css";
import Footer from "./Footer";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import MachineLearning from "./MachineLearning";
import ComprehensiveProtection from "./ComprehensiveProtection";
import GraphicalDashboard from "./GraphicalDashboard";
import ExplainabilityAi from "./ExplainabilityAi";
import FraudDetection from "./FraudDetection";
import ChargebackManagement from "./ChargebackManagement";
import IdentityVerification from "./IdentityVerification";
import AccountTakeOverPrevention from "./AccountTakeOverPrevention";
import RegulatoryReporting from "./RegulatoryReporting";
import Ecommerce from "./Ecommerce";
import OnlineGaming from "./OnlineGaming";
import StreamingServices from "./StreamingServices";
import Careers from "./Careers";
import OpenPositions from "./Components/career/OpenPositions";
import SeniorSoftwareEngineer from "Components/career/vacancy/SeniorSoftwareEngineer";
import TechLead from "Components/career/vacancy/TechLead";
import DataEngineer from "Components/career/vacancy/DataEngineer";
import WhyPanoplia from "./WhyPanoplia";
import Solutions from "./Solutions";
import Industries from "./Industries";
import React from "react";
import Blog from "Blog";
import About from "pages/About-us";
import Fintech from "Fintech";
import Pricing from "pages/Pricing";
import Banking from "Banking";
import ScrollToTop from "Components/ScrollToTop";
import LandingPage from "pages/LandingPage";

function App() {
  return (
    <div className="layout-container">
      <ScrollToTop />
      <Header></Header>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/why-RaptorX" element={<Content />} />
        <Route
          path="/why-panopila/machine-learning"
          element={<MachineLearning />}
        />
        <Route
          path="/why-panopila/comprehensive-protection"
          element={<ComprehensiveProtection />}
        />
        <Route
          path="/why-panopila/graphical-dashboard"
          element={<GraphicalDashboard />}
        />
        <Route
          path="/why-panopila/explainability-ai"
          element={<ExplainabilityAi />}
        />
        <Route path="/solutions/fraud-detection" element={<FraudDetection />} />
        <Route
          path="/solutions/charge-back-management"
          element={<ChargebackManagement />}
        />
        <Route
          path="/solutions/account-take-over-prevention"
          element={<AccountTakeOverPrevention />}
        />
        <Route
          path="/solutions/regulatory-reporting"
          element={<RegulatoryReporting />}
        />
        {/* <Route path="/resources/blog" element={<Blog />} /> */}
        <Route path="/about" element={<About />} />
        <Route
          path="/solutions/identity-verification"
          element={<IdentityVerification />}
        />
        <Route path="/industries/fintech" element={<Fintech />} />
        <Route path="/industries/banking" element={<Banking />} />
        <Route path="/industries/ecommerce" element={<Ecommerce />} />
        <Route path="/industries/online-gaming" element={<OnlineGaming />} />
        <Route
          path="/industries/streaming-services"
          element={<StreamingServices />}
        />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/openpositions" element={<OpenPositions />} />
        <Route
          path="/careers/softwareengineer"
          element={<SeniorSoftwareEngineer />}
        />
        <Route path="/careers/techlead" element={<TechLead />} />
        <Route path="/careers/dataengineer" element={<DataEngineer />} />
        <Route path="/resources" element={<WhyPanoplia />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/industries" element={<Industries />} />
        {/* <Route path="/pricing" element={<Pricing />} /> */}
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
