import React, { useState } from "react";
import PopUp from "./PopUp";
import "./ChargebackManagement.css";

export default function ChargebackManagement() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div id="personalized-solution">CHARGEBACK MANAGEMENT SOFTWARE</div>
            <div
              className="adjust-text d-flex align-items-start flex-column"
              id="adjust-text"
            >
              Protect your revenue with <b>chargeback management</b>
            </div>
            <div className="fraud-handling-info">
              Chargebacks have damaged your bottom line for long enough. It’s
              time to protect the revenue that’s rightfully yours — and Kount
              can help. We offer a complete solution for chargeback
              management.&nbsp;
            </div>
            <div className="primary-button" onClick={togglePopUp}>
              See how it works
            </div>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img
              src="../images/mac-image.svg"
              alt="Chargeback Management Hero"
            />
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container-fluid bgWhite two_column_row bgColorChange rowCount_2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="row align-items-center">
                  <div className="row headingAbove centered">
                    <div className="col">
                      <p id="offers">WHAT’S INCLUDED</p>
                      <h2 className="header-text text-center">
                        A complete strategy for chargeback management
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex flex-column h-100">
                      <div className="colBGimg">
                        <img
                          src="../images/mac-image.svg"
                          alt="chargeback management strategy"
                          className="b-lazy img-fluid b-loaded"
                          width="505"
                          height="465"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex flex-column h-100">
                      <div className="row iconBulletRow bulletStyle-">
                        <div className="col">
                          <p className="header-description">
                            Panopila is capable of solving the problem of
                            chargebacks — the <em>entire</em> problem, not just
                            the most obvious or easiest-to-handle threats.&nbsp;
                          </p>
                        </div>
                      </div>
                      <div className="row iconBulletRow bulletStyle">
                        <div className="col">
                          <p className="header-text">
                            <strong>
                              Our goal is to protect the maximum amount of
                              revenue in the most efficient and accurate way
                              possible.
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container-fluid bgColor bgWhite rowCount_3">
          <div className="container">
            <div className="row multicol justify-content-center columnStyle-bigBoxImgHeaderTextLink">
              <div className="col-lg col-md-6 col-sm-12">
                <a
                  className="box-wrapper"
                  href="/chargeback-management/prevent-chargebacks"
                  style={{ textDecoration: "none" }}
                >
                  <div className=" d-flex justify-content-between flex-column h-100 mediaLocation-bigBoxImgHeaderTextLink">
                    <div className="colBGimg">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="Prevent Chargebacks"
                        width="900"
                        height="900"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h2 className="header-text">Prevent chargebacks</h2>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        The easiest-to-win fight is the one you can avoid.
                        That’s why chargeback prevention is a crucial first step
                        in a comprehensive management strategy.&nbsp;
                      </p>
                    </div>
                    <div className="multicolLinkRow row align-items-end">
                      <div className="button simpleText">
                        Learn about preventing chargebacks
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <a
                  className="box-wrapper"
                  href="/chargeback-management/fight-chargebacks"
                  style={{ textDecoration: "none" }}
                >
                  <div className=" d-flex justify-content-between flex-column h-100 mediaLocation-bigBoxImgHeaderTextLink">
                    <div className="colBGimg">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="Fight Chargebacks"
                        width="900"
                        height="900"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h2 className="header-text">Fight chargebacks</h2>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Prevent what you can, and fight what you can’t. Don’t
                        leave money on the table when chargebacks do happen —
                        reclaim what’s rightfully yours.&nbsp;
                      </p>
                    </div>
                    <div className="multicolLinkRow row align-items-end">
                      <div className="button simpleText">
                        Learn about fighting chargebacks
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="row multicol justify-content-center columnStyle-bigBoxImgHeaderTextLink">
              <div className="col-lg col-md-6 col-sm-12">
                <a
                  className="box-wrapper"
                  href="/chargeback-management/chargeback-automation"
                  style={{ textDecoration: "none" }}
                >
                  <div className=" d-flex justify-content-between flex-column h-100 mediaLocation-bigBoxImgHeaderTextLink">
                    <div className="colBGimg">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="Automate Tasks"
                        width="900"
                        height="900"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h2 className="header-text">Automate tasks</h2>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Automation is key. It ensures everything you do is done
                        efficiently and accurately. Pick how much or how little
                        you want to automate, and the technology will go to
                        work.
                      </p>
                    </div>
                    <div className="multicolLinkRow row align-items-end">
                      <div className="button simpleText">
                        Learn about automating chargebacks
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <a
                  className="box-wrapper"
                  href="/why-kount/robust-data"
                  style={{ textDecoration: "none" }}
                >
                  <div className="d-flex justify-content-between flex-column h-100 mediaLocation-bigBoxImgHeaderTextLink">
                    <div className="colBGimg">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="Analyze Outcomes"
                        width="900"
                        height="900"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h2 className="header-text">Analyze outcomes</h2>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Data analysis brings your chargeback management strategy
                        full circle. Figure out what is and isn’t working so
                        your results are always improving.&nbsp;
                      </p>
                    </div>
                    <div className="multicolLinkRow row align-items-end">
                      <div className="button simpleText">
                        Learn about data analysis
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="customPageContainer container-fluid multicolumn_row bgColor bgGrey bgColorChange rowCount_4 ">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <div className="kountHeaderCol above headerTextAlign-center">
                  <p id="offers" className="preHeading">
                    WHY CHARGEBACK MANAGEMENT MATTERS
                  </p>
                  <h2 className="header-text">
                    The hidden benefits of chargeback management
                  </h2>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Why bother with chargeback management? Because it can
                      significantly improve your bottom line in multiple ways.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
              <h3 className="multiColSubHeader">
                Benefits of <b>preventing chargebacks</b>
              </h3>

              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                  <div className="colBGimg textAlignLeft">
                    <img
                      className="b-lazy img-fluid b-loaded"
                      alt="Reduce chargebacks"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Keep chargeback counts low</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Reduce chargeback rates by 60-70%. Keep thresholds low and
                      merchant accounts healthy.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                  <div className="colBGimg textAlignLeft">
                    <img
                      className="b-lazy img-fluid b-loaded"
                      alt="Improved customer experience"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Improve customer experience</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Increase customer satisfaction by quickly and proactively
                      resolving complaints.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                  <div className="colBGimg textAlignLeft">
                    <img
                      className="b-lazy img-fluid b-loaded"
                      alt="machine learning availabliity"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Take quick action</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Identify issues within 24 hours of a dispute rather than
                      waiting for a chargeback 2-5 weeks later.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                  <div className="colBGimg textAlignLeft">
                    <img
                      className="b-lazy img-fluid b-loaded"
                      alt="Save fulfillment costs"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Save fulfillment costs</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Stop the shipment of goods on disputed orders. Retain
                      fulfillment, shipping, and product costs.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
              <h3 className="multiColSubHeader">
                Benefits of <b>fighting chargebacks</b>
              </h3>

              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                  <div className="colBGimg textAlignLeft">
                    <img
                      className="b-lazy img-fluid b-loaded"
                      alt="Recover revenue"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Recover revenue</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      If you accept chargebacks as a loss, your hard-earned
                      money is gone forever. But if you fight, you can recover
                      revenue that’s been unfairly sacrificed.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                  <div className="colBGimg textAlignLeft">
                    <img
                      className="b-lazy img-fluid b-loaded"
                      alt="machine learning perceptibility"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Uncover data</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      If you ignore your chargebacks, you miss the data they
                      contain. But if you dig in and investigate, you’ll learn
                      why chargebacks happened in the first place.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                  <div className="colBGimg textAlignLeft">
                    <img
                      className="b-lazy img-fluid b-loaded"
                      alt="Avoid penalties"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Avoid penalties</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      If you don’t respond to certain disputes, you’ll have to
                      pay an expensive fine. But fight back and you can easily
                      avoid those unnecessary penalties.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                  <div className="colBGimg textAlignLeft">
                    <img
                      className="b-lazy img-fluid b-loaded"
                      alt="machine learning objectivity"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Prove legitimacy</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      If you aren’t fighting, the bank is going to wonder why.
                      But fight for money that’s rightfully yours and you’ll
                      help solidify the business’s credibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol above headerTextAlign-center">
                <p id="offers" className="preHeading">
                  WHAT MAKES PANOPILA SPECIAL
                </p>
                <h2 className="header-text">
                  Added value achieves better results
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Want to manage chargebacks with greater efficiency and
                    better results? Here’s what sets Panopila apart from any
                    other strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                    <div className="colBGimg textAlignLeft">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="machine learning reactivity"
                        width="1"
                        height="1"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h3 className="header-text">
                      Impressive return on investment
                    </h3>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Other strategies might yield high win rates, but they
                        come with low or negative ROI. At Panopila, we
                        understand what really matters — your bottom line.
                        That’s why we strive for both high win rates and
                        significant ROI.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                    <div className="colBGimg textAlignLeft">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="kount icon automation"
                        width="1"
                        height="1"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h3 className="header-text">Flexible automation</h3>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Not all automation is created equal. You need flexible
                        automation that fits the unique preferences of your
                        business. With options for DIY, fully-managed, and
                        everything in between, Panopila can do it all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row multicol justify-content-center textAlignLeft columnStyle-above">
                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                    <div className="colBGimg textAlignLeft">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="Account Protection Shield and Star"
                        width="1"
                        height="1"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h3 className="header-text">Proven expertise</h3>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        Our chargeback experts have spent decades learning the
                        rules, testing strategies, and building relationships.
                        We know what it takes to succeed. Ongoing insights are
                        funneled into technology that is constantly optimized so
                        results are always improving.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above">
                    <div className="colBGimg textAlignLeft">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="kount icon historical data"
                        width="1"
                        height="1"
                        src="../images/mac-image.svg"
                      />
                    </div>
                    <h3 className="header-text">Real-time data</h3>
                    <div className="formattedTextBlock">
                      <p className="header-description">
                        When it comes to managing chargebacks, time is money.
                        You can’t afford to wait days or even hours to know
                        what’s happening. You need data in real time so you can
                        make quick and accurate decisions — and that’s exactly
                        what Panopila provides.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol bigBoxImgHeaderTextLink headerTextAlign-center">
                <p id="offers" className="preHeading">
                  WHAT PANOPILA OFFERS
                </p>
                <h2 className="header-text">
                  Multiple solutions. Complete strategy.&nbsp;
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Panopila has all the chargeback management tools you need to
                    be successful. Learn more about the individual solutions
                    that go into a complete strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row multicol justify-content-center textAlignCenter columnStyle-bigBoxImgHeaderTextLink">
                <div className="col-lg col-md-6 col-sm-12">
                  <a
                    href="/chargeback-management/order-validation"
                    className="box-wrapper"
                  >
                    <div className="d-flex justify-content-evenly flex-column h-100 mediaLocation-bigBoxImgHeaderTextLink">
                      <div className="colBGimg textAlignCenter">
                        <img
                          className="b-lazy img-fluid b-loaded"
                          alt="Order Validation"
                          width="1461"
                          height="1116"
                          src="../images/mac-image.svg"
                        />
                      </div>
                      <h3 className="header-text">Order Validation</h3>
                      <div className="formattedTextBlock">
                        <p className="header-description">
                          Resolve issues without sacrificing revenue.
                        </p>
                      </div>
                      <div className="multicolLinkRow row align-items-end">
                        <div className="button simpleText">
                          Learn about order validation
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg col-md-6 col-sm-12">
                  <a
                    href="/chargeback-management/chargeback-prevention-alerts"
                    className="box-wrapper"
                  >
                    <div className="d-flex justify-content-evenly flex-column h-100 mediaLocation-bigBoxImgHeaderTextLink">
                      <div className="colBGimg textAlignCenter ">
                        <img
                          className="b-lazy img-fluid b-loaded"
                          alt="Prevention Alerts"
                          width="1461"
                          height="1116"
                          src="../images/mac-image.svg"
                        />
                      </div>
                      <h3 className="header-text">Prevention Alerts</h3>
                      <div className="formattedTextBlock">
                        <p className="header-description">
                          Be notified when transactions are disputed.
                        </p>
                      </div>
                      <div className="multicolLinkRow row align-items-end">
                        <div className="button simpleText">
                          Learn about prevention alerts
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="row multicol justify-content-center textAlignCenter columnStyle-bigBoxImgHeaderTextLink">
                <div className="col-lg col-md-6 col-sm-12">
                  <a
                    href="/chargeback-management/rapid-dispute-resolution-rdr"
                    className="box-wrapper"
                  >
                    <div className="d-flex justify-content-evenly flex-column h-100 mediaLocation-bigBoxImgHeaderTextLink">
                      <div className="colBGimg textAlignCenter ">
                        <img
                          className="b-lazy img-fluid b-loaded"
                          alt="RDR Rapid Dispute Resolution"
                          width="1461"
                          height="1116"
                          src="../images/mac-image.svg"
                        />
                      </div>
                      <h3 className="header-text">Rapid Dispute Resolution</h3>
                      <div className="formattedTextBlock">
                        <p className="header-description">
                          Refund disputes to reduce chargebacks.
                        </p>
                      </div>
                      <div className="multicolLinkRow row align-items-end">
                        <div className="button simpleText">Learn about RDR</div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg col-md-6 col-sm-12">
                  <a
                    href="/chargeback-management/chargeback-responses"
                    className="box-wrapper"
                  >
                    <div className="d-flex justify-content-evenly flex-column h-100 mediaLocation-bigBoxImgHeaderTextLink">
                      <div className="colBGimg textAlignCenter ">
                        <img
                          className="b-lazy img-fluid b-loaded"
                          alt="Chargeback Responses"
                          width="1461"
                          height="1116"
                          src="../images/mac-image.svg"
                        />
                      </div>
                      <h3 className="header-text">Chargeback Responses</h3>
                      <div className="formattedTextBlock">
                        <p className="header-description">
                          Recover revenue that’s been lost.&nbsp;
                        </p>
                      </div>
                      <div className="multicolLinkRow row align-items-end">
                        <div className="button simpleText">
                          Learn about chargeback responses
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
