import React, { useState } from "react";
import PopUp from "./PopUp";
export default function GraphicalDashboard() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  return (
    <>
      <div className="flex-col flex justify-center bg-background-dark items-center md:pt-20" style={{ marginTop: "0rem" }}>
      <div className="grid grid-cols-1 lg:grid-cols-2  pt-4 pb-4 lg:pl-8  bg-[#11171F]" style={{background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)'}}>
               
               <div className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6" style={{ maxWidth: '100%' }}>
                   <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">SIMPLE & INTUITIVE</div>
                   <div className='text-4xl lg:text-5xl text-white font-semibold'>
                   Everything you need. One
easy-to-use platform                    
                   </div>
                   <div className="fraud-handling-info">
                   RaptorX.ai provides a complete strategy for trust and safety — all from a single, easy-to-use platform. Learn how a better user experience ensures better results.
                   </div>
                   <a href="#formData">
                   <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-0 border-none text-white">
                      See How It Works
                   </button>
                   </a>
                   
               </div>
               <div className="flex justify-center">
                   <img src="../images/Screen.svg" className="w-10/12 md:w-7/12"/>
               </div>
           </div>
      </div>
      <div className="flex flex-col justify-center items-center pt-1 md:pt-14 pb-8">
        
        <div className="transaction-image-wrapper grid grid-cols-1 lg:grid-cols-2" style={{display: 'grid', maxWidth: '100%'}}>
          <div className="flex justify-center items-center md:order-first mx-2">
            <img src="../images/dashboardIncludes.svg"></img>
          </div>

          <div className="wallet-info order-first">
          <div className="inline-flex flex-col items-left gap-4 max-w-40rem">
          <div className="text-[#35adf4] font-medium text-lg leading-normal tracking-wider text-left">
  WHAT’S INCLUDED
</div>

          <div id="full_confidence_and_security__no_matter_what_comes_your_way_" style={{textAlign:"left"}}>
          Key RaptorX.ai Dashboard Features
          </div>
          <div className="fraud-handling-info text-left">
          Explore the core elements of the RaptorX.ai dashboard, designed to empower your confidence efficiently.
          </div>
        </div>
            <div className="transaction-title">
              <img src="../icons/1-icon.svg"></img>
              <h1>Graph Insights</h1>
            </div>
            <div className="fraud-handling-info">
            Uncover intricate connections and insights within your data using interactive graph technology. Gain rapid insights that drive faster decision-making and compliance.
            </div>
            <div className="transaction-title">
              <img src="../icons/2-icon.svg"></img>
              <h1>Real-time Analytics</h1>
            </div>
            <div className="fraud-handling-info">
            Access up-to-the-minute analytics for your entire portfolio. Effortlessly filter, sort, and visualize data with user-friendly tools, enabling quicker compliance assessment.
            </div>
            <div className="transaction-title">
              <img src="../icons/3-icon.svg"></img>
              <h1>Interactive Visualization</h1>
            </div>
            <div className="fraud-handling-info">
            Transform complex data into actionable insights with dynamic, interactive visualizations. Easily identify trends and patterns across diverse variables, fostering smarter decision-making.
            </div>
            <div className="transaction-title">
              <img src="../icons/4-icon.svg"></img>
              <h1>Alert System</h1>
            </div>
            <div className="fraud-handling-info">
            Stay ahead of the game by setting custom performance alerts. Receive real-time notifications when predefined thresholds are met, ensuring swift responses and compliance.
            </div>
            <div className="transaction-title">
              <img src="../icons/5-icon.svg"></img>
              <h1>Policy Control</h1>
            </div>
            <div className="fraud-handling-info">
            Take charge of your automation strategy with flexible policy settings. Easily modify and fine-tune policies to align with your evolving needs, enhancing decision agility.
            </div>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)',paddingTop:"2rem",paddingBottom:"2rem" }}>
        <div className="container-fluid rowCount_3">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="kountHeaderCol above headerTextAlign-center">
                  <p className="text-center font-medium text-base text-[#35adf4] tracking-[4px]">WHAT MAKES RAPTORX SPECIAL</p>
                  <h2 className="text-white flex justify-center font-medium text-3xl leading-tight text-center mb-3">
                    {" "}
                    Added value achieves better results
                  </h2>
                  <div className="header-description text-center text-[#FFFFFFB2]">
                    <p className="header-description mx-3">
                      RaptorX.ai offers our clients a useful, robust, and
                      intuitive experience that is <br className="hidden md:flex"></br> uniquely different from any
                      other option available today.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mx-3" style={{marginTop:"1.3rem"}}>
              <div className="col-lg-10 ">
                <div className="row multicol justify-content-center textAlignLeft columnStyle-above gap-6">
                  <div className="col-lg col-md-6 col-sm-12">
                    <div className="d-flex flex-column h-100 mediaLocation-above gap-[10px]">
                    
  <div className="mr-2">
    <img
      className="b-lazy img-fluid"
      alt="Relevant insights"
      src="../icons/relaventInsights.svg"
    />
  </div>
  <h3 className="header-text text-[#FFFFFF]">Relevant insights</h3>


                      <div className="formattedTextBlock text-[#FFFFFFB2]">
                        <p className="header-description">
                          Piles of irrelevant data don’t do anyone any good. You
                          need insights that are applicable to your business —
                          and that’s exactly what RaptorX.ai provides.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg col-md-6 col-sm-12">
                    <div className="d-flex flex-column h-100 mediaLocation-above gap-[10px]">
                    
  <div className="mr-2">
    <img
      className="b-lazy img-fluid"
      alt="Intuitive interface"
      src="../icons/intutiveInterface.svg"
    />
  </div>
  <h3 className="header-text text-[#FFFFFF]">Intuitive interface</h3>


                      <div className="formattedTextBlock text-[#FFFFFFB2]">
                        <p className="header-description">
                          Data is only useful if you understand what it means.
                          Gain valuable insights with intuitive,
                          easy-to-understand reports and charts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row multicol justify-content-center textAlignLeft columnStyle-above" style={{marginTop:"1rem"}}>
                  <div className="col-lg col-md-6 col-sm-12">
                    <div className="d-flex flex-column h-100 mediaLocation-above gap-[10px]">
                    
  <div className="mr-2">
    <img
      className="b-lazy img-fluid"
      alt="Customizable data"
      src="../icons/customizableData.svg"
    />
  </div>
  <h3 className="header-text text-[#FFFFFF]">Customizable data</h3>


                      <div className="formattedTextBlock text-[#FFFFFFB2]">
                        <p className="header-description">
                          Your strategy has to evolve at the same pace as your
                          growing business. Kount’s dashboard can be customized
                          to your specific data needs — both now and in the
                          future.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg col-md-6 col-sm-12">
                    <div className="d-flex flex-column h-100 mediaLocation-above gap-[10px]">
                    
  <div className="mr-2">
    <img
      className="b-lazy img-fluid"
      alt="Centralized reporting"
      src="../icons/centerlizedimg.svg"
    />
  </div>
  <h3 className="header-text text-[#FFFFFF]">Centralized reporting</h3>

                      <div className="formattedTextBlock text-[#FFFFFFB2]">
                        <p className="header-description">
                          Consolidate your data into a single platform.
                          Experience complete transparency across the entire
                          customer journey.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
