import React from "react";

const missionWhyJoinUs = [
  {
    title: "Mission:",
    desc: "RaptorX.ai is on a mission to revolutionize fraud prevention and risk assessment using cutting-edge technologies. We are committed to creating innovative solutions that empower businesses to stay ahead of emerging threats and protect their assets.",
  },
  {
    title: "Why Join Us:",
    desc: "Joining RaptorX.ai means being part of a dynamic team at the forefront of innovation in the cybersecurity domain. As a member of our team, you will have the opportunity to work with the latest technologies and contribute to meaningful projects that have a real impact on businesses worldwide. We offer competitive salaries, ESOPs, and a collaborative work environment where your ideas are valued and encouraged.",
  },
];

const locationSalary = [
  {
    title: "Location:",
    desc: "Hyderabad, India",
  },
  {
    title: "Salary:",
    desc: "Competitive salary based on experience, with additional benefits including ESOPs.",
  },
];

const others =
  "Testing web applications to ensure they are bug-free and work seamlessly for the end-users.Experience with browser-based debugging and performance testing software is preferred. Excellent troubleshooting skills, Skills - Material Design";

export default function SeniorSoftwareEngineer() {
  return (
    <>
      <div
        className="bg-[#11171F] h-[350px] flex flex-col lg:flex-row justify-center items-center lg:place-content-center px-8 lg:px-24 lg:gap-44 sm:mt-20"
        style={{ marginTop: "0rem", paddingTop: "0rem" }}
      >
        <div className=" h-auto lg:h-[252px] lg:mr-0 flex flex-col justify-center gap-4 font-base sm:pt-20">
          <p className="font-gilroy text-[#1FA3ED] text-lg text-center mt-8">
            C A R E E R S
          </p>

          <p className="font-gilroy text-4xl font-medium text-white text-center mx-auto lg:mx-0">
            Senior Software Engineer
          </p>

          <p className="font-gilroy text-gray-400 text-center mb-8">
            On-site. Hyderabad, India.
          </p>
        </div>
      </div>

      <div className="bg-[#020811] md:mx-16">
        <div className="pt-8 px-4 md:pt-20 md:px-20">
          <h1 className="font-gilroy text-2xl md:text-3xl text-white text-left">
            More Details
          </h1>
        </div>

        <div className="px-4 md:px-20">
          {missionWhyJoinUs.map((item, index) => (
            <div key={index} className="mt-6 md:mt-10">
              <div className="text-xl md:text-2xl font-gilroy text-white">
                {item.title}
              </div>
              <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
                {item.desc}
              </div>
            </div>
          ))}
        </div>

        <div className="px-4 md:px-20 mt-8 md:mt-10">
          <div>
            <div className="text-xl md:text-2xl font-gilroy text-white">
              Responsibilities:
            </div>
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              <ul className="list-disc pl-7">
                <li>
                  Developing and maintaining web applications that are optimised
                  for various devices and browsers.
                </li>
                <li>
                  Implementing responsive design and cross-browser compatibility
                  for different screen sizes.
                </li>
                <li>
                  Developing user-friendly and interactive web features such as
                  buttons, forms, and menus.
                </li>
                <li>
                  Conducting code reviews and providing feedback to ensure code
                  quality and best practices are maintained.
                </li>
                <li>
                  Staying up-to-date with the latest frontend development trends
                  and technologies.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 md:mt-10">
            <div className="text-xl md:text-2xl font-gilroy text-white">
              Tech Stack:
            </div>
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              <ul className="list-disc pl-7">
                <li>JavaScript (ES6+), HTML5, CSS3</li>
                <li>React.js, AngularJS</li>
                <li>Redux, Flux</li>
                <li>Webpack, Enzyme</li>
                <li>RESTful APIs</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="px-4 md:px-20 mt-8 md:mt-10">
          <div className="mt-6 md:mt-10">
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              {others}
            </div>
          </div>
        </div>

        <div className="px-4 md:px-20 mt-8 md:mt-10">
          {locationSalary.map((item, index) => (
            <div key={index} className="mt-6 md:mt-10">
              <div className="text-xl md:text-2xl font-gilroy text-white">
                {item.title}
              </div>
              <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
                {item.desc}
              </div>
            </div>
          ))}
        </div>

        <button className="btnColor rounded-md flex items-start px-6 py-2 md:mx-5 sm: justify-start gap-4 mt-6 ml-6 border-none text-white ">
          Apply Now
        </button>
      </div>
    </>
  );
}
