import React from 'react'

const ProblemCard = ({ icon, heading, desc}) => {
    return (
        <div className="col-lg col-md-6 col-sm-12">
            <div className="d-flex flex-column h-100 mb-14 mx-2 md:mx-0">

                <div className="mr-0">
                    <img
                        className="w-14"
                        alt="Icon of a shield and card"
                        src={icon}
                    />
                </div>
                <h3 className="header-text mt-4 text-white">{heading}</h3>

                <div className="header-description">
                    <p className='text-[#FFFFFFB2]'>
                        {desc}
                    </p>
                </div>

            </div>
        </div>
    )
}

export default ProblemCard