import React from "react";
import "../Content.css";
import vector from "../img/Vector.png";
function LandingPage() {
  return (
    <div className="bg-[#020811]">
      <div
        className="grid grid-cols-1 lg:grid-cols-2 lg:mt-20"
        style={{
          backgroundImage: `url(${vector})`,
          backgroundColor: "#020811",
        }}
      >
        <img
          className="position-absolute flex-shrink-0"
          id="background-graphic-2"
          src="./images/background-image-2.svg"
        ></img>
        <div
          className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6 xl:pl-20"
          style={{ maxWidth: "100%" }}
        >
          <div id="adjust-text" className="text-[38px] lg:text-[48px]">
            RaptorX
            <span className="bg-gradient-to-b from-[#16EE9D] to-[#08A0F3] inline-block text-transparent bg-clip-text">
              .ai
            </span>
            - Leading the way in Transaction and Identity Fraud Prevention
          </div>
          <div className="fraud-handling-info">
            Harness the Power of Unsupervised and Supervised Machine Learning,
            Alongside the Latest LLM Technology, to Safeguard Your Operations.
            Defend Against Transaction and Identity Frauds with Precision and
            Expertise.
          </div>
          <a href="#formData">
            <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-0 border-none text-white">
              Get started Now
            </button>
          </a>
        </div>
        <div className="mac-image-wrapper mt-8">
          <img
            src="./images/mac-image-lg.svg"
            className=" hidden lg:-mr-16 lg:block"
          ></img>
          <img src="./images/mac-image.svg" className=" lg:hidden"></img>
        </div>
      </div>

      <div className="content-container pb-20 px-4 lg:px-6">
        <img
          className="background-image-3"
          src="./images/background-image-3.svg"
        ></img>
        <div id="_bg__frame_67">
          <div id="adjust-text" className="text-3xl text-center">
            Safeguarding Trust, Defining Security: Your Partner in Fraud
            Prevention Excellence
          </div>
          <div className="text-center font-Gilroy-SemiBold text-2xl font-normal leading-normal max-w-34rem text-white flex md:hidden">
            Full confidence and security, no matter what comes your way.
          </div>
        </div>
        <div className="transaction-image-wrapper">
          <div className="authorized-section">
            <img src="./images/object1.svg" className="max-h-96"></img>
          </div>
          <div className="wallet-info">
            <div className="transaction-title">
              <img src="./icons/wallet-icon.svg"></img>
              <h1>TRANSACTION FORTIFICATION</h1>
            </div>
            <div className="fraud-handling-info">
              Enhance your transactional prowess with RaptorX's robust
              solutions. From deterring criminal fraud to streamlining
              chargeback management, countering friendly fraud, and bolstering
              revenue recovery – all accessible through our user-friendly
              platform. Boost authorized transactions and minimize false
              positives, ensuring a secure and efficient transaction experience.
            </div>
            <div className="trust-safety-info">
              Learn more about trust and safety in payments
              <img src="./icons/arrow-icon.svg"></img>
            </div>
          </div>
        </div>
        <div className="transaction-image-wrapper">
          <div className="wallet-info lg:order-first">
            <div className="transaction-title">
              <img src="./icons/profile-icon.svg"></img>
              <h1>PRECISE USER PROFILING</h1>
            </div>
            <div className="fraud-handling-info">
              Harness extensive data-driven profiles with RaptorX, unveiling
              valuable insights such as spending likelihood, purchase patterns,
              and payment history. Pinpoint your ideal audience and engage them
              at the opportune juncture, delivering a secure and delightful
              experience that strengthens your brand connection.
            </div>
            <div className="trust-safety-info">
              Learn more about customer identity data
              <img src="./icons/arrow-icon.svg"></img>
            </div>
          </div>
          <div className="authorized-section order-first lg:order-last">
            <img src="./images/object2.svg" className="max-h-96"></img>
          </div>
        </div>
      </div>

      <div
        className="background-image-graphic d-flex justify-content-end pl-4 lg:pl-6"
        id="dashboard-container"
        style={{ backgroundColor: "#0F141D", width: "100%" }}
      >
        <div
          className="transaction-image-wrapper justify-btw"
          style={{ maxWidth: "90rem" }}
        >
          <div className="wallet-info" style={{ maxWidth: "31rem" }}>
            <div id="personalized-solution">PERSONALIZED SOLUTION</div>
            <div id="adjust-text" className="text-3xl">
              Adjust your approach to match what works best for you.
            </div>
            <div className="fraud-handling-info">
              Have a special way you want to use our technology? If you have
              specific needs we haven't covered, just tell us. We're flexible
              and can adapt our solutions to suit exactly what your business
              requires.
            </div>
            <a href="#formData">
              <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-0 border-none text-white">
                Get started Now
              </button>
            </a>
          </div>
          <div
            className="flex w-11/12 md:w-[47rem] justify-center md:justify-end"
            id="dashboard-image-wrapper"
          >
            <img id="dashboard-image" src="./images/Dashboard_image2.svg"></img>
          </div>
        </div>
      </div>
      <div
        className="content-container px-4 lg:px-6"
        style={{ paddingTop: "5rem" }}
      >
        <img
          className="background-image-3"
          style={{ top: "210rem" }}
          src="./images/background-image-3.svg"
        ></img>
        <div id="_bg__frame_67">
          <div id="offers">WHY CHOOSE RAPTORX</div>
          <div className="text-white text-center font-semibold text-3xl  max-w-[34rem]">
            Explore RaptorX: Where Unparalleled Features Set a New Standard.
          </div>
          <div className="text-white text-opacity-70 font-normal text-sm  line-height-5 max-w-[32rem] text-center">
            At RaptorX, we don't fit into conventional molds; we challenge them.
            Comparing us to others would be like comparing a revolution to the
            status quo. Let's venture into what makes RaptorX truly
            trailblazing.
          </div>
        </div>
        <div
          className="transaction-image-wrapper grid grid-cols-1 lg:grid-cols-2"
          style={{ display: "grid" }}
        >
          <div className="flex justify-center items-center">
            <img src="images/homePage/problem.png" className="lg:w-12/12"></img>
          </div>
          <div className="wallet-info">
            <div className="transaction-title">
              <img src="./icons/1-icon.svg"></img>
              <h1>Comprehensive Approach</h1>
            </div>
            <div className="fraud-handling-info">
              You deserve to be shielded from all possible threats, and we've
              got everything you need for that.
            </div>
            <div className="transaction-title">
              <img src="./icons/2-icon.svg"></img>
              <h1>Patented Deep Technology</h1>
            </div>
            <div className="fraud-handling-info">
              Nuanced Frauds need the latest and greatest technology to identify
              and prevent them. With multiple patents and customizable options
              we ensure you are protected from all types of frauds.
            </div>
          </div>
        </div>
      </div>
      {/* <div
                className="content-container background-image-graphic px-4 lg:px-6"
                style={{ backgroundColor: "#0F141D" }}
            >
                <div id="_bg__frame_67" style={{ marginTop: "3.5rem" }}>
                    <div id="offers">WHO RAPTORX SERVES</div>
                    <div id="full_confidence_and_security__no_matter_what_comes_your_way_">
                        Protection that works seamlessly across all industries.
                    </div>
                    <div className="fraud-handling-info text-center">
                        Numerous brands across the globe, spanning various industries, rely
                        on RAPTORX for safe and secure interactions.
                    </div>
                </div>
                <div className="md:inline-flex items-center justify-center gap-6 mt-14 flex-wrap mb-14 grid grid-cols-2">
                    <button>
                        <img src="./icons/bank-icon.svg"></img>
                    </button>
                    <button>
                        <img src="./icons/ecommerce-icon.svg"></img>
                    </button>
                    <button>
                        <img src="./icons/fintech-icon.svg"></img>
                    </button>
                    <button>
                        <img src="./icons/streaming-icon.svg"></img>
                    </button>
                </div>
            </div> */}
      <div
        className="content-container px-1 md:px-4 lg:px-6 pb-20"
        style={{ paddingTop: "5rem" }}
      >
        <div id="_bg__frame_67">
          <div id="offers">HOW RAPTORX WORKS</div>
          <div id="full_confidence_and_security__no_matter_what_comes_your_way_">
            Learn about How Our System Works
          </div>
        </div>
        <div className="system-flow-image-wrapper">
          <img src="./images/Group_4550.svg"></img>
        </div>
      </div>
      <div
        className="content-container background-image-graphic px-1 md:px-4 lg:px-6"
        style={{ backgroundColor: "#0F141D" }}
      >
        <div className="transaction-image-wrapper justify-content-between">
          <div className="wallet-info">
            <div id="personalized-solution">USE CASES</div>
            <div id="adjust-text" className="text-3xl">
              Fostering Trust and Security Through RaptorX's Technological
              Advancements
            </div>
            <div className="fraud-handling-info">
              How do you plan to make use of RaptorX's technology to ensure
              trust and safety?
            </div>
          </div>
          <div className="authorized-section justify-center">
            <img src="./images/inclinegraph.svg"></img>
          </div>
        </div>
        <div className="child-wrapper mt-6 px-4">
          <div className="transaction-parent-wrapper">
            <div className="transaction-wrapper">
              <div className="transaction-title">
                <img src="./icons/1-icon.svg"></img>
                <h1>Customer Insight Amplification</h1>
              </div>
              <div className="fraud-handling-info">
                Harness the power of RAPTORX's technology to gain deeper
                insights into customer preferences and perceptions.
              </div>
            </div>
            <div className="transaction-wrapper">
              <div className="transaction-title">
                <img src="./icons/2-icon.svg"></img>
                <h1>Continuous Identity Verification</h1>
              </div>
              <div className="fraud-handling-info">
                Elevate your authentication process with continuous identity
                profiling at every stage, differentiating reliable customers.
              </div>
            </div>
            <div className="transaction-wrapper">
              <div className="transaction-title">
                <img src="./icons/3-icon.svg"></img>
                <h1>Vigilant Fraud Prevention</h1>
              </div>
              <div className="fraud-handling-info">
                Stay one step ahead of fraudsters by employing cutting-edge
                fraud detection methods that swiftly identify and thwart
                suspicious activities.
              </div>
            </div>
          </div>
          <div
            className="transaction-parent-wrapper d-flex justify-content-around flex-wrap align-items-center"
            style={{ width: "100%", marginTop: "2rem" }}
          >
            <div className="transaction-wrapper">
              <div className="transaction-title">
                <img src="./icons/4-icon.svg"></img>
                <h1>Dispute Resolution Assurance</h1>
              </div>
              <div className="fraud-handling-info">
                Safeguard your transactions with RAPTORX's built-in explainable
                AI solution, against chargebacks, ensuring fair and efficient
                dispute resolution.
              </div>
            </div>
            <div className="transaction-wrapper">
              <div className="transaction-title">
                <img src="./icons/5-icon.svg"></img>
                <h1>Regulatory Compliance Mastery</h1>
              </div>
              <div className="fraud-handling-info">
                Navigate industry regulations effortlessly with RAPTORX's
                solution allowing you to operate with confidence.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LandingPage;
