import React, { useState } from "react";
import PopUp from "./PopUp";
import "./FraudDetection.css";
import NumberList from "Components/NumberList";
import IconCards from "Components/solutions/IconCards";


export default function FraudDetection() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:mt-20  px-4 md:px-0" style={{ background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f2930)' }}>

        <div className="flex flex-col gap-2 pt-10 items-start justify-center px-[5px] md:px-4 lg:pl-32" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0">ZERO TRUST CONTINUOUS USER PROFILING</div>
          <div id="adjust-text" className="my-0 text-[30px] lg:text-[36px]">
          Elevate Confidence with Continuous User Profiling
          </div>
          <div className="fraud-handling-info text-[16px]">
          For unwavering trust in user authenticity, turn to RaptorX.ai. Our Continuous User Profiling assures real-time authentication, zero disruption to customers—truly a win-win.          </div>
          <a href="#formData">
          <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-6 border-none text-white ">
          See How It Works
          </button>
          </a>
          
        </div>
        <div className=" my-10 flex justify-center ">
          <img src="../images/Identity/identity.svg" className="lg:w-8/12"></img>
        </div>
      </div>
      <div className="content-container pb-10">
        <NumberList
          icon="../images/Identity/identity2.svg"
          title="ZERO TRUST USER PROFILING WITH RAPTORX"
          heading="How RaptorX.ai's Zero Trust User Profiling Works"
          desc="In the absence of advanced technology, verifying digital identities can seem nearly impossible. However, with RaptorX,ai's solutions and data, we achieve accurate user identity verification within milliseconds, ensuring Zero Trust User Profiling at every key stage of the user journey"
          list={[
            {heading: "User Interaction", desc: "An individual engages with your business, such as attempting to open an account, login, during session or make a purchase, with Zero Trust in mind."},
            {heading: "Data Matching and Assessment", desc: "The individual's information, including device and identity data, is assessed in real-time against our comprehensive database of insights, adhering to the Zero Trust principle."},
            {heading: "Assessment Score", desc: "Our machine learning algorithms return an assessment score along with reason codes for identity, phone, email, address, and device, maintaining Zero Trust User Profiling."},
            {heading: "User Verification", desc: "Meticulous scrutiny of data using UML, SML, and LLM for risk assessment."},
          ]}
        />
      </div>
      {/* starts */}
      {/* <div className="content-container " style={{background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)', paddingTop: "5rem", paddingBottom:"5rem"}}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="above headerTextAlign-center">
                <p id="offers">UNIQUE FEATURES DELIVER NEEDED BENIFITS</p>
                <h2 className="text-center text-white font-semibold leading-6 text-xl md:text-3xl font-Gilroy-SemiBold">
                  {" "}
                  RaptorX.ai enhances your identity <br /> verification strategy
                </h2>
                <div className="formattedTextBlock header-description text-[#FFFFFFB2] text-center mt-4">
                  <p className="mb-6">
                    RaptorX.ai technology provides the capabilities you need <br className="hidden md:flex" /> to elevate your identity verification strategy
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row multicol justify-content-center columnStyle-fullWidthImageAbove ">
            <div className="col-lg col-md-6 col-sm-12  ">
              <div className="d-flex flex-column h-100 pl-5" >
                <div className="flex items-center justify-center mb-1.25rem bg-[#35ADF4] rounded-full w-12 h-12 mb-2">
                  <img
                    src="../images/Identity/icon1.svg"
                    alt="refunding service header image"
                    className=" w-8 h-8 "
                  />
                </div>

                <h3 className="header-text text-[White]">Passwordless Authentication</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                  Enable users to log into accounts without a password — which can easily be stolen. Verify identities with more secure features like facial recognition, biometrics, or trusted devices.                  </p>
                </div>

              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 pl-5">
              <div className="flex items-center justify-center mb-1.25rem bg-[#35ADF4] rounded-full w-12 h-12 mb-2">
                  <img
                    src="../images/Identity/icon2.svg"
                    alt="refunding service header image"
                    className=" w-8 h-8 "
                  />
                </div>
                <h3 className="header-text text-[White]">Reusable Identities</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                  Leverage our expansive network to reduce friction. Once an identity has been verified through our technology, the individual can seamlessly interact with any Panopila client.                  </p>
                </div>
              </div>
            </div>

          </div>
          
          <div className="row multicol justify-content-center columnStyle-fullWidthImageAbove mt-4">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 pl-5">
              <div className="flex items-center justify-center mb-1.25rem bg-[#35ADF4] rounded-full w-12 h-12 mb-2">
                  <img
                    src="../images/Identity/icon3.svg"
                    alt="refunding service header image"
                    className=" w-8 h-8 "
                  />
                </div>
                <h3 className="header-text text-white">Auto-Fill Forms</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                  Auto-populate new account creation forms with information stored in our database. Verify user identities in real-time with a frictionless experience and minimized risk of fraud.                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 pl-5">
              <div className="flex items-center justify-center mb-1.25rem bg-[#35ADF4] rounded-full w-12 h-12 mb-2">
                  <img
                    src="../images/Identity/icon4.svg"
                    alt="refunding service header image"
                    className=" w-8 h-8 "
                  />
                </div>
                <h3 className="header-text text-white">Identity Proofing</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                  Quickly and accurately verify the authenticity of identity documents. Easily detect fake IDs, protect your business, and comply with know-your-customer regulations.                  </p>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div> */}
      <div className="content-container">
        <div className="container pt-10 pb-20">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="checklistColumns headerTextAlign-center">
                <p id="offers">WHY IDENTITY VERIFICATION MATTERS</p>
                <h2 className="text-center text-white font-semibold leading-6 text-xl md:text-3xl font-Gilroy-SemiBold">
                  {" "}
                  Benefits you can’t afford to miss
                </h2>
                <div className="header-description text-center">
                  <p className="text-[#FFFFFFB2]">
                  Do you want a better way for users to prove their identities? Implementing a software  <br className="hidden md:flex" />solution for verifying IDs is key.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row multicol justify-content-center flex-column columnStyle-checklistColumns ">
              <div
                  className="align-items-center justify-content-between grid lg:grid-cols-2 grid-cols-1 "
                  style={{ width: "100%"}}
                >
                  <div className="d-flex flex-column h-100 checklist mt-16 lg:ml-20 lg:order-first ml-4">
                    <h3 className="header-text" id="adjust-text">
                      WITHOUT ID <span className="bg-gradient-to-r from-[#16EE9D] to-[#08A0F3] inline-block text-transparent bg-clip-text font-extrabold" >VERIFICATION&nbsp; {" "}</span><br></br>
                    </h3>
                    <div className="formattedTextBlock header-description mt-4">
                      <ul>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          <span>Decline legitimate customers</span>
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                            Expose your business to risk
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Frustrate customers
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Create skepticism
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex justify-center lg:order-last order-first">
                  <img src="../images/Identity/Frameidentity3.svg" />
                  </div>
                </div>
                <div
                  className="align-items-center justify-content-between grid lg:grid-cols-2 grid-cols-1 "
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-center">

                  <img src="../images/Identity/identity4.svg" />
                  </div>
                  <div className="d-flex flex-column h-100 checklist mt-16 lg:ml-20 ml-4">
                    <h3 className="header-text" id="adjust-text">
                      WITH ID <span className="bg-gradient-to-r from-[#16EE9D] to-[#08A0F3] inline-block text-transparent bg-clip-text font-extrabold" >PROTECTION&nbsp; {" "}</span>
                    </h3>
                    <div className="formattedTextBlock header-description mt-4">
                      <ul>
                        <li className="liSolution flex items-center gap-3  text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Decrease false positives
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Enhance fraud prevention
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Reduce friction
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Establish trust
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* start here */}
      {/* <div className="content-container " style={{background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)', paddingTop: "2rem"}}>
        <div className="container pt-10 pb-10">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="above headerTextAlign-center">
                <p id="offers">WHAT MAKES RAPTORX.AI SPECIAL</p>
                <h2 className="text-center text-white font-semibold leading-6 text-xl md:text-3xl font-Gilroy-SemiBold">
                  {" "}
                  Added value achieves better results
                </h2>
                <div className="formattedTextBlock header-description text-[#FFFFFFB2] text-center mt-4">
                  <p className="mb-6">
                  Not all verification processes are able to achieve the results you <br /> desire. But Panopila can. Here’s what sets us apart.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row multicol justify-content-center columnStyle-fullWidthImageAbove ">
            <div className="col-lg col-md-6 col-sm-12 ">
              <div className="d-flex flex-column h-100 pl-5 ">
                <div className="flex items-center justify-center mb-1.25rem bg-[#73ed94] rounded-full w-12 h-12 mb-2">
                  <img
                    src="../images/Identity/icon11.svg"
                    alt="refunding service header image"
                    className=" w-8 h-8"
                  />
                </div>

                <h3 className="header-text text-[White]">Distinctive Identity Verification</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                  RaptorX.ai specializes in combatting account takeover fraud and identity theft. Unlike many verification processes, RaptorX.ai's technology is tailored specifically for these critical security challenges. </p>
                </div>

              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 pl-5">
              <div className="flex items-center justify-center mb-1.25rem bg-[#73ed94] rounded-full w-12 h-12 mb-2">
                  <img
                    src="../images/Identity/icon22.svg"
                    alt="refunding service header image"
                    className=" w-8 h-8 "
                  />
                </div>
                <h3 className="header-text text-[White]">Tailored Technology</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                  RaptorX.ai's flexible technology offers customizable solutions for identity verification. Whether through passwordless authentication or reusable identities, we adapt our methods to suit your business's unique needs.                 </p>
                </div>
              </div>
            </div>

          </div>
          
          <div className="row multicol justify-content-center columnStyle-fullWidthImageAbove mt-4">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 pl-5">
              <div className="flex items-center justify-center mb-1.25rem bg-[#73ed94] rounded-full w-12 h-12 mb-2">
                  <img
                    src="../images/Identity/icon33.svg"
                    alt="refunding service header image"
                    className=" w-8 h-8 "
                  />
                </div>
                <h3 className="header-text text-white">Comprehensive Approach</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                  While our primary focus is account takeover fraud and identity theft prevention, RaptorX.ai goes beyond verification by providing additional services for payments and compliance. By offering an all-in-one strategy, we streamline your fraud prevention efforts, reduce costs, and optimize revenue potential.                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 pl-5">
              <div className="flex items-center justify-center mb-1.25rem bg-[#73ed94] rounded-full w-12 h-12 mb-2">
                  <img
                    src="../images/Identity/icon44.svg"
                    alt="refunding service header image"
                    className=" w-8 h-8 "
                  />
                </div>
                <h3 className="header-text text-white">Expansive Data Network</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                  As part of Equifax, Panoplia harnesses a vast repository of digital and physical identity attributes. This extensive data network empowers us to provide highly accurate risk mitigation while ensuring a seamless experience for your genuine customers.                  </p>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div> */}
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}

// fill="#16ee9d"