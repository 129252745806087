import React, { useState } from "react";
import PopUp from "./PopUp";
import first from "../src/img/Frame.svg";
import second from "../src/img/ml2.svg";
import icon1 from "../src/img/1.svg";
import icon2 from "../src/img/icon2.svg";
import icon3 from "../src/img/3.svg";
import last from "../src/img/OBJECTS.svg";
import six from "../src/img/6.svg";
export default function GraphicalDashboard() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  return (
    <>
      <div className="flex-col flex justify-center bg-background-dark items-center md:pt-16" style={{ marginTop: "0rem" }} >
        <div
          className="grid grid-cols-1 lg:grid-cols-2  pt-16 lg:pl-8  bg-[#11171F]"
          style={{
            background:
              "linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)",
              marginTop: "0rem",
          }}
        >
          <div
            className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6"
            style={{ maxWidth: "100%" }}
          >
            <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">
              INCREASED ACCURACY & LOWER FALSE POSITIVES
            </div>
            <div className="text-4xl lg:text-5xl text-white font-semibold">
              Digital trust and safety using machine learning
            </div>
            <div className="fraud-handling-info">
              Discover RaptorX's cutting-edge machine learning for unparalleled
              digital trust and safety. Our optimized blend of unsupervised and
              supervised techniques ensures exceptional efficiency and
              precision, delivering unmatched results.
            </div>
            <a href="#formData">
            <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-0 border-none text-white">
              See How It Works
            </button>
            </a>
            
          </div>
          <div className="flex justify-center">
            <img
              src={first}
              className="w-7/12 "
              style={{ marginBottom: "3rem" }}
            ></img>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div
          className="transaction-image-wrapper grid grid-cols-1 lg:grid-cols-2"
          style={{ display: "grid", maxWidth: "100%" }}
        >
          <div className="authorized-section md:order-first">
            <img src={second}></img>
          </div>

          <div className="wallet-info order-first">
            <div className="inline-flex flex-col items-left gap-4 max-w-40rem">
              <div className="text-[#35adf4] font-medium text-lg leading-normal tracking-wider text-left">
                RAPTORX'S MACHINE LEARNING
              </div>

              <div
                id="full_confidence_and_security__no_matter_what_comes_your_way_"
                style={{ textAlign: "left" }}
              >
                How machine learning works?
              </div>
              <div className="fraud-handling-info text-left">
                RaptorX employs a dynamic blend of unsupervised and supervised
                machine learning for efficient and accurate digital trust and
                safety. Detects novel threats and recognizes established fraud
                patterns, ensuring a robust defense against emerging risks.
              </div>
            </div>
            <div className="transaction-title">
              <img src="../icons/1-icon.svg"></img>
              <h1>An interaction is detected.</h1>
            </div>
            <div className="fraud-handling-info">
              A user begins to interact with your brand — anything from
              accessing an account, requesting a loan, or buying a product from
              an online store.
            </div>
            <div className="transaction-title">
              <img src="../icons/2-icon.svg"></img>
              <h1>Data is collected.</h1>
            </div>
            <div className="fraud-handling-info">
              The technology gathers insights about the user such as location,
              propensity to spend, device in use, and more.{" "}
            </div>
            <div className="transaction-title">
              <img src="../icons/3-icon.svg"></img>
              <h1>Data is analyzed.</h1>
            </div>
            <div className="fraud-handling-info">
              The technology gathers insights about the user such as location,
              propensity to spend, device in use, and more.{" "}
            </div>
            <div className="transaction-title">
              <img src="../icons/4-icon.svg"></img>
              <h1>Safety is calculated.</h1>
            </div>
            <div className="fraud-handling-info">
              The technology determines how risky the interaction is and the
              level of trust.{" "}
            </div>
            <div className="transaction-title">
              <img src="../icons/5-icon.svg"></img>
              <h1>Policies are consulted.</h1>
            </div>
            <div className="fraud-handling-info">
              The technology analyzes established business policies to determine
              your acceptable risk thresholds.{" "}
            </div>
            <div className="transaction-title">
              <img src={six}></img>
              <h1>The database is updated.</h1>
            </div>
            <div className="fraud-handling-info">
            Event outcomes are recorded so the technology learns from each interaction and decision accuracy consistently improves over time.{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="content-container mt-5 " style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)', paddingTop: "5rem", paddingBottom :"2rem" , paddingLeft:"1rem", paddingRight:"1rem" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="above headerTextAlign-center">
                  <p className="text-center font-medium text-base text-[#35adf4] tracking-[4px]">WHAT MAKES RAPTORX.AI DIFFERENT</p>
                  <h2 className="text-white font-medium text-3xl leading-tight text-center mb-3">What could machine learning do for your business?</h2>
                  <div className="header-description  text-center mb-10" style={{color:'#FFFFFFB2'}}>
                    <p>
                    RaptorX.ai excels in fraud prevention with a dynamic blend of Unsupervised, Supervised, and Language Model-based Learning, offering a holistic solution for adaptive digital trust and safety.                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center textAlignLeft text-white">
              <div className="col-lg col-md-6 col-sm-12 ">
                <div className="d-flex flex-column h-100 mb-14 mx-2 md:mx-0 ">

                  <div className="mr-0">
                    <img
                      className="w-14"
                      alt="Icon of a shield and card"
                      src={icon1}
                    />
                  </div>
                  <h3 className="header-text mt-4 text-[18px]">Supervised Machine Learning</h3>

                  <div className="" style={{color:'#FFFFFFB2'}}>
                    <p className="text-[14px]">
                    RaptorX.ai's Supervised Machine Learning acts like a seasoned detective, identifying fraud patterns in user histories for precise threat assessment.
                    </p>
                  </div>
                  <div className="row align-items-end ">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <div className="d-flex flex-column h-100 mb-14 mx-2 md:mx-0">

                  <div className="mr-0">
                    <img
                      className="w-14"
                      alt="Icon of identity verification"
                      src={icon2}
                    />
                  </div>
                  <h3 className="header-text mt-4 text-[18px]">Unsupervised Machine Learning</h3>

                  <div style={{color:'#FFFFFFB2'}}>
                    <p className="text-[14px]">

                    RaptorX.ai's Unsupervised Machine Learning acts as a silent sentinel, detecting novel threats in vast datasets, providing early warnings in fraud prevention.
                    </p>
                  </div>
                  <div className="row align-items-end">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <div className="d-flex flex-column h-100  mx-2 md:mx-0">

                  <div className="mr-0">
                    <img
                      className="w-14"
                      alt="Icon of a legal document"
                      src={icon3}
                    />
                  </div>
                  <h3 className="header-text mt-4 text-[18px]">Language Model-based Learning (LLM)</h3>

                  <div style={{color:'#FFFFFFB2'}}>
                    <p className="text-[14px]">

                    RaptorX's Language Model-based Learning decodes unstructured data, enhancing precision in identifying nuanced fraud signals for adaptability in fraud detection.
                    </p>
                  </div>
                  <div className="row align-items-end">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-container">
        <div
          className="transaction-image-wrapper grid grid-cols-1 lg:grid-cols-2"
          style={{ display: "grid", maxWidth: "100%" }}
        >
          <div className="authorized-section">
            <img src={last}></img>
          </div>

          <div className="wallet-info">
            <div className="inline-flex flex-col items-left gap-4 max-w-40rem">
              <div className="text-[#35adf4] font-medium text-lg leading-normal tracking-wider text-left">
                WHAT MAKES RAPTORX.AI DIFFERENT
              </div>

              <div
                id="full_confidence_and_security__no_matter_what_comes_your_way_"
                style={{ textAlign: "left" }}
              >
What could machine learning do for your business?              </div>
              <div className="fraud-handling-info text-left">
              RaptorX.ai pioneers fraud prevention with a blend of Unsupervised, Supervised, and Language Model-based Learning, ensuring precise detection of emerging and established fraud patterns, solidifying its leadership in adaptive digital trust and safety.
              </div>
            </div>
            <div className="transaction-title">
              <img src="../icons/1-icon.svg"></img>
              <h1>Supervised Machine Learning</h1>
            </div>
            <div className="fraud-handling-info">
            Supervised Machine Learning within RaptorX.ai is like a seasoned detective with a library of past cases. It scrutinizes user histories, recognizing established fraud patterns such as chargebacks, refunds, or defaults. SML ensures precision in identifying known threats, delivering an accurate assessment of trustworthiness.
            </div>
            <div className="transaction-title">
              <img src="../icons/2-icon.svg"></img>
              <h1>Unsupervised Machine Learning</h1>
            </div>
            <div className="fraud-handling-info">
            In the world of RaptorX.ai, Unsupervised Machine Learning is the silent sentinel that sifts through vast datasets without predefined labels, identifying novel patterns and emerging threats. It's the first line of defense against the unknown, offering early warnings when specific fraud patterns have yet to be defined.{" "}
            </div>
            <div className="transaction-title">
              <img src="../icons/3-icon.svg"></img>
              <h1>Language Model-based Learning (LLM)</h1>
            </div>
            <div className="fraud-handling-info">
            RaptorX's Language Model-based Learning introduces a new layer of contextual understanding, like a linguist fluent in the nuances of user interactions. LLM deciphers unstructured data, capturing subtle fraud signals within free-form text, chat logs, or descriptions, enhancing adaptability and precision in identifying complex fraud schemes.{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
