import React,{useState, useEffect} from "react";
import {Link} from 'react-router-dom'
import "./Footer.css";
import ScrollToTop from "Components/ScrollToTop";


function Footer() {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
  });

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://primary-obliging-koala.ngrok-free.app/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "unsafe-url",
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(data); // You can handle the response as needed

      // Show success message and reset the form
      setShowSuccessMessage(true);
      setFormData({
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        phone: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    // Hide success message after 3 seconds
    const timeout = setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showSuccessMessage]);


  return (
    <>
    <ScrollToTop/>
      <div className="content-container bg-[#020811]">
        <div
          className="transaction-image-wrapper"
          style={{ maxWidth: "76.5rem", marginTop: "0", marginBottom: "1rem" }}
        >
          <div className="wallet-info">
            <div id="personalized-solution">EXPLORE RAPTORX.ai</div>
            <div id="adjust-text">Curious to find out more?</div>
            <div className="fraud-handling-info">
              Schedule a conversation with our team to find out how to grow your
              business with more confidence.
            </div>
          </div>
          <div className="user-form" id="formData">
          <form onSubmit={handleSubmit}>
            <h1 className= "mb-3">Schedule a Demo</h1>
            <div className="input-wrapper">
            <input
              type="text"
              placeholder="*Corporate email address"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="*First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="*Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="*Company"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="*Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
            </div>
            <div className="d-flex justify-content-center mt-5">
            <button
              type="submit"
              className="btnColor rounded-md flex items-start px-7 py-2 justify-start gap-4 mt-0 border-none text-white"> Request a Demo</button>
            </div>
            </form>
            {showSuccessMessage && (
              <div className="success-message text-white d-flex justify-content-center">
                Email sent successfully! Form has been reset.
              </div>
            )}
            <div className="info">
              We'd like to stay in touch! By sharing your contact details, you
              allow us to reach out regarding our products and services. If you
              ever wish to stop receiving these messages, you can easily
              unsubscribe. For details on how to do so and to learn more about
              our commitment to keeping your information safe, please review our
              Privacy Policy.
            </div>
          </div>
        </div>
      </div>
      <div
        className="content-container p-2"
        style={{ marginTop: "0", backgroundColor: "#11171F" }}
      >
        <div
          className="grid grid-cols-2 lg:grid-cols-3 gap-16 p-[1rem] md:p-[3.5rem] lg:gap-8"
          style={{ width: "100%" }}
        >
          <div
            className="d-flex flex-column items-center lg:items-start gap-3 col-span-2 lg:col-span-1"
            id="footer-description"
            // style={{ maxWidth: "24.51rem" }}
          >
            <img src="../icons/mainLogo.svg"></img>
            <div style={{ color: "rgba(255, 255, 255, 0.70)" }} className="lg:hidden text-center">
              At Raptorx.ai, our technology is all about trust and safety. We make
              sure every interaction fills you with confidence.
            </div>
            <div style={{ color: "rgba(255, 255, 255, 0.70)" }} className="hidden text-left lg:flex">
              At Raptorx.ai, our technology is all about trust and safety. We make
              sure every interaction fills you with confidence.
            </div>
          </div>
          <div
            className="flex-column gap-3 justify-center md:justify-start col-span-2 items-center lg:items-start lg:hidden flex"
            style={{ width: "100%" }}
          >
            <div className="d-flex align-items-start gap-2 opacity-50">
              <img src="../icons/facebook-icon.svg" alt="facebook-icon" />
              <img src="../icons/instagram-icon.svg" alt="instagram-icon" />
              <img src="../icons/twitter-icon.svg" alt="twitter-icon" />
              <img src="../icons/telegram-icon.svg" alt="telegram-icon" />
              <img src="../icons/youtube-icon.svg" alt="youtube-icon" />
            </div>
            <div style={{ color: "rgba(255, 255, 255, 0.30)" }} className="text-center lg:text-left">
              © Copyright 2023 Raptorx.ai, All rights reserved.
            </div>
          </div>
          <div
            className="flex-column align-items-start gap-3 lg:pl-32 lg:hidden flex"
            style={{ color: "rgba(255, 255, 255, 0.70)" }}
          >
            <div id="personalized-solution" className="text-center w-full">Company</div>
            <Link to='/why-raptorx' className="text-center w-full text-[#808388]">
              About Us
            </Link>
            <Link to='/careers' className=" text-center w-full text-[#808388]">
              Careers
            </Link>
            <div className=" text-center w-full text-[#808388]">News and Press</div>
          </div>
          <div
            className="flex-column align-items-start gap-3 lg:pl-32 hidden lg:flex"
            style={{ color: "rgba(255, 255, 255, 0.70)" }}
          >
            <div id="personalized-solution" >Company</div>
            <Link to='/why-raptorx' className="text-[#808388]">
              About Us
            </Link>
            <Link to='/careers' className="text-[#808388]">Careers</Link>
            <div className="text-[#808388]">News and Press</div>
          </div>
          <div
            className="flex-column align-items-start gap-3 lg:pl-16 flex lg:hidden"
            style={{
              maxWidth: "19.4375rem",
              color: "rgba(255, 255, 255, 0.70)",
            }}
          >
            <div id="personalized-solution" className="text-center w-full">Support</div>
            <div className="text-center w-full text-[#808388]">Login</div>
            <div className="text-center w-full text-[#808388]">Developers</div>
            {/* <div className="text-center w-full text-[#808388]">Pricing</div> */}
            <div className="text-center w-full text-[#808388]">Support</div>
            <div className="text-center w-full text-[#808388]">FAQs</div>
          </div>
          <div
            className="flex-column align-items-start gap-3 lg:pl-16 hidden lg:flex"
            style={{
              maxWidth: "19.4375rem",
              color: "rgba(255, 255, 255, 0.70)",
            }}
          >
            <div id="personalized-solution">Support</div>
            <div className="text-[#808388]">Login</div>
            <div className="text-[#808388]">Developers</div>
            {/* <div className="text-[#808388]">Pricing</div> */}
            <div className="text-[#808388]">Support</div>
            <div className="text-[#808388]">FAQs</div>
          </div>
          <div
            className="flex-column gap-3 justify-center md:justify-start col-span-2 items-center lg:items-start hidden lg:flex"
            style={{ width: "100%" }}
          >
            <div className="d-flex align-items-start gap-2 opacity-50">
              <img src="../icons/facebook-icon.svg" alt="facebook-icon" />
              <img src="../icons/instagram-icon.svg" alt="instagram-icon" />
              <img src="../icons/twitter-icon.svg" alt="twitter-icon" />
              <img src="../icons/telegram-icon.svg" alt="telegram-icon" />
              <img src="../icons/youtube-icon.svg" alt="youtube-icon" />
            </div>
            <div style={{ color: "rgba(255, 255, 255, 0.30)" }} className="text-center lg:text-left">
              © Copyright {currentYear} Raptorx.ai, All rights reserved.
            </div>
          </div>
        </div>
        <div className="d-flex"></div>
      </div>
      <div className="py-3 flex justify-center" id="footer-base" style={{ backgroundColor: "#1B2129" }}>
        <div className="flex justify-between md:justify-around lg:w-7/12 w-11/12">
          <div className="col-auto">
            <a href="/legal/terms-of-use" className="text-[12px] md:text-[14px]">Terms of Use</a>
          </div>
          <div className="col-auto">
            <a href="/legal/compliance" className="text-[12px] md:text-[14px]">Compliance</a>
          </div>
          <div className="col-auto">
            <a href="/legal/privacy" className="text-[12px] md:text-[14px]">Privacy</a>
          </div>
          <div className="col-auto">
            <a href="/legal/privacy-policy" className="text-[12px] md:text-[14px]">Privacy Policy</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
