import React, { useState } from "react";
import PopUp from "./PopUp";
import BankingTickList from "Components/banking/BankingTickList";

export default function Banking() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <div className="overflow-y-hidden ">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:mt-20 px-4 pt-5 lg:px-0 lg:pt-0" style={{ background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f2930)' }}>

        <div className="flex flex-col gap-2 items-start justify-center px-[5px] md:px-4 lg:pl-32" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0">Smart Tracking and User Insights</div>
          <div id="adjust-text" className="my-0 text-[30px] lg:text-[36px]">
            Smart monitoring and profiling for your needs.
          </div>
          <div className="fraud-handling-info text-[16px]">
            RaptorX offers a cutting-edge solution tailored to your needs, combining meticulous transaction monitoring with intelligent user profiling.
          </div>
          <a href="#formData">
          <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-6 border-none text-white ">
            Contact Us
          </button>
          </a>
          
        </div>
        <div className=" my-10 flex justify-center ">
          <img src="../images/banking/hero.svg" className="w-9/12 lg:w-6/12"></img>
        </div>
      </div>

      <div className="mt-16 md:mt-24 ml-0 bg-[#020811] grid lg:grid-cols-2 pb-16 md:pb-24 grid-cols-1 md:px-28">
        <div className="flex flex-col gap-2 items-start justify-center px-4 md:px-[5px] md:px-4 lg:pl-32" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0 text-center md:text-left">The Banking Conundrum</div>
          <BankingTickList heading="Modernizing Transaction Monitoring and User Profiling" desc="Old monitoring systems are causing problems, like inefficiency and complex rules. Adding more people used to fix it, but not anymore. We need a new way because rules and tech are changing." list={[
            "Streamlined Efficiency",
            "Proactive Effectiveness",
            "Transparent Clarity"
          ]} listHead="Unlock the Potential with RaptorX.ai-" />
        </div>
        <div className="flex justify-center">
          <img src="../images/banking/list1.svg" className="lg:w-12/12"></img>
        </div>
      </div>

      {/* <div className="py-16 px-4 text-white max-w-[100%]" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>
        <div className="text-center w-12/12 lg:w-full">
          <h6 className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium text-center mb-4">EXPERT INSIGHT</h6>
          <h2 className="mx-auto text-2xl md:text-[28px] lg:text-[32px] text-white font-semibold mb-4">THE FUTURE OF TRANSACTION MONITORING</h2>
          <p className="text-[#FFFFFFB2] text-center mx-auto lg:w-6/12 text-[16px] px-3">RaptorX:AI voiced key considerations on “Building future state of transaction monitoring
            & reporting – emerging ways to detect & disrupt financial crime”, read the full panel discussion featuring HSBC, BNP Paribas and AlixPartners.</p>
        </div>

        <div className="flex justify-center items-center mt-8">
          <button className="btnColor rounded-md px-6 py-2  gap-4 mt-6 border-none text-white ">
            Request a Demo
          </button>
        </div>
      </div> */}

      <div className="mt-2 gap-6 md:gap-2 md:mt-2 ml-0 bg-[#020811] grid lg:grid-cols-2 py-8 md:py-24 grid-cols-1" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>
        
        <div className="flex flex-col gap-2 items-start justify-center md:px-[5px]  lg:pl-32 px-4" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0 text-center md:text-left">Boosting Fraud Prevention</div>
          <BankingTickList heading="Ramp up fraud protection with RaptorX.ai." desc="Optimize Resources: Implement automation strategically to reduce reliance on expanding teams and streamline compliance reporting." list={[
            "Precision Fraud Detection",
            "Chargeback Savings",
            "Effortless Compliance Reporting"
          ]} listHead="RaptorX.ai Benefits-" />

        </div>
        <div className="flex justify-center md:order-first">
          <img src="../images/banking/list2.svg" className="lg:w-10/12"></img>
        </div>

      </div>

      <div className=" ml-0 grid lg:grid-cols-2 grid-cols-1 py-16 md:py-24 px-4" >
      <div className="flex flex-col gap-2 items-start justify-center px-[5px] md:px-4 lg:pl-32" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0 flex justify-center md:justify:start text-center">TRANSACTION MONITORING</div>
          <BankingTickList heading="RaptorX.ai: Transforming Monitoring and Risk Assessment" desc="Explore precise monitoring and risk assessment with RaptorX.ai for accurate insights and real-time trust." list={[
            "Real-Time Transaction Monitoring",
            "Behavioral Analytics",
            "Minimized False Positives",
            "Early Threat Detection",
            "Future-Ready Solutions",
            "Efficiency and Time Savings",
            "Robust Data Protection"
          ]} listHead="Unlock the Potential with RaptorX.ai-" />

        </div>
        <div className="flex justify-center">
          <img src="../images/banking/list3.svg" className="lg:w-10/12"></img>
        </div>

      </div>

      <div className="mt-2 md:mt-2 ml-0 bg-[#020811] grid lg:grid-cols-2 py-6 md:py-24 grid-cols-1 px-4 gap-12 md:gap-2" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>
        
        <div className="flex flex-col gap-2 items-start justify-center px-[5px] md:px-4 lg:pl-32" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0 text-center md:text-left">Zero Trust Risk Profiling</div>
          <BankingTickList heading="Customer Risk Rating for Banks" desc="Unleash the power of continuous customer risk assessment with RaptorX.ai's innovative solution. Discover and manage the risk rating of every client in a transparent and efficient manner." list={[
            "Dynamic Risk Scoring",
            "Customized Risk Ratings",
            "Efficiency Redefined",
            "User-Friendly Configuration"
          ]} listHead="Key Advantages of Zero Trust Risk Profiling with RaptorX.ai-" />

        </div>
        <div className="flex justify-center md:order-first">
          <img src="../images/banking/list4.svg" className="lg:w-9/12"></img>
        </div>

      </div>

    </div>
  );

}
