import React from 'react';

const FintechIndustry = ({ imageUrl, title, description }) => {
  return (
    <div className="text-white md:order-1 flex justify-center items-center" style={{ marginTop: '2rem' }}>
      <img
        src={imageUrl}
        alt={`Image Alt Text`}
        className="object-cover rounded-t-md md:rounded-l-md w-20 h-20"
      />
     <div className="ml-2">
  <h5 className="font-bold t" style={{color:"#35ADF4"}}>{title}</h5>
  <h6>{description}</h6>
</div>

    </div>
  );
};
export default FintechIndustry