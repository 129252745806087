import React, { useState } from "react";
import PopUp from "./PopUp";
import first from "../src/img/explainAiFirst.png"
import second from "../src/img/ExplainAi2.png"
import third from "../src/img/ExplainAi3.png"
import fourth from "../src/img/ExplainAi4.png"
export default function ExplainabilityAi() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  return (
    <>
      <div className="" style={{ marginTop: "0rem" }}>

      <div className="grid grid-cols-1 lg:grid-cols-2 pt-6  md:pt-28 lg:pl-8  bg-[#11171F]" style={{background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)'}}>
               
               <div className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6" style={{ maxWidth: '100%' }}>
                   <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">Explainability AI</div>
                   <div className='text-4xl lg:text-5xl text-white font-semibold'>
                   Your insights,Your preferences.                      
                       
                   </div>
                   <div className="fraud-handling-info">
                   At RaptorX.ai, we understand your business is one-of-a-kind, driven by your distinct objectives. Our Explainability AI doesn't just provide answers; it empowers you to tailor the decision-making process according to your unique needs. Your strategies, your way.
                   </div>
                   <a href="#formData">
                   <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-0 border-none text-white">
                   See How It Works
                   </button>
                   </a>
                   
               </div>
               <div className="flex justify-center">
                   <img src={first}  className="md:w-7/12 w-10/12" style={{marginBottom:"3rem"}}></img>
               </div>
           </div>
     
      </div>
      <div className="flex-col flex justify-center bg-background-dark items-center pt-0 md:pt-14">
        
        <div className="transaction-image-wrapper grid grid-cols-1 lg:grid-cols-2" style={{display: 'grid', maxWidth: '100%'}}>
          <div className="flex items-center justify-center max-w-[100%] md:max-w-[70%] md:order-first">
            <img src={second}></img>
          </div>

          <div className="wallet-info order-first">
          <div className="inline-flex flex-col items-left gap-4 max-w-40rem">
          <div className="text-[#35adf4] font-medium text-lg leading-normal tracking-[0.3375rem] text-left">
          BUSINESS POLICIES AND EXPLAINABILITY AI
</div>

          <div id="full_confidence_and_security__no_matter_what_comes_your_way_" style={{textAlign:"left"}}>
          Empowering Trust and Safety through Policy Insights.
          </div>
          <div className="fraud-handling-info text-left">
          Consistently applied rules and policies ensure the best outcomes possible with the least amount of effort and greatest accuracy.
          </div>
        </div>
            <div className="transaction-title">
              <img src="../icons/1-icon.svg"></img>
              <h1>Unveiling Needs.</h1>
            </div>
            <div className="fraud-handling-info">
            Our experts uncover your distinct needs, tailoring an approach that suits you - hands-on, automated, or a blend of both.
            </div>
            <div className="transaction-title">
              <img src="../icons/2-icon.svg"></img>
              <h1>Crafting Policies.</h1>
            </div>
            <div className="fraud-handling-info">
            Handpick the monitoring variables, define risk tolerance, and specify desired actions within our Explainability AI framework.
            </div>
            <div className="transaction-title">
              <img src="../icons/3-icon.svg"></img>
              <h1>Enlightened Decision-Making.</h1>
            </div>
            <div className="fraud-handling-info">
            Our technology dissects interactions, guiding decisions that align seamlessly with your preset policies.
            </div>
            <div className="transaction-title">
              <img src="../icons/4-icon.svg"></img>
              <h1>Agile Policy Evolution.</h1>
            </div>
            <div className="fraud-handling-info">
            Stay ahead as policies adapt in real time to counter emerging threats, shifting risks, and evolving business landscapes.
            </div>
           
            
          </div>
        </div>
      </div>



       <div className="flex-col flex justify-center bg-background-dark items-center pt-2 md:pt-14">
          <div className="container-fluid rowCount_2">
            <div className="container">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="checklistColumns headerTextAlign-center">
                <p id="offers">WHY POLICIES MATTER</p>
                <h2 className="text-center text-white font-semibold leading-6 text-xl md:text-3xl font-Gilroy-SemiBold">
                  {" "}
                  Benefits you can’t afford to miss
                </h2>
                <div className="header-description text-center text-[#FFFFFFB2]">
                  <p>
                  Consistently applied rules and policies ensure the best outcomes possible with the least amount of effort and greatest accuracy.
                  </p>
                </div>
              </div>
            </div>
          </div>
              
              {/* new data started */}
              <div className="row ">
            <div className="col-lg-12 ">
              <div className="row multicol justify-content-center flex-column columnStyle-checklistColumns gap-0 md:gap-14 mb-10" >
              <div
                  className="col-lg col-md-6 col-sm-12  grid grid-cols-1 md:grid-cols-2 align-items-center justify-between gap-0 md:gap-16 mt-0 md:mt-8"
                  style={{ width: "100%"}}
                >
                <div className="flex items-center justify-center max-w-[100%] md:max-w-[100%]">
                <img src={third} />
          </div>
                
                  <div className="d-flex flex-column h-100 checklist mt-16">
                    <h3 className="header-text flex justify-center md:justify-start  text-center md:text-left" id="adjust-text">
                    Processes Without Explainability AI
                    </h3>
                    <div className="formattedTextBlock header-description mt-4 grid md:grid-cols-2 grid-cols-1 md:gap-3 gap-0">
                      <ul>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2] ">
                          <img src="../icons/cross-icon.svg" />
                          <span>Opaque Decision-Making - Limited Insights</span>
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Increased Risk
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Compliance Challenges
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Reactive Problem-Solving
                        </li>
                      </ul>
                     
                    </div>
                  </div>
                  
                </div>
                <div
                  className="col-lg col-md-6 col-sm-12 grid grid-cols-1 md:grid-cols-2  align-items-center justify-content-between"
                  style={{ width: "100%" }}
                >
                  <img src={fourth} className=" md:order-last"/>
                  <div className="d-flex flex-column h-100 checklist mt-16">
                    <h3 className="header-text flex justify-center md:justify-start text-center md:text-left" id="adjust-text">
                    Processes With Explainability AI
                    </h3>
                    <div className="formattedTextBlock header-description mt-4 grid md:grid-cols-2 grid-cols-1 md:gap-3 gap-0">
                      <ul>
                        <li className="liSolution flex items-center gap-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Transparent Decision-Making
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Rich Insights
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Mitigated Risks
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Streamlined Compliance
                        </li>
                        
                      </ul>
                     
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
              {/* ned data ends */}
            </div>
          </div>
        </div>
        <div className="content-container mx-0 py-[2rem]" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)',paddingTop:"2rem" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="above headerTextAlign-center">
                  <p className="text-center font-medium text-base text-[#35adf4] tracking-[4px]">SOLUTIONS</p>
                  <h2 className="text-white font-medium text-3xl leading-tight text-center mb-3">Complete Security</h2>
                  <div className="header-description text-center mb-10 text-[#FFFFFFB2] mx-3">
                    <p>
                    Elevate your business with RaptorX.ai – where confidence meets limitless <br className="hidden md:flex"></br>potential. Our adaptive platform prioritizes your needs for seamless growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center textAlignLeft mx-3">
              <div className="col-lg col-md-6 col-sm-12">
                <div className="d-flex flex-column h-100 mb-14 mx-2 md:mx-0">
                
      <div className="mr-0">
        <img
          className="w-14"
          alt="Icon of a shield and card"
          src="../icons/paymentFraud.svg"
        />
      </div>
      <h3 className="header-text mt-4 text-white">Payments Fraud</h3>
    
                  <div className="header-description text-[#FFFFFFB2] ">
                    <p>
                    Access the tools and strategies necessary to combat fraud and minimize chargebacks across the entire customer journey.
                    </p>
                  </div>
                  <div className="row align-items-end mt-3">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <div className="d-flex flex-column h-100 mb-14 mx-2 md:mx-0">
                
      <div className="mr-0">
        <img
          className="w-14"
          alt="Icon of identity verification"
          src="../icons/identityProtection.svg"
        />
      </div>
      <h3 className="header-text mt-4 text-white">Identity Protection</h3>
    
                  <div className="header-description text-[#FFFFFFB2] ">
                    <p>
                      
Understand the true identities behind brand interactions. Engage with the right customers at the right moments while ensuring their experience remains secure and seamless.
                    </p>
                  </div>
                  <div className="row align-items-end">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <div className="d-flex flex-column h-100  mx-2 md:mx-0">
                
      <div className="mr-0">
        <img
          className="w-14"
          alt="Icon of a legal document"
          src="../icons/regularityComp.svg"
        />
      </div>
      <h3 className="header-text mt-4 text-white">Regulatory Compliance</h3>
    
                  <div className="header-description text-[#FFFFFFB2]">
                    <p>
                    
Efficiently navigate the complex landscape of rules and regulations, minimizing disruptions for end-users while maintaining strict compliance.
Learn about card testing fraud prevention
                    </p>
                  </div>
                  <div className="row align-items-end">
                    <a
                      className="trust-safety-info"
                      style={{ textDecoration: "none" }}
                    >
                      Learn about card testing fraud prevention
                      <img src="../icons/arrow-icon.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
