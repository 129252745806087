import React, { useState } from "react";
import PopUp from "./PopUp";

export default function OnlineGaming() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div
              className="adjust-text d-flex align-items-start flex-column"
              id="adjust-text"
            >
              Trust and safety solutions for online gaming companies
            </div>
            <div className="fraud-handling-info">
              <p>
                It’s time to stop fraudsters from infiltrating your games and
                negatively affecting your relationship with your customers. And
                Kount can help. We offer online gaming fraud detection solutions
                that protect your customers’ gaming accounts and help grow your
                business.
              </p>
            </div>
            <div className="primary-button" onClick={togglePopUp}>
              See how it works
            </div>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img
              src="../images/mac-image.svg"
              alt="Identity Verification Hero"
            />
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol above text-center">
                <p id="offers" className="preHeading">
                  WHAT KOUNT DOES
                </p>
                <h2 className="header-text">
                  Complete protection. <br />
                  No matter the risk.
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Panopila offers a complete trust and safety strategy that
                    delivers confidence in every interaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100">
                <div className="colBGimg text-left">
                  <img
                    className="img-fluid"
                    alt="Panopila icon keys"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Prevent account takeover</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Block or challenge abnormal behavior from fraudsters
                    attempting to hack into gaming accounts. Build a
                    relationship of trust and confidence with your customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100">
                <div className="colBGimg text-left">
                  <img
                    className="img-fluid"
                    alt="lock credit card"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Block promo abuse</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Confidently extend offers like sign-up bonuses without
                    worrying about fake accounts created to abuse promotions.
                    Spend money on marketing that does more help than harm.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100">
                <div className="colBGimg text-left">
                  <img
                    className="img-fluid"
                    alt="key and person"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text">Provide seamless experiences</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Provide frictionless in-game or in-app buying experiences
                    for known customers with customizable security protocols.
                    Challenge suspicious behavior that could be fraud.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100">
                  <div className="colBGimg text-left">
                    <img
                      className="img-fluid"
                      alt="Approve more good signups"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Stop new account fraud</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Prevent customers and fraudsters from creating multiple
                      new accounts, a scheme known as multi-accounting, to scam
                      other players or unfairly gain advantages in games.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100">
                  <div className="colBGimg text-left">
                    <img
                      className="img-fluid"
                      alt="Prevent chargebacks"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Reduce chargebacks</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Protect against chargebacks from money laundering schemes,
                      payments fraud, and in-game artifact trading scams. Avoid
                      chargeback fees and time-consuming management
                      responsibilities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg col-md-6 col-sm-12">
                <div className="kountContentCol d-flex flex-column h-100">
                  <div className="colBGimg text-left">
                    <img
                      className="img-fluid"
                      alt="Resolve friendly fraud"
                      width="1"
                      height="1"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Resolve friendly fraud</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Help customers recognize unknown purchases and quickly
                      refund unintentional purchases to resolve disputes. Avoid
                      unnecessary chargebacks and maintain a good relationship
                      with your customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="kountContentCol">
                <p id="offers">RESULTS AT A GLANCE</p>
                <h2 class="header-text text-center">
                  What Panopila can do for you
                </h2>
                <p class="header-description text-center">
                  Panopila is trust and safety technology that has helped
                  businesses like yours reduce risk and increase revenue — with
                  the highest ROI possible. We understand the challenges unique
                  to your business. And we can help you solve them.&nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="kountContentCol">
                <div class="colIframe">
                  <div class="ratio ratio-16x9">
                    <iframe
                      class="b-lazy b-loaded"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""
                      src="https://www.youtube.com/embed/MgzwaD4XaVY"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="kountContentCol">
                <h2 class="header-text">
                  See how a gaming company uses Panopila to secure the online
                  gaming experience
                </h2>
                <div class="formattedTextBlock">
                  <p class="header-description">
                    Drop-In Gaming wants gamers to feel confident and secure
                    about building their accounts, storing their payment
                    information, and playing in online tournaments — and
                    Panopila helps achieve these goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div id="personalized-solution">GET STARTED TODAY</div>
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              See how trust and safety solutions from Panopila can benefit your
              business
            </div>
            <div className="primary-button" onClick={togglePopUp}>
              Schedule a demo
            </div>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
