import React from "react";

const missionWhyJoinUs = [
  {
    title: "Mission:",
    desc: "RaptorX.ai is on a mission to revolutionize fraud prevention and risk assessment using cutting-edge technologies. We are committed to creating innovative solutions that empower businesses to stay ahead of emerging threats and protect their assets.",
  },
  {
    title: "Why Join Us:",
    desc: "Joining RaptorX.ai means being part of a dynamic team at the forefront of innovation in the cybersecurity domain. As a member of our team, you will have the opportunity to work with the latest technologies and contribute to meaningful projects that have a real impact on businesses worldwide. We offer competitive salaries, ESOPs, and a collaborative work environment where your ideas are valued and encouraged.",
  },
];

const locationSalary = [
  {
    title: "Location:",
    desc: "Hyderabad, India",
  },
  {
    title: "Salary:",
    desc: "Competitive salary based on experience, with additional benefits including ESOPs.",
  },
];

export default function DataEngineer() {
  return (
    <div >
      <div className="bg-[#11171F] h-[350px] flex flex-col lg:flex-row justify-center items-center lg:place-content-center px-8 lg:px-24 lg:gap-44 sm:pt-20" style={{ marginTop: "0rem", paddingTop: "0rem" }}>
        <div className=" h-auto lg:h-[252px] lg:mr-0 flex flex-col justify-center gap-4 font-base sm:pt-20">
          <p className="font-gilroy text-[#1FA3ED] text-lg text-center mt-8">
            C A R E E R S
          </p>

          <p className="font-gilroy text-4xl font-medium text-white text-center mx-auto lg:mx-0">
            Data Engineer
          </p>

          <p className="font-gilroy text-gray-400 text-center mb-8">
            On-site. Hyderabad, India.
          </p>
        </div>
      </div>

      <div className="bg-[#020811] md:mx-16">
        <div className="pt-8 px-4 md:pt-20 md:px-20">
          <h1 className="font-gilroy text-2xl md:text-3xl text-white text-left">
            More Details
          </h1>
        </div>

        <div className="px-4 md:px-20">
          {missionWhyJoinUs.map((item, index) => (
            <div key={index} className="mt-6 md:mt-10">
              <div className="text-xl md:text-2xl font-gilroy text-white">
                {item.title}
              </div>
              <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
                {item.desc}
              </div>
            </div>
          ))}
        </div>

        <div className="px-4 md:px-20 mt-8 md:mt-10">
          <div>
            <div className="text-xl md:text-2xl font-gilroy text-white">
              Responsibilities:
            </div>
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              <ul className="list-disc pl-7">
                <li>
                  Designing and building optimized data pipelines using
                  cutting-edge technologies in a cloud environment to drive
                  analytical insights.This would also include develop and
                  maintain scalable data pipelines and builds out new API
                  integrations to support continuing increases in data volume
                  and complexity.
                </li>
                <li>
                  Constructing infrastructure for efficient ETL processes from
                  various sources and storage systems.
                </li>
                <li>
                  Collaborating closely with Product Managers and Business
                  Managers to design technical solutions aligned with business
                  requirements.
                </li>
                <li>
                  Leading the implementation of algorithms and prototypes to
                  transform raw data into useful information.
                </li>
                <li>
                  Architecting, designing, and maintaining database pipeline
                  architectures, ensuring readiness for AI/ML transformations.
                </li>
                <li>
                  Creating innovative data validation methods and data analysis
                  tools.
                </li>
                <li>
                  Ensuring compliance with data governance and security
                  policies.
                </li>
                <li>
                  Interpreting data trends and patterns to establish operational
                  alerts.
                </li>
                <li>
                  Developing analytical tools, utilities, and reporting
                  mechanisms.
                </li>
                <li>
                  Conducting complex data analysis and presenting results
                  effectively.
                </li>
                <li>
                  Preparing data for prescriptive and predictive modeling.
                </li>
                <li>
                  Continuously exploring opportunities to enhance data quality
                  and reliability.
                </li>
                <li>
                  Applying strong programming and problem-solving skills to
                  develop scalable solutions.
                </li>
                <li>
                  Writes unit/integration tests, contributes towards
                  documentation work.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 md:mt-10">
            <div className="text-xl md:text-2xl font-gilroy text-white">
              Requirements:
            </div>
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              <ul className="list-disc pl-7">
                <li>
                  6 to 8 years of hands-on experience designing, building,
                  deploying, testing, maintaining, monitoring, and owning
                  scalable, resilient, and distributed data pipelines.
                </li>
                <li>
                  High proficiency in Scala/Java/ Python API frameworks/ Swagger
                  and Spark for applied large-scale data processing.
                </li>
                <li>
                  Expertise with big data technologies, API development,
                  Flask,,including Spark, Data Lake, Delta Lake, and Hive.
                </li>
                <li>
                  Solid understanding of batch and streaming data processing
                  techniques.
                </li>
                <li>
                  Proficient knowledge of the Data Lifecycle Management process,
                  including data collection, access, use, storage, transfer, and
                  deletion.
                </li>
                <li>
                  Expert-level ability to write complex, optimized SQL queries
                  across extensive data volumes.
                </li>
                <li>
                  Experience with RDBMS and OLAP databases like MySQL, Redshift.
                </li>
                <li>Familiarity with Agile methodologies.</li>
                <li>
                  Obsession for service observability, instrumentation,
                  monitoring, and alerting.
                </li>
                <li>
                  Knowledge or experience in architectural best practices for
                  building data pipelines.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 md:mt-10">
            <div className="text-xl md:text-2xl font-gilroy text-white">
              Skills:
            </div>
            <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
              <ul className="list-disc pl-7">
                <li>Fraud</li>
                <li>Data Science and Machine Learning (ML)</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="px-4 md:px-20 mt-8 md:mt-10">
          {locationSalary.map((item, index) => (
            <div key={index} className="mt-6 md:mt-10">
              <div className="text-xl md:text-2xl font-gilroy text-white">
                {item.title}
              </div>
              <div className="text-base font-gilroy text-[#9A9CA0] mt-2">
                {item.desc}
              </div>
            </div>
          ))}
        </div>

        <button className="btnColor rounded-md flex items-center px-6 py-2 md:mx-5 sm: justify-start gap-4 mt-6 ml-6 border-none text-white ">
          Apply Now
        </button>
      </div>
    </div>
  );
}
