import React, { useState } from "react";
import PopUp from "./PopUp";
import "./FraudDetection.css";
import NumberList from "Components/NumberList";
import IconCards from "Components/solutions/IconCards";

export default function FraudDetection() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 pt-6  md:pt-28 lg:pl-8 px-4 md:px-0" style={{ background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f2930)' }}>

        <div className="flex flex-col gap-2 items-start justify-center  lg:pl-32" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0">RaptorX AI: Elevate Your Fraud Game</div>
          <div id="adjust-text" className="my-0 text-[30px] lg:text-[36px]">
          Precision, Efficiency, Revenue Growth
          </div>
          <div className="fraud-handling-info text-[16px]">
          Experience the power of RaptorX AI, where fraud accuracy soars, false positives vanish, time is saved, and revenue surges. Say goodbye to uncertainties and inefficiencies, and hello to a more profitable future.
          </div>
          <a href="#formData">
          <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-6 border-none text-white ">
          See How It Works
          </button>
          </a>
          
        </div>
        <div className=" my-10 flex justify-center ">
          <img src="../images/fraudDetect/headImg.svg" className="lg:w-8/12"></img>
        </div>
      </div>
      <div className="content-container">
        <img
          className="background-image-3 order-first "
          style={{ top: "210rem" }}
          src="../images/background-image-3.svg"
          alt="Background Image"
        ></img>
        <NumberList
          icon="../images/fraudDetect/listSide.svg"
          title="FRAUD DETECTION AT RaptorX.ai"
          heading="How Fraud Detection Works"
          desc="At RaptorX.ai, our comprehensive approach to fraud detection combines Unsupervised Machine Learning (UML), Supervised Machine Learning (SML), Linguistic Machine Learning (LLM), and advanced Behavioral Biometrics, all fortified with Explainability AI."
          list={[
            {heading: "Interaction Initiated", desc: "User initiates an interaction with your business."},
            {heading: "Data Collection", desc: "Swift gathering of user data, including personal info, device ID, and geolocation."},
            {heading: "Creating Distinct Identity Profiles", desc: "Assembling holistic user profiles for enhanced identification."},
            {heading: "Real-time Analysis", desc: "Meticulous scrutiny of data using UML, SML, and LLM for risk assessment."},
            {heading: "Policy Evaluation", desc: "Assessing interaction conformity with established business policies."},
            {heading: "Decision and Action", desc: "Automated response within milliseconds (accept, block, or challenge) based on evaluation."},
          ]}
        />
      </div>
      <IconCards
        title="USE CASES FOR DIGITAL FRAUD PREVENTION"
        heading="Complete Shielding"
        desc="Empowering Your Success. In the ever-shifting landscape of challenges, Panoplia offers not just protection but also an engine for growth. With our cutting-edge fraud detection technology, your business is poised to conquer any obstacle and thrive."
        list={[
          {heading: "Account Takeover (ATO) Fraud", desc: "RaptorX’s employs advanced machine learning and behavioral biometrics to detect unusual user behavior and swiftly block unauthorized access, safeguarding against fraudulent transactions and fund theft.", icon: "../icons/fraudDetect/set1img1.svg", link: "Learn about card testing fraud prevention"},
          {heading: "Credit Card Fraud", desc: "With RaptorX’s real-time analysis and fraud detection algorithms, businesses can confidently process payments, recognizing stolen or fake credit card information to prevent financial losses.", icon: "../icons/fraudDetect/set1img2.svg", link: "Learn about card testing fraud prevention"},
          {heading: "Identity Theft", desc: "RaptorX’s identity verification solutions and behavioral biometrics ensure the protection of personal information, thwarting fraudsters' attempts to open fraudulent accounts and commit financial crimes.", icon: "../icons/fraudDetect/set1img3.svg", link: "Learn about card testing fraud prevention"},
          {heading: "Chargeback Fraud", desc: "RaptorX provides real-time transaction monitoring and dispute resolution capabilities, reducing the risk of chargeback fraud and protecting merchants from unwarranted financial losses.", icon: "../icons/fraudDetect/set1img4.svg", link: "Learn about card testing fraud prevention"},
          {heading: "Return Fraud", desc: "By analyzing return patterns and customer behavior, RaptorX helps retailers identify and prevent abusive return practices, minimizing financial losses.", icon: "../icons/fraudDetect/set1img5.svg", link: "Learn about card testing fraud prevention"},
          {heading: "UPI (Unified Payments Interface) Frauds", desc: "RaptorX prevents user-assisted frauds like UPI fraud by continuously monitoring transaction behavior, promptly identifying suspicious patterns, and leveraging advanced behavioral biometrics to thwart fraudulent activities, ensuring secure financial operations.", icon: "../icons/fraudDetect/set1img6.svg", link: "Learn about card testing fraud prevention"},
        ]}
      />
      <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="checklistColumns headerTextAlign-center">
                <p id="offers">Why RaptorX's Fraud Detection Matters</p>
                <h2 className="text-center text-white font-semibold leading-6 text-xl md:text-3xl font-Gilroy-SemiBold">
                  {" "}
                  Benefits you can’t afford to miss
                </h2>
                <div className="header-description text-center">
                  <p className="text-[#FFFFFFB2]">
                    Unparalleled Advantages You Must Embrace With RaptorX.ai, your
                    business gains a robust defense against multifaceted fraud
                    threats. Advanced machine learning, behavioral biometrics,
                    and real-time monitoring empower IT professionals with
                    enhanced detection capabilities, reduce false positives for
                    financial executives, and provide business owners with the
                    confidence to proactively pursue growth while safeguarding
                    customer trust and reputation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row multicol justify-content-center flex-column columnStyle-checklistColumns ">
              <div
                  className="align-items-center justify-content-between grid lg:grid-cols-2 grid-cols-1"
                  style={{ width: "100%" }}
                >
                  <div className="d-flex flex-column h-100 checklist mt-16 lg:ml-20 lg:order-first ml-4">
                    <h3 className="header-text" id="adjust-text">
                      WITHOUT <span className="bg-gradient-to-r from-[#16EE9D] to-[#08A0F3] inline-block text-transparent bg-clip-text font-extrabold" >RaptorX's&nbsp; {" "}</span>Fraud<br></br>
                      Protection in Fintech
                    </h3>
                    <div className="formattedTextBlock header-description mt-4">
                      <ul>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          <span>Limited Point-in-Time Understanding</span>
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          High Incidence of False Positives
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Vulnerability to Identity Theft
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Customer Experience Lacks Context
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Overwhelmed Admins Dealing with Vast Data
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Utilizing Basic ML Models and Black Box ML Models
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex justify-center lg:order-last order-first">
                  <img src="../images/with-panoplia-image.svg" className="md:w-12/12 w-9/12"/>
                  </div>
                </div>
                <div
                  className="align-items-center justify-content-between grid lg:grid-cols-2 grid-cols-1 "
                  style={{ width: "100%" }}
                >
                  <div className="flex justify-center">

                  <img src="../images/tempImg.svg" className="w-12/12"/>
                  </div>
                  <div className="d-flex flex-column h-100 checklist mt-16 lg:ml-20 ml-4">
                    <h3 className="header-text" id="adjust-text">
                      WITH <span className="bg-gradient-to-r from-[#16EE9D] to-[#08A0F3] inline-block text-transparent bg-clip-text font-extrabold" >RaptorX's&nbsp; {" "}</span>Fraud<br></br>
                      Protection in Fintech
                    </h3>
                    <div className="formattedTextBlock header-description mt-4">
                      <ul>
                        <li className="liSolution flex items-center gap-3  text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Continuous Monitoring and Contextual Insights
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Reduced False Positives
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Enhanced Identity Verification
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Improved Customer Experience
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Efficient Automation
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Admins Empowered with Actionable Insights
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <IconCards
        title="what makes RaptorX special"
        heading="Added value achieves better results"
        desc="Why choose RaptorX for fraud detection and prevention? Here’s what sets us apart."
        list={[
          {heading: "Complete Strategy", desc: "No matter the types of fraud — from common concerns like payment card fraud to complex issues like account takeover — RaptorX has you covered with an end-to-end strategy. And it's all available in an easy-to-access platform.", icon: "../icons/fraudDetect/set2img1.svg"},
          {heading: "Historical Data", desc: "RaptorX was one of the first fraud management companies to enter the market. That means we have 15 years worth of data — a decade more than other service providers. A longer history means RaptorX has the best understanding of risk.", icon: "../icons/fraudDetect/set2img2.svg"},
          {heading: "Better Result", desc: "RaptorX gives you the most accurate risk assessments possible with better fraud prevention and fewer false positives. Plus, you get full access to decision data analytics, so you have complete transparency on what is happening and why.", icon: "../icons/fraudDetect/set2img3.svg"},
          {heading: "Machine Learning", desc: "Some fraud tools claim to incorporate supervised machine learning — the most basic capability — into their platform. But RaptorX utilizes both supervised and unsupervised learning. By combining multiple machine learning models, RaptorX has greater insights which lead to the most accurate decisions.", icon: "../icons/fraudDetect/set2img4.svg"},
          {heading: "Flexible Automation", desc: "RaptorX is a best-in-class technology platform that can reduce time-consuming, labor-intensive, error-prone processes. But automation doesn’t blindly dictate how your business should be run. You have full control over how decisions are made and the level of risk you are willing to accept.", icon: "../icons/fraudDetect/set2img5.svg"},
          {heading: "Unmatched Expertise", desc: "The RaptorX team is made up of professionals with dozens of years’ experience. We know what it takes to stop criminals from committing fraud. And we will readily share those insights with you. Our goal is to help clients continuously improve results by fine-tuning their strategy as threats evolve over time.", icon: "../icons/fraudDetect/set2img6.svg"},
        ]}
      /> */}
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}

// fill="#16ee9d"