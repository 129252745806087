import React from 'react'

const IconCards = ({ title, heading, desc, list }) => {
    return (
        <div className="content-container mx-0 py-12" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="above headerTextAlign-center">
                            <p className="text-center font-medium text-base text-[#35adf4] tracking-[4px] uppercase">{title}</p>
                            <h2 className="text-white font-medium text-3xl leading-tight text-center mb-3">{heading}</h2>
                            <div className=" text-center mb-10">
                                <p className='text-[#FFFFFFB2]'>
                                    {desc}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-content-center textAlignLeft grid grid-cols-1 lg:grid-cols-3 gap-4">
                    {list.map((item) => {
                        return <div className="h-full py-2">
                            <div className="d-flex flex-column mx-2 md:mx-0 h-full">

                                <div className="mr-0">
                                    <img
                                        className="w-14"
                                        alt="Icon of a shield and card"
                                        src={item.icon}
                                    />
                                </div>
                                <h3 className="header-text mt-4 text-white text-[18px]">{item.heading}</h3>

                                <div className='flex flex-col justify-between flex-1'>
                                    <div className="header-description">
                                        <p className='text-[#FFFFFFB2] text-[14px]'>
                                            {item.desc}
                                        </p>
                                    </div>
                                    {item.link && <div className="row align-items-end">
                                        <a
                                            href=''
                                            className="text-[16px] flex text-[#1C93D5] font-semibold items-center"
                                            style={{ textDecoration: "none" }}
                                        >
                                            {item.link}
                                            <img src="../icons/arrow-icon.svg"></img>
                                        </a>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default IconCards