import React, { useState } from "react";
import PopUp from "./PopUp";
export default function Solutions() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  return (
    <>
      
      <div className="flex-col flex justify-center bg-background-dark items-center md:pt-16" style={{ marginTop: "0rem" }}>
          <div className="grid grid-cols-1 pb-8 lg:grid-cols-2 pt-6 md:pt-10  bg-[#11171F]" style={{ background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>

            <div className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6" style={{ maxWidth: '100%' }}>
              <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">FEATURES</div>
              <div className='text-4xl lg:text-5xl text-white font-semibold'>
              Unique features. Complete protection.

              </div>
              <div className="fraud-handling-info">
              RaptorX offers a complete set of cutting-edge features, creating unparalleled protection. Whether you need account protection, data insights, chargeback management, or help with compliance regulations, RaptorX has you covered. There are no limits to what we can help you accomplish.
              </div>
              <a href="#formData">
              <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-0 border-none text-white">
                Request a Demo
              </button>
              </a>
              
            </div>
            <div className="flex justify-center">
              <img src="../images/Isolation_Mode.svg" className="w-6/12"></img>
            </div>
          </div>
        </div>

      <div className="flex flex-col justify-center items-center bg-dark-background-background-dark mt-20">
        <div className="container d-flex flex-column gap-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="fullWidthImageAbove headerTextAlign-center">
                <p id="offers">PAYMENTS FRAUD</p>
                <h2 className="header-text text-center text-white">
                  Complete confidence for payments
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center text-[#b1b1b2]">
                  Accepting customer payments is crucial, but it can be risky without the right safeguards.<br className="hidden md:flex"></br> Discover how RaptorX boosts your revenue while minimizing risks for your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center bg-dark-background-background-dark mt-4 md:mt-14">
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem] md:order-first">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              Fraud detection and prevention
            </div>
            <div className="fraud-handling-info">
              Want to maintain positive revenue growth? And worry less about
              events that can harm your business? RaptorX can help. Our fraud
              prevention and detection software can stop threats and improve
              business operations so you can focus on increasing revenue.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about fraud detection and prevention
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="flex justify-center items-center order-first">
            <img src="../images/sol_payment_1.svg" className="w-[85%]"></img>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center bg-dark-background-background-dark mt-14">
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="flex justify-center items-center" >
            <img src="../images/sol_payment_2.svg" className="w-[85%]"></img>
          </div>
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem]">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              Chargeback management
            </div>
            <div className="fraud-handling-info">
              Chargebacks have damaged your bottom line for long enough. It’s
              time to protect the revenue that’s rightfully yours — and RaptorX
              can help. We offer a complete solution for chargeback management.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about chargeback management
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center bg-dark-background-background-dark mt-4 md:mt-14">
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem] md:order-first">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              Authorization optimization
            </div>
            <div className="fraud-handling-info">
              When customers win, we all win. Reduce the number of false
              positives — declined legitimate orders — and watch your conversion
              rates soar.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about authorization optimization
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="flex justify-center items-center order-first">
            <img src="../images/sol_payment_3.svg" className="w-[85%]"></img>
          </div>
        </div>
      </div>

{/* identity  component starts*/}
<div className=" my-20 pb-16 pt-10" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>
<div className="flex flex-col justify-center items-center mt-14">
        <div className="container d-flex flex-column gap-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="fullWidthImageAbove headerTextAlign-center">
                <p id="offers">IDENTITY</p>
                <h2 className="header-text text-center text-white">
                  Complete confidence for digital identity
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center text-[#b1b1b2]">
                    Understand who is interacting with your brand so you can
                    build a<br className="hidden md:flex"></br> better, safer experience to grow revenue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center" >
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem] md:order-first">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              Consumer Insights
            </div>
            <div className="fraud-handling-info">
              Wondering how to get the right customers in the door? But still
              manage the risks? Look no further. RaptorX gives you actionable
              insights into consumer behavior — risk levels, purchasing
              decisions, propensity to spend — so you can increase your bottom
              line while protecting your business.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about consumer insights
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="flex justify-center items-center order-first">
            <img src="../images/sol_identity_1.svg" className="w-[85%]"></img>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-14" >
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="flex justify-center items-center">
            <img src="../images/sol_identity_2.svg" className="w-[85%]"></img>
          </div>
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem]">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              New account fraud prevention
            </div>
            <div className="fraud-handling-info">
              New accounts can lead to valuable customer relationships — as long
              as they are created by legitimate customers. Fraudsters and
              opportunistic customers often open fake accounts that expose your
              business to unnecessary risks and fraud schemes. That’s why we
              make sure you let the good customers in and keep the bad ones
              away.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about new account fraud prevention
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-4 md:mt-14" >
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem] md:order-first">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              Account takeover prevention
            </div>
            <div className="fraud-handling-info">
              Account protection technology detects suspicious activity and
              stops costly account takeover (ATO) fraud. Plus, our technology
              can give your customers a better, seamless experience across your
              platforms.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about account takeover prevention
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="flex justify-center items-center order-first">
            <img src="../images/sol_identity_3.svg" className="w-[85%]"></img>
          </div>
        </div>
      </div>
</div>
{/* identity  component ends*/}
      
      <div className="flex flex-col justify-center items-center bg-dark-background-background-dark mt-20 b">
        <div className="container d-flex flex-column gap-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="fullWidthImageAbove headerTextAlign-center">
                <p id="offers">COMPLIANCE</p>
                <h2 className="header-text text-center text-white">
                  Comply with complete confidence
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center text-[#b1b1b2]">
                    Laws and regulations are designed to protect your business —
                    but compliance can be a challenge. Fortunately, RaptorX helps
                    simplify the complex. Our compliance software makes it easy
                    to adhere to rules without sacrificing business growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center bg-dark-background-background-dark mt-14">
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem] md:order-first">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              Customer Due Diligence
            </div>
            <div className="fraud-handling-info">
              Looking for a way to improve the efficiency of your customer due
              diligence workflow? Let RaptorX help. Take advantage of our
              industry-leading technology and decades of experience to achieve
              the best results possible with the least amount of effort.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about customer due diligence
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="flex justify-center items-center order-first">
            <img src="../images/sol_compliance_1.svg" className="w-[85%]"></img>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center bg-dark-background-background-dark mt-14">
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="flex justify-center items-center">
            <img src="../images/sol_compliance_2.svg" className="w-[85%]"></img>
          </div>
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem]">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              Global watchlist search
            </div>
            <div className="fraud-handling-info">
              Wondering how to detect and prevent financial crimes? Unsure about
              how to conduct necessary checks against sanctions lists? Concerned
              about the technical know-how needed to comply with law enforcement
              expectations? Don’t worry. RaptorX offers a complete solution for
              global watchlist screening with flexible onboarding options.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about global watchlist search
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center bg-dark-background-background-dark mt-4 md:mt-20  mb-24">
        <div className="flex justify-between items-center w-full max-w-[76rem] flex-wrap mt-0 md:mt-10">
          <div className="inline-flex flex-col items-start gap-3 md:gap-6 m-8 max-w-[31rem] md:order-first">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column text-xl"
            >
              Regulatory reporting
            </div>
            <div className="fraud-handling-info">
              Don’t let financial crimes jeopardize your business’s growth.
              Quickly detect threats and accurately protect your business with
              dependable, ongoing portfolio monitoring.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about regulatory reporting
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="flex justify-center items-center order-first">
            <img src="../images/sol_compliance_3.svg" className="w-[85%]"></img>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
