import React from 'react'

const NumberList = ({ title, heading, desc, list, icon }) => {
    return (
        <div className="transaction-image-wrapper grid grid-cols-1 lg:grid-cols-2 md:order-first" style={{ display: 'grid', maxWidth: '100%' }}>
            
            <div className="wallet-info text-left">
                <div id="offers" className='text-left'>{title}</div>
                <div  className='text-left text-white  font-gilroy-semibold text-3xl font-semibold leading-normal max-w-[34rem]'>
                    {heading}
                </div>
                <div className="fraud-handling-info text-left">
                    {desc}
                </div>
                {list.map((item, index) => {
                    return (
                        <div>
                            <div className="transaction-title">
                                <img src={`../icons/${index+1}-icon.svg`} alt="Icon 1"></img>
                                <h1>{item.heading}</h1>
                            </div>
                            <div className="fraud-handling-info mt-3">
                                {item.desc}
                            </div>
                        </div>
                    )
                })}

            </div>
            <div className="flex justify-center items-center md:order-first px-2">
                <img
                    src={icon}
                    alt="Fraud Detection Software"
                ></img>
            </div>
        </div>
    )
}

export default NumberList