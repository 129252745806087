import React, { useState } from "react";
import PopUp from "./PopUp";
import "./FraudDetection.css";
import NumberList from "Components/NumberList";
import reg1 from "../src/img/Reg1.png"
import reg2 from "../src/img/Reg2.png"

import reg3 from "../src/img/Reg3.png"

import icon1 from "../src/img/icon1.png"
import icon2 from "../src/img/icon2.png"
import icon3 from "../src/img/icon3.png"
import icon4 from "../src/img/icon4.png"

export default function RegulatoryReporting() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:pt-[5rem] px-4 md:px-0" style={{ background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f2930)' }}>

        <div className="flex flex-col gap-2 items-start justify-center my-10 px-[5px] md:px-4 lg:pl-32" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0">ONGOING ANALYSIS</div>
          <div id="adjust-text" className="my-0 text-[30px] lg:text-[36px]">
            Regulatory reporting for portfolio monitoring
          </div>
          <div className="fraud-handling-info text-[16px]">
            Don’t let financial crimes jeopardize your business’s growth. Quickly detect threats and accurately protect your business with dependable, ongoing portfolio monitoring.          </div>
          <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-6 border-none text-white ">
            See How It Works
          </button>
        </div>
        <div className=" my-10 flex justify-center ">
          {/* <img src="../images/fraudDetect/headImg.svg" className="lg:w-8/12"></img> */}
        </div>
      </div>
      <div className="content-container ">
        <img
          className="background-image-3"
          style={{ top: "210rem" }}
          src="../images/background-image-3.svg"
          alt="Background Image"
        ></img>
        <NumberList
          icon={reg1}
          title="PORTFOLIO MONITORING AT PANOPLIA"
          heading="How regulatory reporting works"
          desc="How does RaptorX.ai use regulatory reporting to monitor the evolving risk of your portfolio?"
          list={[
            { heading: "Portfolio is built.", desc: "An individual engages with your business, such as attempting to open an account, login, during session or make a purchase, with Zero Trust in mind." },
            { heading: "Inquiries are sent.", desc: "Inquiries are sent.Swift gathering of user data, including personal info, device ID, and geolocation." },
            { heading: "Risk is assessed.", desc: "Our machine learning algorithms return an assessment score along with reason codes for identity, phone, email, address, and device, maintaining Zero Trust User Profiling." },
            { heading: "Monitoring continues.", desc: "The individual's identity is conclusively verified as either legitimate or not, in alignment with Zero Trust practices." },
            { heading: "Business growth is secure.", desc: "Our risk-based approach ensures ongoing compliance for know your customer (KYC), enhanced due diligence (EDD), and more. Constant monitoring means your business grows confidently and securely." },

          ]}
        />
      </div>
      <div className="content-container " style={{background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)', paddingTop: "2rem"}}>>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="above headerTextAlign-center">
                <p id="offers">WHAT MAKES RAPTORX SPECIAL</p>
                <h2 className="text-center text-white font-semibold leading-6 text-xl md:text-3xl font-Gilroy-SemiBold">
                  {" "}
                  Added value achieves better results
                </h2>
                <div className="formattedTextBlock header-description text-[#FFFFFFB2] text-center mt-4">
                  <p className="mb-6">
                    Why choose Panopila for fraud detection and prevention?
                    Here’s what sets us apart.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row multicol justify-content-center columnStyle-fullWidthImageAbove  lg:p-6 p-6">
            <div className="col-lg col-md-6 col-sm-12   ">
              <div className="d-flex flex-column h-100  ">
                <div className="flex items-center justify-center mb-1.25rem  bg-[#35ADF4] rounded-full w-12 h-12" style={{marginBottom:"0.8rem"}}>
                  <img
                    src={icon1}
                    alt="refunding service header image"
                    className=" w-7 h-7 "
                  />
                </div>

                <h3 className="header-text text-[White]">Ongoing monitoring</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                    Enable users to log into accounts without a password — which can easily be stolen. Verify identities with more secure features like facial recognition, biometrics, or trusted devices.
                  </p>
                </div>

              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
              <div className="flex items-center justify-center mb-1.25rem bg-[#35ADF4] rounded-full w-12 h-12" style={{marginBottom:"0.8rem"}}>
                  <img
                    src={icon2}
                    alt="refunding service header image"
                    className=" w-7 h-7 "
                  />
                </div>
                <h3 className="header-text text-[White]">Better data</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                    Leverage our expansive network to reduce friction. Once an identity has been verified through our technology, the individual can seamlessly interact with any Panopila client.
                  </p>
                </div>
              </div>
            </div>

          </div>
          {/* 3 lines end 6 starts */}
          <div className="row multicol justify-content-center columnStyle-fullWidthImageAbove mt-4  lg:p-6 p-6" style={{paddingTop:0}}>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
              <div className="flex items-center justify-center mb-1.25rem bg-[#35ADF4] rounded-full w-12 h-12" style={{marginBottom:"0.8rem"}}>
                  <img
                    src={icon3}
                    alt="refunding service header image"
                    className=" w-7 h-7 "
                  />
                </div>
                <h3 className="header-text text-white">Complete strategy</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                    Auto-populate new account creation forms with information stored in our database. Verify user identities in real-time with a frictionless experience and minimized risk of fraud.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
              <div className="flex items-center justify-center mb-1.25rem bg-[#35ADF4] rounded-full w-12 h-12" style={{marginBottom:"0.8rem"}}>
                  <img
                    src={icon4}
                    alt="refunding service header image"
                    className=" w-7 h-7 "
                  />
                </div>
                <h3 className="header-text text-white">Less friction</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-[#FFFFFFB2]">
                    Quickly and accurately verify the authenticity of identity documents. Easily detect fake IDs, protect your business, and comply with know-your-customer regulations.                  </p>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    
      <div className="content-container " >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="checklistColumns headerTextAlign-center">
                <p id="offers">WHY REGULATORY REPORTING MATTERS</p>
                <h2 className="text-center text-white font-semibold leading-6 text-xl md:text-3xl font-Gilroy-SemiBold">
                  {" "}
                  Benefits you can’t afford to miss
                </h2>
                <div className="header-description text-center text-[#FFFFFFB2]">
                  <p>
                  Consulting regulatory reporting and sanctions lists is important, but why do you need ongoing portfolio monitoring? Can’t you just periodically analyze your database whenever it seems relevant? Here is what happens if you make continuous monitoring a priority — and what happens if you don’t.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 lg:p-6 p-6 ">
              <div className="row multicol justify-content-center flex-column columnStyle-checklistColumns gap-14" style={{marginBottom:"5rem"}}>
              <div
                  className="col-lg col-md-6 col-sm-12  grid grid-cols-1 md:grid-cols-2 align-items-center justify-between"
                  style={{ width: "100%",marginTop:"2.5rem",}}
                >
               
                  <div className="d-flex flex-column h-100 checklist mt-16">
                  <h3 className="header-text" id="adjust-text">
                      WITHOUT <span id="panoplia-text" style={{fontSize:"27px"}}>PORTFOLIO {" "}</span> <br></br>
                      MONITORING
                    </h3>
                    <div className="formattedTextBlock header-description mt-4 grid md:grid-cols-2 grid-cols-1 md:gap-3 gap-0">
                      <ul>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          <span>Labor-intensive and redundant</span>
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Undetected risk
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Gaps in coverage
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Unexpected penalties and fines
                        </li>
                        <li className="flex items-center liWarning gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/cross-icon.svg" />
                          Shortened business longevity
                        </li>
                      </ul>
                     
                    </div>
                  </div>
                  <img src={reg2} />
                </div>
                <div
                  className="col-lg col-md-6 col-sm-12 grid grid-cols-1 md:grid-cols-2  align-items-center justify-content-between"
                  style={{ width: "100%" }}
                >
                  <img src={reg3} className="order-last "/>
                  
                  <div className="d-flex flex-column h-100 checklist mt-16 md:order-last">
                  <h3 className="header-text" id="adjust-text">
                      WITHOUT <span id="panoplia-text" style={{fontSize:"27px"}}>PORTFOLIO {" "}</span> <br></br>
                      MONITORING
                    </h3>
                    <div className="formattedTextBlock header-description mt-4 grid md:grid-cols-2 grid-cols-1 md:gap-3 gap-0">
                      <ul>
                        <li className="liSolution flex items-center gap-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Efficient and accurate
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Complete protection
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3  text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Ongoing compliance
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Positive cash flow
                        </li>
                        <li className="liSolution flex items-center gap-3 mt-3 text-[#FFFFFFB2]">
                          <img src="../icons/tick-icon.svg" />
                          Sustainable business growth
                        </li>
                        
                      </ul>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}

// fill="#16ee9d"