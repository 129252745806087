import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import ListComp from "Components/header/ListComp";
const Header = () => {
  const [showWhyPanopilaContainer, setShowWhyPanopilaContainer] =
    useState(false);
  const [showFeaturesContainer, setShowFeaturesContainer] = useState(false);
  const [showIndustriesContainer, setShowIndustriesContainer] = useState(false);
  const [showResourcesContainer, setShowResourcesContainer] = useState(false);
  const [showPricingContainer, setShowPricingContainer] = useState(false);
  const [showMenuContainer, setShowMenuContainer] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [animation, setAnimation] = useState(false);
  const handleToggle = () => {
    setAnimation(!animation);
    setTimeout(() => {
      setShowMenuContainer(!showMenuContainer);
    }, 200);
  };

  const mobileClickHandler = () => {
    setMobileView(!mobileView);
    setShowFeaturesContainer(false);
    setShowIndustriesContainer(false);
    setShowWhyPanopilaContainer(false);
    setShowResourcesContainer(false);
  };

  return (
    <>
      <div className="header-container hidden lg:block">
        <div className="d-flex justify-content-center">
          <div
            className="d-flex justify-content-between align-items-center"
            id="header"
          >
            <Link to="/" className="text-decoration-none" id="company-logo">
              <img src="/icons/mainLogo.svg" alt="Company Logo" />
            </Link>
            <div id="menu-items">
              <Link
                className={`menu-item ${
                  showWhyPanopilaContainer ? "blue-text" : ""
                }`}
                id="item-1"
                onMouseEnter={() => setShowWhyPanopilaContainer(true)}
                onMouseLeave={() => setShowWhyPanopilaContainer(false)}
                to="/why-raptorx"
              >
                Why RaptorX.ai
              </Link>
              <Link
                className={`menu-item ${
                  showFeaturesContainer ? "blue-text" : ""
                }`}
                id="item-2"
                onMouseEnter={() => setShowFeaturesContainer(true)}
                onMouseLeave={() => setShowFeaturesContainer(false)}
                to="/solutions"
              >
                Solutions
              </Link>
              {/* <Link
                className={`menu-item ${showIndustriesContainer ? "blue-text" : ""
                  }`}
                id="item-3"
                onMouseEnter={() => setShowIndustriesContainer(true)}
                onMouseLeave={() => setShowIndustriesContainer(false)}
                to="/industries"
              >
                Industries
              </Link> */}
              <div
                className={`menu-item ${
                  showIndustriesContainer ? "blue-text" : ""
                }`}
                id="item-3"
                onMouseEnter={() => setShowIndustriesContainer(true)}
                onMouseLeave={() => setShowIndustriesContainer(false)}
              >
                Industries
              </div>
              <div
                className={`menu-item ${
                  showResourcesContainer ? "blue-text" : ""
                }`}
                id="item-4"
                onMouseEnter={() => setShowResourcesContainer(true)}
                onMouseLeave={() => setShowResourcesContainer(false)}
              >
                Resources
              </div>

              <div
                className={`menu-item ${
                  showPricingContainer ? "blue-text" : ""
                }`}
                id="item-5"
                onMouseEnter={() => setShowPricingContainer(true)}
                onMouseLeave={() => setShowPricingContainer(false)}
              >
                {/* <Link to='/pricing' className={`${showPricingContainer ? "blue-text" : "text-white"}`}>
                  Pricing
                </Link> */}
              </div>
            </div>
            <button
              className="d-flex justify-content-center align-items-center"
              id="sign-up-button"
            >
              <div>Sign Up</div>
            </button>
            <div className="menu-icon" onClick={() => handleToggle()}>
              <img src="../icons/menu-icon.svg" alt="Menu Icon" />
            </div>
          </div>
        </div>
        {showWhyPanopilaContainer && (
          <div
            className="sub-menu d-flex"
            onMouseEnter={() => setShowWhyPanopilaContainer(true)}
            onMouseLeave={() => setShowWhyPanopilaContainer(false)}
            id="show-why-panopila-container"
          >
            <div
              className="d-flex flex-column align-items-start gap-2"
              id="container-1"
            >
              <img src="../icons/verified-icon.svg" alt="Verified Icon" />
              <h1 className="hearder-item-text">Why Choose RaptorX.ai</h1>
              <p className="header-description-text">
                Discover how we make sure things are safe and trustworthy in a
                special way
              </p>
              <div className="navigation-wrapper">
                <div>What sets us apart</div>
                <img src="../icons/arrow-icon.svg"></img>
              </div>
            </div>
            <div id="container-2">
              <div id="container-3">
                <Link
                  className="container-4"
                  to="/why-panopila/comprehensive-protection"
                >
                  <div className="container-5">
                    <img src="../icons/complete-strategy-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Comprehensive Protection</h1>
                    <p>
                      Stay safe from all dangers with our all-in-one platform.
                    </p>
                  </div>
                </Link>
                <Link
                  className="container-4"
                  to="/why-panopila/machine-learning"
                >
                  <div className="container-5">
                    <img src="../icons/machine-learning-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Unsupervised ML & LLM</h1>
                    <p>Smart decisions powered by advanced AI for precision.</p>
                  </div>
                </Link>
              </div>
              <div id="container-3">
                {/* <div className="container-4">
                        <div className="container-5">
                            <img src="../icons/robust-data-icon.svg"></img>
                        </div>
                        <div className="container-6">
                            <h1>
                                Robust Data
                            </h1>
                            <p>Years of data from diverse industries worldwide.</p>
                        </div>
                </div> */}
                <Link
                  className="container-4"
                  to="/why-panopila/explainability-ai"
                >
                  <div className="container-5">
                    <img src="../icons/business-policies-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Explainability AI</h1>
                    <p>Make informed decisions faster</p>
                  </div>
                </Link>
                <Link
                  className="container-4"
                  to="/why-panopila/graphical-dashboard"
                >
                  <div className="container-5">
                    <img src="../icons/user-dashboard-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Graphical Dashboard</h1>
                    <p>User-friendly platform, customizable reports.</p>
                  </div>
                </Link>
              </div>
              {/* <div id="container-3">
            <div className="container-4">
                <div className="container-5">
                    <img src="../icons/user-dashboard-icon.svg"></img>
                </div>
                <div className="container-6">
                    <h1>
                        Graphical Dashboard
                    </h1>
                    <p>
                        User-friendly platform, customizable reports.
                    </p>
                </div>
            </div>
            <div className="container-4">
                    <div className="container-5">
                        <img src="../icons/automation-icon.svg"></img>
                    </div>
                    <div className="container-6">
                        <h1>
                            Automation
                        </h1>
                        <p>
                            Cutting down on time-wasting and mistake-prone tasks
                        </p>
                    </div>
                </div>
            </div> */}
            </div>
          </div>
        )}
        {showFeaturesContainer && (
          <div
            className="sub-menu d-flex"
            onMouseEnter={() => setShowFeaturesContainer(true)}
            onMouseLeave={() => setShowFeaturesContainer(false)}
          >
            <div
              className="d-flex flex-column align-items-start gap-2"
              id="container-1"
            >
              <img src="../icons/solutionMainIcon.svg" alt="Verified Icon" />
              <h1 className="hearder-item-text">Fraud Prevention</h1>
              <p className="header-description-text">
                Find out how our industry-leading ecommerce fraud prevention can
                benefit your business.
              </p>
              <div className="navigation-wrapper">
                <div>Explore RaptorX.ai fraud prevention</div>
                <img src="../icons/arrow-icon.svg"></img>
              </div>
            </div>
            <div id="container-2">
              <div id="container-3">
                <h1>PAYMENTS FRAUD</h1>
                <Link className="container-4" to="/solutions/fraud-detection">
                  <div className="container-5">
                    <img src="../icons/froudDetctIcon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Fraud detection</h1>
                    <p>Stop threats and improve business operations.</p>
                  </div>
                </Link>
              </div>
              <div id="container-3">
                <h1>IDENTITY</h1>
                <Link
                  className="container-4"
                  to="/solutions/identity-verification"
                >
                  <div className="container-5">
                    <img src="../icons/identityVerifIcon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Identity verification</h1>
                    <p>
                      Provide real-time authentication without disrupting
                      service
                    </p>
                  </div>
                </Link>
              </div>
              {/* <div id="container-3">
                <h1>COMPLIANCE</h1>
                <Link className="container-4" to="/solutions/regulatory-reporting">
                  <div className="container-5">
                    <img src="../icons/regulatory-reporting-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Regulatory reporting</h1>
                    <p>Collect, monitor, and track data across a portfolio</p>
                  </div>
                </Link>

              </div> */}
            </div>
          </div>
        )}
        {showIndustriesContainer && (
          <div
            className="sub-menu d-flex"
            onMouseEnter={() => setShowIndustriesContainer(true)}
            onMouseLeave={() => setShowIndustriesContainer(false)}
          >
            <div
              className="d-flex flex-column align-items-start gap-2"
              id="container-1"
            >
              <img src="../icons/industryMainIcon.svg" alt="Verified Icon" />
              <h1 className="hearder-item-text">
                Solutions for all industries
              </h1>
              <p className="header-description-text">
                Learn how RaptorX.ai solves the challenges specific to your
                industry.
              </p>
              <div className="navigation-wrapper">
                <div>Explore industry solutions</div>
                <img src="../icons/arrow-icon.svg"></img>
              </div>
            </div>
            <div id="container-2">
              <div id="container-3">
                {/* <Link className="container-4" to="/industries/fintech" >
                  <div className="container-5">
                    <img src="../icons/fintech-inner-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Fintech</h1>
                    <p>Increase revenue and customer trust</p>
                  </div>
                </Link> */}

                <Link className="container-4" to="/industries/banking">
                  <div className="container-5">
                    <img src="../icons/bankingHeader.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Banking</h1>
                    <p>Expand learning offerings and improve revenue</p>
                  </div>
                </Link>

                {/* <div className="container-4">
                    <div className="container-5">
                        <img src="../icons/online-learning-icon.svg"></img>
                    </div>
                    <div className="container-6">
                        <h1>
                            Online Learning
                        </h1>
                        <p>Expand learning offerings and improve revenue.</p>
                    </div>
                </div> */}
              </div>
              <div id="container-3">
                <Link className="container-4" to="/industries/ecommerce">
                  <div className="container-5">
                    <img src="../icons/new-ecommerce-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Ecommerce</h1>
                    <p>Protect your online store and increase revenue.</p>
                  </div>
                </Link>
                {/* <div className="container-4">
                    <div className="container-5">
                        <img src="../icons/health-and-beauty-icon.svg"></img>
                    </div>
                    <div className="container-6">
                        <h1>
                            Health and Beauty
                        </h1>
                        <p>
                            Grow brand awareness while reducing resale activity
                        </p>
                    </div>
                </div> */}
                {/* <a className="container-4" href="/industries/online-gaming">
                <div className="container-5">
                  <img src="../icons/online-gaming-icon.svg"></img>
                </div>
                <div className="container-6">
                  <h1>Online Gaming</h1>
                  <p>Provide secure gaming experiences</p>
                </div>
              </a> */}
                {/* <a className="container-4" href="/industries/streaming-services">
                <div className="container-5">
                  <img src="../icons/streaming-services-icon.svg"></img>
                </div>
                <div className="container-6">
                  <h1>Streaming Services</h1>
                  <p>Decrease account sharing and artificial streaming.</p>
                </div>
              </a> */}

                {/* <div className="container-4">
                    <div className="container-5">
                        <img src="../icons/direct-sales-icon.svg"></img>
                    </div>
                    <div className="container-6">
                        <h1>
                            Direct Sales
                        </h1>
                        <p>
                            Stop promotion abuse and affiliate account fraud
                        </p>
                    </div>
                </div> */}
              </div>
              <div id="container-3">
                {/* <div className="container-4">
                    <div className="container-5">
                        <img src="../icons/health-care-icon.svg"></img>
                    </div>
                    <div className="container-6">
                        <h1>
                            Healthcare
                        </h1>
                        <p>
                            Protect medical records and access to patient portals
                        </p>
                    </div>
                </div> */}
                {/* <div className="container-4">
                    <div className="container-5">
                        <img src="../icons/automotive-rentals-icon.svg"></img>
                    </div>
                    <div className="container-6">
                        <h1>
                            Automotive Rentals
                        </h1>
                        <p>
                            Deploy safeguards at every step of the rental process
                        </p>
                    </div>
                </div> */}
              </div>
            </div>
          </div>
        )}{" "}
        {showResourcesContainer && (
          <div
            className="sub-menu d-flex"
            onMouseEnter={() => setShowResourcesContainer(true)}
            onMouseLeave={() => setShowResourcesContainer(false)}
          >
            <div
              className="d-flex flex-column align-items-start gap-2"
              id="container-1"
            >
              <img src="../icons/resourceMainIcon.svg" alt="Verified Icon" />
              <h1 className="hearder-item-text">RaptorX.ai Resources</h1>
              <p className="header-description-text">
                Check out our educational resources and learn more about
                RaptorX.ai.
              </p>
              <div className="navigation-wrapper">
                <div>Browse resources</div>
                <img src="../icons/arrow-icon.svg"></img>
              </div>
            </div>
            <div id="container-2" style={{ marginLeft: "1rem", gap: "0.5rem" }}>
              {/* <div id="container-3">
                <h1>EDUCATION</h1>
                <Link className="container-4" to="/resources/blog" >
                  <div className="container-5">
                    <img src="../icons/blog-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Blog</h1>
                    <p>Educational articles and guides</p>
                  </div>
                </Link>
              </div> */}
              {/* <div id="container-3">
                <h1>USER RESOURCES</h1>
                <div className="container-4">
                  <div className="container-5">
                    <img src="../icons/support-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Support</h1>
                    <p>Technical Product Support</p>
                  </div>
                </div>
              </div> */}
              <Link id="container-3" to="/why-raptorx">
                <h1>About Us</h1>
                <div className="container-4">
                  <div className="container-5">
                    <img src="../icons/aboutUsIcon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>About RaptorX.ai</h1>
                    <p>Who we are and why we do what we do</p>
                  </div>
                </div>
                <Link className="container-4" to="/careers">
                  <div className="container-5">
                    <img src="../icons/careers-icon.svg"></img>
                  </div>
                  <div className="container-6">
                    <h1>Careers</h1>
                    <p>
                      We're actively on the lookout for passionate individuals.
                    </p>
                  </div>
                </Link>
              </Link>
              {/* <div id="container-3">
              <h1>PARTNERS</h1>
              <div className="container-4">
                <div className="container-5">
                  <img src="../icons/partner-program-icon.svg"></img>
                </div>
                <div className="container-6">
                  <h1>Partner program</h1>
                  <p>Opportunities to partner with Panopila</p>
                </div>
              </div>
               <div className="container-4">
                    <div className="container-5">
                        <img src="../icons/partner-market-icon.svg"></img>
                    </div>
                    <div className="container-6">
                        <h1>
                            Partner marketplace
                        </h1>
                        <p>
                            Explore our cutting-edge integrations
                        </p>
                    </div>
                </div>
                <div className="container-4">
                    <div className="container-5">
                        <img src="../icons/partner-portal-icon.svg"></img>
                    </div>
                    <div className="container-6">
                        <h1>
                            Partner portal login
                        </h1>
                        <p>
                            Access your Panopila dashboard
                        </p>
                    </div>
                </div>
            </div> */}
            </div>
          </div>
        )}
        {/* {showPricingContainer && (
        <div className="sub-menu" id="container-5">
          Pricing container
        </div>
      )} */}
        {showMenuContainer && (
          <div
            className={`sub-menu d-flex ${animation ? "show" : "hide"}`}
          ></div>
        )}
      </div>
      <div className="lg:hidden">
        <>
          {/* drawer init and show */}
          <div className="text-center flex justify-between p-3 bg-[#11171F]">
            <Link to="">
              <img src="/icons/mainLogo.svg" alt="Company Logo" />
            </Link>
            <button
              className="text-white  font-medium rounded-lg text-sm px-3 py-2.5 focus:outline-none "
              type="button"
              onClick={() => {
                mobileClickHandler();
              }}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          {/* drawer component */}
          {mobileView && (
            <div
              id="drawer-navigation"
              className="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto w-[70%] bg-[#181818]"
              tabIndex={-1}
              aria-labelledby="drawer-navigation-label"
            >
              <button
                type="button"
                data-drawer-hide="drawer-navigation"
                aria-controls="drawer-navigation"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  mobileClickHandler();
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close menu</span>
              </button>

              <div className="py-4 overflow-y-auto">
                <ul className="space-y-4 font-medium pl-0">
                  <li className="pl-4 py-3">
                    <button
                      className="d-flex justify-content-center align-items-center"
                      id="sign-up-button"
                      style={{ display: "block !important" }}
                      onClick={() => {
                        mobileClickHandler();
                      }}
                    >
                      <div>Sign Up</div>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full p-2 text-base transition duration-75 rounded-lg group text-white"
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                      onClick={() => {
                        setShowWhyPanopilaContainer(!showWhyPanopilaContainer);
                      }}
                    >
                      <span
                        className={`flex-1 ms-3 text-left rtl:text-right whitespace-nowrap ${
                          showWhyPanopilaContainer ? "text-[#35adf4]" : ""
                        }`}
                      >
                        Why RaptorX.ai
                      </span>
                      {showWhyPanopilaContainer ? (
                        <svg
                          className={`w-3 h-3 text-[#35adf4]`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5 5 1 1 5"
                          />
                        </svg>
                      ) : (
                        <svg
                          className={`w-3 h-3`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      )}
                    </button>
                    {showWhyPanopilaContainer && (
                      <ul
                        id="dropdown-example"
                        className="my-2 space-y-2 border-l-2 ml-[2rem] border-gray-700"
                        style={{ paddingLeft: 8 }}
                      >
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Home"
                          route="/why-raptorx"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Comprehensive Protection"
                          route="/why-panopila/comprehensive-protection"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Explainable AI"
                          route="/why-panopila/explainability-ai"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Unsupervised ML & LLM"
                          route="/why-panopila/machine-learning"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Graphical Dashboard"
                          route="/why-panopila/graphical-dashboard"
                        />
                      </ul>
                    )}
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full p-2 text-base transition duration-75 rounded-lg group text-white"
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                      onClick={() => {
                        setShowFeaturesContainer(!showFeaturesContainer);
                      }}
                    >
                      <span
                        className={`flex-1 ms-3 text-left rtl:text-right whitespace-nowrap ${
                          showFeaturesContainer ? "text-[#35adf4]" : ""
                        }`}
                      >
                        Solutions
                      </span>
                      {showFeaturesContainer ? (
                        <svg
                          className={`w-3 h-3 text-[#35adf4]`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5 5 1 1 5"
                          />
                        </svg>
                      ) : (
                        <svg
                          className={`w-3 h-3`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      )}
                    </button>
                    {showFeaturesContainer && (
                      <ul
                        id="dropdown-example"
                        className="my-2 space-y-2 border-l-2 ml-[2rem] border-gray-700"
                        style={{ paddingLeft: 8 }}
                      >
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Home"
                          route="/solutions"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Fraud detection"
                          route="/solutions/fraud-detection"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Identity verification"
                          route="/solutions/identity-verification"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Ragulatory reporting"
                          route="/solutions/regulatory-reporting"
                        />
                      </ul>
                    )}
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full p-2 text-base transition duration-75 rounded-lg group text-white"
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                      onClick={() => {
                        setShowIndustriesContainer(!showIndustriesContainer);
                      }}
                    >
                      <span
                        className={`flex-1 ms-3 text-left rtl:text-right whitespace-nowrap ${
                          showIndustriesContainer ? "text-[#35adf4]" : ""
                        }`}
                      >
                        Industries
                      </span>
                      {showIndustriesContainer ? (
                        <svg
                          className={`w-3 h-3 text-[#35adf4]`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5 5 1 1 5"
                          />
                        </svg>
                      ) : (
                        <svg
                          className={`w-3 h-3`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      )}
                    </button>
                    {showIndustriesContainer && (
                      <ul
                        id="dropdown-example"
                        className="my-2 space-y-2 border-l-2 ml-[2rem] border-gray-700"
                        style={{ paddingLeft: 8 }}
                      >
                        {/* <ListComp mobileClickHandler={mobileClickHandler} title="Home" route="/industries" /> */}
                        {/* <ListComp mobileClickHandler={mobileClickHandler} title="Fintech" route="/industries/fintech" /> */}
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Banking"
                          route="/industries/banking"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Ecommerce"
                          route="/industries/ecommerce"
                        />
                      </ul>
                    )}
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full p-2 text-base transition duration-75 rounded-lg group text-white"
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                      onClick={() => {
                        setShowResourcesContainer(!showResourcesContainer);
                      }}
                    >
                      <span
                        className={`flex-1 ms-3 text-left rtl:text-right whitespace-nowrap ${
                          showResourcesContainer ? "text-[#35adf4]" : ""
                        }`}
                      >
                        Resources
                      </span>
                      {showResourcesContainer ? (
                        <svg
                          className={`w-3 h-3 text-[#35adf4]`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5 5 1 1 5"
                          />
                        </svg>
                      ) : (
                        <svg
                          className={`w-3 h-3`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      )}
                    </button>
                    {showResourcesContainer && (
                      <ul
                        id="dropdown-example"
                        className="my-2 space-y-2 border-l-2 ml-[2rem] border-gray-700"
                        style={{ paddingLeft: 8 }}
                      >
                        {/* <ListComp mobileClickHandler={mobileClickHandler} title="Blogs" route="/resources/blog" /> */}
                        {/* <ListComp mobileClickHandler={mobileClickHandler} title="Home" route="/resources" /> */}
                        {/* <ListComp mobileClickHandler={mobileClickHandler} title="Support" route="/resources/support" /> */}
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="About RaptorX.ai"
                          route="/why-raptorx"
                        />
                        <ListComp
                          mobileClickHandler={mobileClickHandler}
                          title="Careers"
                          route="/careers"
                        />
                      </ul>
                    )}
                  </li>
                  <li>
                    {/* <Link to="/pricing" onClick={() => { mobileClickHandler() }}
                    className=" flex items-center w-full px-4 py-2 text-white transition duration-75 rounded-lg pl-11 group"
                  >
                    Pricing
                  </Link> */}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};
export default Header;
