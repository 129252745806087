import React, { useState } from "react";
import PopUp from "./PopUp";
export default function WhyPanoplia() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  return (
    <>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info" style={{ maxWidth: "100%" }}>
            <div id="personalized-solution">WHY PANOPLIA</div>
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              The only way to grow confidently
            </div>
            <div className="fraud-handling-info">
              Are you looking for an industry leader to protect your growing
              business with the most efficient yet accurate strategy? You’ve
              found it. Learn more about why Panoplia is right for you.
            </div>
            <div className="primary-button" onClick={togglePopUp}>
              Request a demo
            </div>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container d-flex flex-column gap-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="fullWidthImageAbove headerTextAlign-center">
                <p id="offers">THE PROBLEM</p>
                <h2 className="header-text text-center">
                  The challenges of growing a business
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center">
                    Doing business in today's digital world is difficult. There
                    are always risks. And managing those risks is a challenge.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row multicol justify-content-center columnStyle-fullWidthImageAbove">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
                <div className="colBGimg">
                  <img
                    src="../icons/account-take-over-icon.svg"
                    alt="refunding service header image"
                  />
                </div>
                <h3 className="header-text">Shifting Threats</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Risk evolves. New threats emerge every day. What worked
                    yesterday might not be effective tomorrow.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
                <div className="colBGimg">
                  <img
                    src="../icons/credit-card-icon.svg"
                    alt="refunding service header image"
                  />
                </div>
                <h3 className="header-text">Confusing Processes</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    A lot of time and effort goes into manual tasks. Yet there
                    is no clear way to tell what is and isn't working.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
                <div className="colBGimg">
                  <img
                    src="../icons/identity-theft-icon.svg"
                    alt="refunding service header image"
                  />
                </div>
                <h3 className="header-text">Financial Loss</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Margins are tight. You can't afford to lose money — yet
                    revenue slips away with every new threat you face.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div
            className="wallet-info"
            style={{ maxWidth: "100%", width: "31rem " }}
          >
            <div id="personalized-solution">THE SOLUTION</div>
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Introducing trust and safety technology
            </div>
            <div className="fraud-handling-info">
              We believe the solution to evolving threats, labor-intensive
              processes, and costly risks is trust and safety technology.
              <br />
              We use data-driven decisions, advanced machine learning, and
              decades of experience to overcome the challenges that commonly
              plague today's growing businesses. The result is greater
              confidence in every interaction.
            </div>
            <div className="primary-button" onClick={togglePopUp}>
              Request a demo
            </div>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container d-flex flex-column gap-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="fullWidthImageAbove headerTextAlign-center">
                <p id="offers">THE PROBLEM</p>
                <h2 className="header-text text-center">
                  The challenges of growing a business
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center">
                    Doing business in today's digital world is difficult. There
                    are always risks. And managing those risks is a challenge.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="transaction-image-wrapper">
            <div className="wallet-info" style={{ maxWidth: "100%" }}>
              <div
                id="adjust-text"
                className="d-flex align-items-start flex-column"
              >
                1. Complete Strategy
              </div>
              <div className="fraud-handling-info">
                You need protection against any threat that could come your way.
                And we have everything you could possibly need — all in one
                easy-to-use platform.
              </div>
              <div
                className="col-lg col-md-6 col-sm-12 d-flex align-items-center justify-content-between"
                style={{ width: "100%" }}
              >
                <div
                  className="kountContentCol d-flex flex-column h-100 mediaLocation-checklistColumns checklist"
                  style={{ width: "31rem" }}
                >
                  <div className="formattedTextBlock header-description">
                    <ul>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Pick the solutions that are relevant to your business
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Adjust processes as your business grows and needs change
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Simplify budgeting with just one expense
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Monitor outcomes with greater accuracy and transparency
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Reduce operational costs and boost your bottom line
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <a
                className="trust-safety-info"
                style={{ textDecoration: "none" }}
              >
                Learn More
                <img src="../icons/arrow-icon.svg"></img>
              </a>
            </div>
            <div className="authorized-section" style={{ maxWidth: "38rem" }}>
              <img src="../images/mac-image.svg"></img>
            </div>
          </div>
          <div className="transaction-image-wrapper">
            <div className="authorized-section" style={{ maxWidth: "38rem" }}>
              <img src="../images/mac-image.svg"></img>
            </div>
            <div className="wallet-info" style={{ maxWidth: "100%" }}>
              <div
                id="adjust-text"
                className="d-flex align-items-start flex-column"
              >
                2. Unmatched Expertise
              </div>
              <div className="fraud-handling-info">
                We've spent decades learning the industry, testing strategies,
                gathering data, and analyzing trends. We know what it takes to
                succeed.
              </div>
              <div
                className="col-lg col-md-6 col-sm-12 d-flex align-items-center justify-content-between"
                style={{ width: "100%" }}
              >
                <div
                  className="kountContentCol d-flex flex-column h-100 mediaLocation-checklistColumns checklist"
                  style={{ width: "31rem" }}
                >
                  <div className="formattedTextBlock header-description">
                    <ul>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Base decisions on data accumulated over two decades
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Benefit from experience with thousands of global clients
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Receive advice on strategy and policy management
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Consult with our on-demand support team
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <a
                className="trust-safety-info"
                style={{ textDecoration: "none" }}
              >
                Learn More
                <img src="../icons/arrow-icon.svg"></img>
              </a>
            </div>
          </div>
          <div className="transaction-image-wrapper">
            <div className="wallet-info" style={{ maxWidth: "100%" }}>
              <div
                id="adjust-text"
                className="d-flex align-items-start flex-column"
              >
                3. Flexible Technology
              </div>
              <div className="fraud-handling-info">
                You decide how much or how little is automated. Then the machine
                learning goes to work, replacing repetitive, error-prone
                processes with accurate efficiency.
              </div>
              <div
                className="col-lg col-md-6 col-sm-12 d-flex align-items-center justify-content-between"
                style={{ width: "100%" }}
              >
                <div
                  className="kountContentCol d-flex flex-column h-100 mediaLocation-checklistColumns checklist"
                  style={{ width: "31rem" }}
                >
                  <div className="formattedTextBlock header-description">
                    <ul>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Retain full control over what the technology manages
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Increase decision accuracy with multiple machine
                        learning types
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Adjust policies in real-time with immediate results
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Choose from multiple integration options
                      </li>
                      <li className="liWarning">
                        <img src="../icons/tick-icon.svg" />
                        Onboard solutions in as little as 24 hours
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <a
                className="trust-safety-info"
                style={{ textDecoration: "none" }}
              >
                Learn More
                <img src="../icons/arrow-icon.svg"></img>
              </a>
            </div>
            <div className="authorized-section" style={{ maxWidth: "38rem" }}>
              <img src="../images/mac-image.svg"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container d-flex flex-column gap-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="fullWidthImageAbove headerTextAlign-center">
                <p id="offers">COMPETITION</p>
                <h2 className="header-text text-center">The alternatives</h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center">
                    If you don't use Panoplia, what are the alternatives?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row multicol justify-content-center columnStyle-fullWidthImageAbove">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
                <div className="colBGimg">
                  <img
                    src="../icons/account-take-over-icon.svg"
                    alt="refunding service header image"
                  />
                </div>
                <h3 className="header-text">Incomplete Strategy</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    You create multiple layers of protection for your business,
                    but each layer involves a different service provider. So
                    data and processes are fragmented across multiple platforms
                    that aren't compatible with each other. Results are poor,
                    and costs are high.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
                <div className="colBGimg">
                  <img
                    src="../icons/credit-card-icon.svg"
                    alt="refunding service header image"
                  />
                </div>
                <h3 className="header-text">Insufficient Expertise</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    Data has to accumulate over several months before it is
                    useful. Once the technology finally kicks in, decisions
                    aren't accurate. Because only one type of machine learning
                    is used. And new data networks are more than a decade behind
                    on insights.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100">
                <div className="colBGimg">
                  <img
                    src="../icons/identity-theft-icon.svg"
                    alt="refunding service header image"
                  />
                </div>
                <h3 className="header-text">Inflexible Technology</h3>
                <div className="formattedTextBlock">
                  <p className="header-description">
                    You either pick a set-it-and-forget-it strategy or a highly
                    manual process. Once your decision has been made, you can't
                    change your mind. If you want to try something different,
                    you'll need a different service provider.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="container d-flex flex-column gap-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="fullWidthImageAbove headerTextAlign-center">
                <p id="offers">WHAT'S INCLUDED</p>
                <h2 className="header-text text-center">
                  Unique features of Panoplia
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center">
                    Panoplia's trust and safety technology is built with unique
                    features you won't find anywhere else.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row multicol justify-content-center columnStyle-cardLink"
          style={{ maxWidth: "100%" }}
        >
          <div className="col-lg col-md-6 col-sm-12 ">
            <a
              href="/why-panoplia/complete-strategy"
              // href="/why-panopila/comprehensive-protection"
            >
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-cardLink page-navigation">
                <div className="colBGimg">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Account Protection Shield and Star"
                    width="1"
                    height="1"
                    src="../icons/verified-icon.svg"
                  />
                </div>
                <h3 className="header-text">Comprehensive Protection</h3>
                <div className="formattedTextBlock"></div>
              </div>
            </a>
          </div>
          <div className="col-lg col-md-6 col-sm-12 ">
            <a
              href="/why-panoplia/complete-strategy"
              // href="/why-panopila/machine-learning"
            >
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-cardLink page-navigation">
                <div className="colBGimg">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Account Protection Shield and Star"
                    width="1"
                    height="1"
                    src="../icons/verified-icon.svg"
                  />
                </div>
                <h3 className="header-text">Unsupervised ML & LLM</h3>
                <div className="formattedTextBlock"></div>
              </div>
            </a>
          </div>
          <div className="col-lg col-md-6 col-sm-12 ">
            <a
              href="/why-panoplia/complete-strategy"
              // href="/why-panopila/explainability-ai"
            >
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-cardLink page-navigation">
                <div className="colBGimg">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Account Protection Shield and Star"
                    width="1"
                    height="1"
                    src="../icons/verified-icon.svg"
                  />
                </div>
                <h3 className="header-text">Explainability AI</h3>
                <div className="formattedTextBlock"></div>
              </div>
            </a>
          </div>
          <div className="col-lg col-md-6 col-sm-12 ">
            <a
              href="/why-panoplia/complete-strategy"
              // href="/why-panopila/graphical-dashboard"
            >
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-cardLink page-navigation">
                <div className="colBGimg">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Account Protection Shield and Star"
                    width="1"
                    height="1"
                    src="../icons/verified-icon.svg"
                  />
                </div>
                <h3 className="header-text">Graphical Dashboard</h3>
                <div className="formattedTextBlock"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info" style={{ maxWidth: "100%" }}>
            <div id="personalized-solution">PRICING</div>
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              How much Kount costs
            </div>
            <div className="fraud-handling-info">
              How much does trust and safety cost? Kount offers a la carte
              pricing so you only pay for the services you need — and nothing
              you don't. That means your cost depends on the solutions you use.
            </div>
            <div className="primary-button" onClick={togglePopUp}>
              Visit Page Pricing
            </div>
          </div>
          <div className="wallet-info" style={{ maxWidth: "100%" }}>
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              What We Offer
            </div>
            <div
              className="col-lg col-md-6 col-sm-12 d-flex align-items-center justify-content-between"
              style={{ width: "100%" }}
            >
              <div
                className="kountContentCol d-flex flex-column h-100 mediaLocation-checklistColumns checklist"
                style={{ width: "31rem" }}
              >
                <div className="formattedTextBlock header-description">
                  <ul>
                    <li className="liWarning">
                      <img src="../icons/tick-icon.svg" />
                      Transparent pricing
                    </li>
                    <li className="liWarning">
                      <img src="../icons/tick-icon.svg" />
                      No hidden fees
                    </li>
                    <li className="liWarning">
                      <img src="../icons/tick-icon.svg" />
                      Unlimited user accounts
                    </li>
                    <li className="liWarning">
                      <img src="../icons/tick-icon.svg" />
                      Multiple payment options
                    </li>
                    <li className="liWarning">
                      <img src="../icons/tick-icon.svg" />
                      Flexible invoice scheduling
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
