import React from "react";
import "./Content.css";
import ProblemCard from "Components/home/ProblemCard";
import TickList from "Components/home/TickList";
import Footer from "Footer";
import { Link } from "react-router-dom";
function Content() {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:mt-20 px-4 pt-5 lg:px-0 lg:pt-0" style={{ background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f2930)' }}>

        <div className="flex flex-col gap-2 items-start justify-center px-[5px] md:px-4 lg:pl-32" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0">WHY RAPTORX</div>
          <div id="adjust-text" className="my-0 text-[30px] lg:text-[36px]">
            The only way to grow confidently.
          </div>
          <div className="fraud-handling-info text-[16px]">
            Are you looking for an industry leader to protect your growing business with the most efficient yet accurate strategy? You’ve found it. Learn more about why raptorX.ai is right for you.
          </div>
          <a href="#formData">
          <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-6 border-none text-white ">
            Get started Now
          </button>
          </a>
          
        </div>
        <div className=" my-10 flex justify-center ">
          <img src="./images/p1img1.png" className="lg:w-8/12"></img>
        </div>
      </div>
      <div className=" mx-0  px-3 lg:px-0" style={{ paddingTop: "2rem" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="above headerTextAlign-center">
                <p className="text-center font-medium text-base text-[#35adf4] tracking-[4px]">THE PROBLEM</p>
                <h2 className="text-white font-medium text-3xl leading-tight text-center mb-3">The challenges of growing a business</h2>
                <div className="header-description text-center mb-10">
                  <p className="text-[#FFFFFFB2]">
                    Doing business in today's digital world is difficult. There are always risks. And<br className="hidden lg:block" /> managing those risks is a challenge.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center textAlignLeft">
            <ProblemCard
              icon="images/homePage/set1icon1.png"
              heading="Changing Menaces"
              desc="Dangers are always changing. Every day, new threats pop up. What kept us safe yesterday might not work tomorrow."
            />
            <ProblemCard
              icon="images/homePage/set1icon2.png"
              heading="Complex Procedures"
              desc="With RaptorX’s real-time analysis and fraud detection algorithms, businesses can confidently process payments, recognizing stolen or fake credit card information to prevent financial losses."
            />
            <ProblemCard
              icon="images/homePage/set1icon3.png"
              heading="Monetary Depletion"
              desc="Doing things by hand takes a long time and a lot of work. But it's hard to figure out what's actually helping and what's not."
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:mt-20 py-8 px-4 lg:px-0" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>

        <div className="flex flex-col gap-2 items-start justify-center px-[5px] md:px-4 lg:pl-32 lg:pr-12" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0">THE PROBLEM</div>
          <div id="adjust-text" className="my-0 text-[30px] lg:text-[36px]">
            Bringing in advanced technology to enhance trust and safety in our operations.
          </div>
          <div className="fraud-handling-info text-[16px]">
            We think that trust and safety technology is the answer to dealing with changing threats, time-consuming tasks, and expensive risks.<br /><br />

            We make decisions based on data, use smart machines, and rely on years of experience to tackle the common problems faced by businesses that are growing today. The outcome is more confidence in every interaction.
          </div>
          <a href="#formData">
          <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-6 border-none text-white ">
            Request a Demo
          </button>
          </a>
          
        </div>
        <div className=" my-10 flex justify-center lg:justify-start lg:pl-8">
          <img src="images/homePage/problem.png" className="lg:w-10/12"></img>
        </div>
      </div>
      <div className="content-container px-2 lg:px-0">
        <div className="container-fluid rowCount_2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="above headerTextAlign-center">
                  <p id="offers">WHAT SETS US APART</p>
                  <div className="flex items-center justify-center mt-[-0.5rem]">
                    <h2 className="text-white font-semibold font-sans  text-xl lg:text-[32px] text-center max-w-[35rem] leading-normal">
                      What distinguishes RaptorX.ai is its distinctive approach and features
                    </h2>
                  </div>

                  <div className="header-description text-center flex items-center justify-center">
                    <p className="max-w-[30rem] text-[#FFFFFFB2]">
                      RaptorX.ai stands out for three key reasons, and we're confident that our company is the only one with this special mix of strengths.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* new data started */}
            <div className="row ">
              <div className="col-lg-12 ">
                <div className="row multicol justify-content-center flex-column columnStyle-checklistColumns gap-14" style={{ marginBottom: "5rem" }}>
                  <div
                    className="col-lg col-md-6 col-sm-12  grid grid-cols-1 md:grid-cols-2 align-items-center justify-between"
                    style={{ width: "100%", marginTop: "2.5rem", }}
                  >
                    <img src="images/homePage/setApart1.png" className="mx-auto" />

                    <TickList
                      list={[
                        "Choose solutions that fit your business needs.",
                        "Adapt your processes as your business grows and its requirements evolve.",
                        "Simplify your budget by consolidating expenses into one.",
                        "Keep a close eye on results with more accurate and transparent monitoring.",
                        "Cut operational costs and increase your overall profits."
                      ]}
                      heading="Comprehensive Approach"
                      desc="For many years, we've immersed ourselves in the industry, tried out different strategies, collected data, and studied trends. We understand what it really takes to thrive."
                      icon="icons/1-icon.svg"
                    />

                  </div>
                  <div
                    className="col-lg col-md-6 col-sm-12 grid grid-cols-1 md:grid-cols-2  align-items-center justify-content-between"
                    style={{ width: "100%" }}
                  >


                    <div className="lg:order-first">
                    <TickList
                      list={[
                        "Make decisions backed by two decades' worth of collected data.",
                        "Gain insights from our vast experience working with thousands of clients worldwide.",
                        "Get guidance on managing strategies and policies.",
                        "Reach out to our on-demand support team for consultation.",
                      ]}
                      heading="Patented Deep Technology"
                      desc="For many years, we've immersed ourselves in the industry, tried out different strategies, collected data, and studied trends. We understand what it really takes to thrive."
                      icon="icons/2-icon.svg"
                    />
                    </div>
                    <img src="images/homePage/setApart2.png" className="mx-auto order-first lg:order-last" />
                  </div>
                </div>
              </div>
            </div>
            {/* ned data ends */}
          </div>
        </div>
      </div>
      {/* starts here */}
      {/* <div className="content-container mx-0 px-3 lg:px-0" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)', paddingTop: "2rem" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="above headerTextAlign-center">
                <p className="text-center font-medium text-base text-[#35adf4] tracking-[4px]">CONFLICT</p>
                <h2 className="text-white font-medium text-3xl leading-tight text-center mb-3">The other options</h2>
                <div className="header-description text-center mb-10">
                  <p className="text-[#FFFFFFB2]">
                  If RaptorX.ai isn't your choice, what other options do you have?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center textAlignLeft">
            <ProblemCard
              icon="images/homePage/set2icon1.png"
              heading="Strategy with Gaps"
              desc="You set up different shields to protect your business, but each shield comes from a different provider. This makes your data and processes scattered across platforms that don't work well together. The outcome is not great, and it ends up costing you more."
            />
            <ProblemCard
              icon="images/homePage/set2icon2.png"
              heading="Lack of Specialized Knowledge"
              desc="It takes many months for the data to pile up before it becomes helpful. When the technology does start working, the decisions it makes aren't accurate. This happens because it only relies on one type of machine learning. Additionally, the new data networks are more than ten years behind in gaining insights."
            />
            <ProblemCard
              icon="images/homePage/set2icon3.png"
              heading="Rigid Technology Solutions"
              desc="You're faced with two options: either choose a strategy and stick with it without making changes, or go for a very hands-on approach. Once you've made a decision, there's no turning back. If you decide to explore a new approach, you'll have to find a different service provider."
            />
          </div>
        </div>
      </div> */}
      <div
        className="content-container px-4 lg:px-6"
        style={{ margin: 0 }}
      >
        <div id="_bg__frame_67" style={{ marginTop: "3.5rem" }}>
          <div id="offers">WHAT COMES WITH IT</div>
          <div id="full_confidence_and_security__no_matter_what_comes_your_way_">
          Standout Qualities of RaptorX.ai
          </div>
          <div className="fraud-handling-info text-center text-[#FFFFFFB2]">
          RaptorX.ai's trust and safety technology comes with special features that set it apart from the rest.
          </div>
        </div>
        <div className="md:grid-cols-4 items-center justify-center gap-6 mt-14 flex-wrap mb-14 grid grid-cols-2">
          <Link to="/why-panopila/comprehensive-protection" className="flex flex-col items-center gap-3 bg-[#1B2129] p-4 rounded-lg max-w-[185px]">
            <img src="images/homePage/qualities1.png"></img>
            <h4 className="text-base md:text-lg text-white text-center">Comprehensive <br /> Protection</h4>
          </Link>
          <Link to="/why-panopila/machine-learning" className="flex flex-col items-center gap-3 bg-[#1B2129] p-4 rounded-lg max-w-[185px]">
            <img src="images/homePage/qualities2.png"></img>
            <h4 className="text-base md:text-lg text-white text-center">Unsupervised ML & LLM</h4>
          </Link>
          <Link to="/why-panopila/explainability-ai" className="flex flex-col items-center gap-3 bg-[#1B2129] p-4 rounded-lg max-w-[185px]">
            <img src="images/homePage/qualities3.png"></img>
            <h4 className="text-base md:text-lg text-white text-center">Explainability <br /> AI</h4>
          </Link>
          <Link to="/why-panopila/graphical-dashboard" className="flex flex-col items-center gap-3 bg-[#1B2129] p-4 rounded-lg max-w-[185px]">
            <img src="images/homePage/qualities4.png"></img>
            <h4 className="text-base md:text-lg text-white text-center">Graphical <br /> Dashboard</h4>
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:mt-20 py-8 px-4 lg:px-0" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>

        <div className="flex flex-col gap-2 items-start justify-center px-[5px] md:px-4 lg:pl-32 lg:pr-12" style={{ maxWidth: '100%' }}>
          <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium mb-0">PRICE BREAKDOWN</div>
          <div id="adjust-text" className="my-0 text-[30px] lg:text-[36px]">
          The pricing for RaptorX.ai
          </div>
          <div className="fraud-handling-info text-[16px]">
          The expense for trust and safety through RaptorX.ai is tailored to your needs. With a menu-style pricing, you only pay for the specific services you choose, ensuring you're charged only for what you use.
          </div>
          <a href="#formData">
          <button className="btnColor rounded-md flex items-start px-6 py-2 justify-start gap-4 mt-6 border-none text-white ">
            Request a Demo
          </button>
          </a>
          
        </div>
        <div className=" my-10 flex justify-center lg:justify-start lg:pl-8">
          <img src="images/homePage/price.png" className="lg:w-10/12"></img>
        </div>
      </div>
    </>
  );
}
export default Content;
