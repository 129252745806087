import React, { useState } from "react";
import PopUp from "./PopUp";
import "./Ecommerce.css";
import NumberList from "Components/NumberList";
import IconCards from "Components/solutions/IconCards";
import NumberCard from "Components/ecommerce/NumberCard";
export default function Ecommerce() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  return (
    <>
      <div className="flex-col flex justify-center bg-background-dark items-center md:pt-20" style={{ marginTop: "0rem" }}>
        <div className="grid grid-cols-1 lg:grid-cols-2 pt-2 lg:px-8" style={{ background: 'linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }} >

          <div className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6" style={{ maxWidth: '100%' }}>
            <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">E-commerce Guardian</div>
            <div className='text-4xl lg:text-5xl text-white font-semibold'>
              Defend your online business with RaptorX.ai
            </div>
            <div className="fraud-handling-info">
              The ultimate e-commerce guardian. Our specialized solution is built to safeguard your online store, effectively stopping fraudsters in their tracks without inconveniencing genuine customers. Experience real-time fraud detection and prevention, ensuring your e-commerce venture remains secure.
            </div>
            <button className="btnColor rounded-md flex items-start px-2 md:px-6 py-2 justify-start gap-4 mt-0 border-none text-white">

              <span >Request a Demo</span>
            </button>
          </div>
          <div className="mac-image-wrapper mt-8">
            <img src="../images/EcomHeroImg.svg" className="" />
          </div>
        </div>
      </div>

      <div className="content-container mx-0 py-14 " >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="above headerTextAlign-center">
                <p className="text-center font-medium text-lg text-[#35adf4] tracking-[4px] px-2">Elevate Your E-commerce Safeguarding</p>
                <h2 className="text-white flex justify-center mx-1 font-medium text-3xl leading-tight text-center mb-3 px-3">RaptorX.ai: A New Era of <br className="hidden md:flex"></br> E-commerce Fraud Protection</h2>
                <div className="header-description text-center mb-10 text-[#FFFFFFB2]">
                  <p className="md:mx-5 px-4">
                    In a digital world where online threats constantly evolve, RaptorX.ai emerges as the vanguard of e-commerce security. Our solution is meticulously tailored to address the ever-changing landscape of online fraud, ensuring that your business remains unyielding in the face of diverse and sophisticated risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center textAlignLeft mx-3">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 md:mb-14 mb-4 mx-2 md:mx-0">

                <div className="mr-0 flex md:justify-start justify-center ">
                  <img
                    className="w-14"
                    alt="Icon of a shield and card"
                    src="../icons/promoIcon.svg"
                  />
                </div>
                <h3 className="text-base mt-4 text-[#FFFFFF] flex justify-center md:justify-start">Promo and loyalty abuse</h3>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 md:mb-14 mb-4 mx-2 md:mx-0">

                <div className="mr-0 flex md:justify-start justify-center ">
                  <img
                    className="w-14"
                    alt="Icon of identity verification"
                    src="../icons/transaction2Icon.svg"
                  />
                </div>
                <h3 className="text-base mt-4 text-[#FFFFFF] flex justify-center md:justify-start">Unauthorized transactions</h3>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 md:mb-14 mb-4 mx-2 md:mx-0">

                <div className="mr-0 flex md:justify-start justify-center ">
                  <img
                    className="w-14"
                    alt="Icon of identity verification"
                    src="../icons/friendlyFraudIcon.svg"
                  />
                </div>
                <h3 className="text-base mt-4 text-[#FFFFFF] flex justify-center md:justify-start">Friendly fraud</h3>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100  mx-2 md:mx-0">

                <div className="mr-0 flex md:justify-start justify-center ">
                  <img
                    className="w-14"
                    alt="Icon of a legal document"
                    src="../icons/phissingIcon.svg"
                  />
                </div>
                <h3 className="text-base mt-4 text-[#FFFFFF] flex justify-center md:justify-start">Phishing and account takeover fraud</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 md:mt-1">
          <a href="#formData">
            <button
              className="btnColor rounded-md flex items-start px-7 py-2 justify-start gap-4 mt-0 border-none text-white"> Request a Demo</button>
          </a>
        </div>
      </div>

      <div style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)', paddingTop: "2rem", paddingBottom: "2rem" }}>
        <NumberList
          icon="../images/ecomHowWorksImg.svg"
          title="HOW IT WORKS"
          heading="Transaction Initiation and User Interaction"
          desc="A customer engages with your online platform, adding items to their cart and initiating a transaction."
          list={[
            { heading: "Behavioral Risk Assessment", desc: "Advanced machine learning algorithms within RaptorX.ai analyze the user's historical behavior and current attributes to assess the risk associated with the transaction." },
            { heading: "Real-time Decision Making", desc: "Based on the calculated risk level, historical patterns, and your predefined policies, RaptorX.ai makes an instantaneous decision. Transactions may be approved, declined, or flagged for manual review, depending on the level of suspicion." },
            { heading: "Link Analysis", desc: "The system performs link analysis to detect hidden relationships and associations between seemingly unrelated transactions, users, or accounts." },
            { heading: "Network Identification", desc: "RaptorX.ai identifies clusters of transactions or activities that exhibit suspicious behavior, indicating the presence of a potential fraud ring." },

          ]}
        />

      </div>


      <div className="content-container mx-0 py-14" >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="above headerTextAlign-center">
                <p className="text-center font-medium text-lg text-[#35adf4] tracking-[4px] px-2">Growing with raptorx.ai</p>
                <h2 className="text-white flex justify-center mx-1 font-medium text-3xl leading-tight text-center mb-3 px-2">More than just fraud prevention software</h2>
                <div className="header-description text-center mb-10 text-[#FFFFFFB2]">
                  <p className="px-4 md:mx-5">
                    Empowering Your Success. In the ever-shifting landscape of challenges, RaptorX.ai offers not just protection but also an engine for growth. With our cutting-edge fraud detection technology, your business is poised to conquer any obstacle and thrive.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center textAlignLeft mx-3">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 mb-2 md:mb-14 mx-2 md:mx-0">

                <div className="mr-0">
                  <img
                    className="w-14"
                    alt="Icon of a shield and card"
                    src="../icons/ecomIdentityImg.svg"
                  />
                </div>
                <h3 className="header-text mt-4 text-[#FFFFFF]">Identity</h3>

                <div className="header-description text-[#FFFFFFB2]">
                  <p>
                    RaptorX’s employs advanced machine learning and behavioral biometrics to detect unusual user behavior and swiftly block unauthorized access, safeguarding against fraudulent transactions and fund theft.
                  </p>
                </div>

              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 mb-2 md:mb-14 mx-2 md:mx-0">

                <div className="mr-0">
                  <img
                    className="w-14"
                    alt="Icon of identity verification"
                    src="../icons/ecomPaymentsImg.svg"
                  />
                </div>
                <h3 className="header-text mt-4 text-[#FFFFFF]">Payments</h3>

                <div className="header-description text-[#FFFFFFB2]">
                  <p>
                    With RaptorX’s real-time analysis and fraud detection algorithms, businesses can confidently process payments, recognizing stolen or fake credit card information to prevent financial losses.
                  </p>
                </div>

              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="d-flex flex-column h-100 mb-2 md:mb-14  mx-2 md:mx-0">

                <div className="mr-0">
                  <img
                    className="w-14"
                    alt="Icon of a legal document"
                    src="../icons/ecomComplianceImg.svg"
                  />
                </div>
                <h3 className="header-text mt-4 text-[#FFFFFF]">Compliance</h3>

                <div className="header-description text-[#FFFFFFB2]">
                  <p>
                    RaptorX’s identity verification solutions and behavioral biometrics ensure the protection of personal information, thwarting fraudsters' attempts to open fraudulent accounts and commit financial crimes.
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-1">
          <a href="#formData">
            <button
              className="btnColor rounded-md flex items-start px-7 py-2 justify-start gap-4 mt-0 border-none text-white"> Request a Demo</button>
          </a>
        </div>
      </div>

      <div className="lg:hidden grid grid-cols-1 px-8 py-16 gap-4" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>
        <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium text-center uppercase">results you deserve</div>
        <div className='text-center text-white  font-gilroy-semibold text-3xl font-semibold leading-normal max-w-[34rem]'>
          What you expect from RaptorX.ai
        </div>
        <div className="fraud-handling-info text-center">
          Our Clients Experience significant improvements in multiple metrics. We don’t solve just part of the problem - we offer complete solutions
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 mt-4">
          <NumberCard icon="../icons/1-icon.svg" heading="Increase Approval Rates" desc="Boost approval rates by up to 20%, driving higher revenue." />
          <NumberCard icon="../icons/2-icon.svg" heading="Boost Revenue and Conversion Rates" desc="Witness a 15% increase in revenue and a 10% surge in conversion rates." />
          <NumberCard icon="../icons/3-icon.svg" heading="Increase Decision Accuracy" desc="Achieve a 98% decision accuracy rate, minimizing errors." />
          <NumberCard icon="../icons/4-icon.svg" heading="Block Fraud and Chargebacks" desc="Prevent 90% of fraudulent transactions, reducing chargebacks." />
          <NumberCard icon="../icons/5-icon.svg" heading="Reduce Friction" desc="Decrease transaction friction, resulting in a 15% increase in customer trust." />
          <NumberCard icon="../icons/6-icon.svg" heading="Reduce Manual Processes" desc="Eliminate 40% of manual processes, streamlining operations." />
          <NumberCard icon="../icons/7-icon.svg" heading="Eliminate Hunches and Guesses" desc="Replace guesswork with data-driven decisions for 90% of cases." />
        </div>
      </div>
      <div className="hidden lg:flex flex-col px-24 py-16 gap-4 items-center" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>
        <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium text-center uppercase">results you deserve</div>
        <div className='text-center text-white  font-gilroy-semibold text-3xl font-semibold leading-normal max-w-[34rem]'>
          What you expect from RaptorX.ai
        </div>
        <div className="fraud-handling-info text-center">
          Our Clients Experience significant improvements in multiple metrics. We don’t solve just part of the problem - we offer complete solutions
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 mt-4">
          <NumberCard icon="../icons/1-icon.svg" heading="Increase Approval Rates" desc="Boost approval rates by up to 20%, driving higher revenue." />
          <NumberCard icon="../icons/2-icon.svg" heading="Boost Revenue and Conversion Rates" desc="Witness a 15% increase in revenue and a 10% surge in conversion rates." />
          <NumberCard icon="../icons/3-icon.svg" heading="Increase Decision Accuracy" desc="Achieve a 98% decision accuracy rate, minimizing errors." />
          <NumberCard icon="../icons/4-icon.svg" heading="Block Fraud and Chargebacks" desc="Prevent 90% of fraudulent transactions, reducing chargebacks." />
          <NumberCard icon="../icons/5-icon.svg" heading="Reduce Friction" desc="Decrease transaction friction, resulting in a 15% increase in customer trust." />
          <NumberCard icon="../icons/6-icon.svg" heading="Reduce Manual Processes" desc="Eliminate 40% of manual processes, streamlining operations." />
          <div></div>
          <NumberCard icon="../icons/7-icon.svg" heading="Eliminate Hunches and Guesses" desc="Replace guesswork with data-driven decisions for 90% of cases." />
          <div></div>
        </div>
      </div>
      {/* UseCase Starts here */}
      {/* <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="bigBoxImgHeaderTextLink headerTextAlign-center">
                <p id="offers">USE CASES</p>
                <h2 className="header-text text-center text-white flex justify-center md:justify-start mx-3">
                  Full ecommerce fraud protection. <br /> No matter the risk.
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75 mx-3">
                    Our fraud prevention solution is specifically designed to
                    block the unique threats targeting ecommerce businesses.
                    Whether you need to solve one of the online fraud challenges
                    or overcome them all, Panopila can help.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row multicol justify-content-center columnStyle-bigBoxImgHeaderTextLink mx-2">
            <div className="col-lg col-md-6 col-sm-12">
              <a href="/new-account-fraud-prevention" className="box-wrapper">
                <div className="d-flex flex-column h-100 p-2 md:p-8">
                  <div className="colBGimg">
                    <img
                      className="img-fluid max-h-300 w-auto h-auto max-w-80 mx-auto mb-2 md:mb-32 block"
                      alt="Promo and loyalty abuse"
                      width="473"
                      height="458"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Promo and loyalty abuse</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Don't let anyone take advantage of your promotions,
                      offers, affiliate marketing, and loyalty programs. Block
                      new account opening fraud with ease and accuracy.
                    </p>
                  </div>

                  <div
                    className="d-flex align-items-end justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <a className="trust-safety-info">
                      Learn about account opening fraud prevention
                      <img src="../icons/arrow-icon.svg" />
                    </a>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <a href="/account-takeover-prevention" className="box-wrapper">
                <div className="d-flex flex-column h-100 p-2 md:p-8">
                  <div className="colBGimg">
                    <img
                      className="img-fluid max-h-300 w-auto h-auto max-w-80 mx-auto mb-2 md:mb-32 block"
                      alt="Account takeover fraud"
                      width="473"
                      height="458"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">
                    Phishing and account takeover fraud
                  </h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Don’t let fraudsters gain access to valuable information
                      stored in online accounts. Detect account takeover fraud
                      to protect the customer experience — and your bottom
                      line.&nbsp;
                    </p>
                  </div>

                  <div
                    className="d-flex align-items-end justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <a className="trust-safety-info">
                      Learn about account takeover detection
                      <img src="../icons/arrow-icon.svg" />
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="row multicol justify-content-center columnStyle-bigBoxImgHeaderTextLink mx-2">
            <div className="col-lg col-md-6 col-sm-12">
              <a href="/fraud-detection-software" className="box-wrapper">
                <div className="d-flex flex-column h-100 p-2 md:p-8">
                  <div className="colBGimg">
                    <img
                      className="img-fluid max-h-300 w-auto h-auto max-w-80 mx-auto mb-2 md:mb-32 block"
                      alt="Unauthorized transactions"
                      width="473"
                      height="458"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Unauthorized transactions</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Reduce the risk of credit card fraud and unauthorized
                      transactions. Defend against any threat — e-gift card
                      fraud, card testing schemes, triangulation fraud, and
                      more.&nbsp;
                    </p>
                  </div>
                  <div
                    className="d-flex align-items-end justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <a className="trust-safety-info">
                      Learn about fraud detection software
                      <img src="../icons/arrow-icon.svg" />
                    </a>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <a href="/chargeback-management" className="box-wrapper">
                <div className="d-flex flex-column h-100 p-2 md:p-8">
                  <div className="colBGimg">
                    <img
                      className="img-fluid max-h-300 w-auto h-auto max-w-80 mx-auto mb-2 md:mb-32 block"
                      alt="Friendly fraud"
                      width="473"
                      height="458"
                      src="../images/mac-image.svg"
                    />
                  </div>
                  <h3 className="header-text">Friendly fraud</h3>
                  <div className="formattedTextBlock">
                    <p className="header-description">
                      Stop invalid chargebacks from stealing your hard-earned
                      revenue. Challenge false fraud claims, defend your
                      business’s reputation, and protect your bottom line.&nbsp;
                    </p>
                  </div>
                  <div
                    className="d-flex align-items-end justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <a className="trust-safety-info">
                      Learn about chargeback management software
                      <img src="../icons/arrow-icon.svg" />
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div
        className="content-container"
        style={{ backgroundColor: "rgb(13, 13, 13)" }}
      >
        <div className="container" style={{ padding: "48px" }}>
          <div className="row align-items-center greyOutline">
            <div className="col-lg-6 order-lg-last">
              <div className="kountContentCol">
                <div className="colBGimg">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="customized solution"
                    src="../images/mac-image.svg"
                    style={{ height: "auto", width: "auto" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="kountContentCol">
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    <strong>Want to use the technology in a unique way?</strong>{" "}
                    Have specific use cases we haven’t outlined? Let us know. We
                    can customize our solutions to fit your business’s
                    individual needs.
                  </p>
                </div>
                <div className="buttons" style={{ maxWidth: "16rem" }}>
                  <a href="#request-demo">
                    <div className="btnColor rounded-md flex items-start px-6 py-3 justify-center gap-4 mt-0 border-none text-white cursor-pointer">REQUEST A DEMO</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="content-container pb-10">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col">
                  <div className="kountHeaderCol">
                    <p id="offers">QUICK & EASY ONBOARDING</p>
                    <h2 className="header-text text-center text-white flex justify-center md:justify-start mx-3">
                      Fraud prevention integrations with the biggest ecommerce
                      platforms
                    </h2>
                    <p className="header-description text-center text-white opacity-75 mx-3">
                      Panopila has direct integrations with the industry’s
                      biggest ecommerce platforms. Whether you want a Shopify
                      fraud prevention app, Woo Commerce extension, or something
                      in between, Panopila can help.
                    </p>
                  </div>
                </div>
              </div>
              <div className="views-element-container">
                <div className="view view-partners view-id-partners view-display-id-block_2 js-view-dom-id-3d73d76ff8016c3e10fddc0767d60c97d988421e9058f78d0f4f30732be00b35">
                  <div className="view-content">
                    <div
                      id="views-bootstrap-partners-block-2"
                      className="grid views-view-grid mx-4"
                    >
                      <div className="row">
                        
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 filteredTile">
                          <div className="views-field views-field-field-media-image">
                            <div className="field-content">
                              <a
                                href="/partners/woocommerce-fraud-prevention-plugin"
                                data-cat="Ecommerce platform"
                                className="filteredLink"
                              >
                                <div className="viewCard">
                                  <img
                                    className="b-lazy b-loaded"
                                    src="../images/mac-image.svg"
                                    alt="WooCommerce Logo"
                                    style={{ width: "10rem", height: "10rem" }}
                                  />
                                  <div className="filterIndicator"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="view-footer">
                    <div className="buttons centered flex justify-center">
                      <a href="/partners/marketplace">
                        <div className="  flex items-center justify-center gap-4 mt-0  w-fit">
                        <button className="btnColor rounded-md px-2 text-sm md:text-base md:px-6 py-3 border-none text-white cursor-pointer">EXPLORE OUR PARTNER MARKETPLACE</button>
                          
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div
        className="flex flex-col justify-center items-center pt-2 md:pt-14"
        style={{ backgroundColor: "rgb(13, 13, 13)" }}
      >
        <div className="container" style={{ padding: "48px" }}>
          <div className="row align-items-center greyOutlineShadow">
            <div className="col-lg-5">
              <div className="kountContentCol">
                <div className="colBGimg">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Panopila dashboard"
                    src="../images/mac-image.svg"
                    style={{ height: "auto", width: "auto" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="kountContentCol">
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    Not using one of our integrated ecommerce platforms? That’s
                    fine! Panopila’s flexible technology is designed to work
                    with your existing processes, resources, and abilities —
                    whatever those may be. Schedule a call with our team today
                    to discuss the best integration strategy for your business.
                  </p>
                </div>
                <div className="buttons" style={{ maxWidth: "16rem" }}>
                  <a href="#request-demo">
                    <div className="btnColor rounded-md flex items-start px-6 py-3 justify-center gap-4 mt-0 border-none text-white cursor-pointer text-center">SCHEDULE A CALL</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-10">
              <div className="row align-items-center">
                <div className="col-lg-5 order-lg-last">
                  <div className="kountContentCol">
                    <div className="colBGimg">
                      <img
                        className="b-lazy img-fluid b-loaded"
                        alt="what makes Panopila different"
                        src="../images/mac-image.svg"
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 px-4">
                  <div className="kountContentCol">
                    <p id="offers" className="preHeading">
                      WHAT MAKES PANOPILA SPECIAL
                    </p>
                    <h2 className="header-text text-white flex justify-center md:justify-start mx-3 ">
                      Unique fraud prevention benefits you won’t find anywhere
                      else
                    </h2>
                    <div className="formattedTextBlock header-description">
                      <p className="header-description text-white opacity-75 mx-3">
                        "We don’t think Panopila is better than other service
                        providers — we believe we are <em>different</em>. And
                        there are three things that set us apart from any other
                        fraud prevention company out there.
                      </p>
                      <ol className="header-description text-white opacity-75 flex flex-col justify-center">
                        <li className="text-center md:text-start">Flexible technology</li>
                        <li className="text-center md:text-start">Complete strategy</li>
                        <li className="text-center md:text-start">Unmatched expertise</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="content-container">
        <div
          className="customPageContainer container-fluid two_column_row bgColor bgGrey rowCount_10"
          style={{ paddingBottom: "32px !important" }}
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-8">
                <div className="row align-items-center greyOutline">
                  <div className="col-sm-0 order-lg-last">
                    <div className="kountContentCol"></div>
                  </div>
                  <div className="col-sm-12">
                    <div className="kountContentCol">
                      <h2 className="header-text text-white flex justify-center md:justify-start mx-3">
                        Flexible online fraud prevention features
                      </h2>
                      <div className="formattedTextBlock">
                        <p className="header-description text-white opacity-75 mx-3">
                          Kount’s technology — and the data it’s built on — is
                          truly unique. We provide you the very best
                          capabilities and features to make sure you get
                          outstanding results with the greatest efficiency
                          possible.
                        </p>
                      </div>
                      <div className="row iconBulletRow bulletStyle-icons">
                        <div className="col-auto">
                          <div className="colBGimg">
                            <img
                              className="b-lazy img-fluid b-loaded"
                              alt="Improved data analysis"
                              width="300"
                              height="300"
                              src="../images/mac-image.svg"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <h3 className="header-text text-white "> ROBUST DATA</h3>
                          <p className="header-description text-white opacity-75">
                            Access the industry’s most{" "}
                            <strong>robust database</strong>. Panoplia started
                            collecting data several years before any other
                            service provider. As a bonus, now that we are part
                            of the Equifax family, we have access to consumer
                            insights that date back to 1899. We’ve served
                            thousands of global clients with billions of annual
                            interactions, giving us an unmatched ability to make
                            educated, accurate, data-driven decisions.
                          </p>
                        </div>
                      </div>
                      <div className="row iconBulletRow bulletStyle-icons">
                        <div className="col-auto">
                          <div className="colBGimg">
                            <img
                              className="b-lazy img-fluid b-loaded"
                              alt="Panoplia icon automation"
                              width="80"
                              height="79"
                              src="../images/mac-image.svg"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <h3 className="header-text text-white ">
                            {" "}
                            ADVANCED MACHINE LEARNING
                          </h3>
                          <p className="header-description text-white opacity-75">
                            Trust real-time <strong>automation</strong> driven
                            by advanced{" "}
                            <strong>machine learning in fraud detection</strong>
                            . Panoplia is one of the few service providers on
                            the market today to use two forms of machine
                            learning fraud detection — supervised and
                            unsupervised. Because we offer more innovative
                            technology, you will benefit from better results:
                            enhanced fraud prevention, reduced customer
                            friction, and increased employee efficiency.
                          </p>
                        </div>
                      </div>
                      <div className="row iconBulletRow bulletStyle-icons">
                        <div className="col-auto">
                          <div className="colBGimg">
                            <img
                              className="b-lazy img-fluid b-loaded"
                              alt="machine learning adaptability"
                              width="60"
                              height="61"
                              src="../images/mac-image.svg"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <h3 className="header-text text-white ">
                            {" "}
                            FLEXIBILITY &amp; CUSTOMISATION
                          </h3>
                          <p className="header-description text-white opacity-75">
                            Enjoy a solution that perfectly{" "}
                            <strong>fits your needs</strong>. Choose an
                            integration option that works with your existing
                            processes, resources, and abilities. Then create a
                            customised automation strategy that perfectly aligns
                            with the way you work — high-touch,
                            set-it-and-forget-it, or somewhere in between. And
                            because our technology is flexible, we can keep pace
                            as your business grows.
                          </p>
                        </div>
                      </div>
                      <div className="buttons flex justify-center md:justify-start">
                        <a href="#request-demo">
                          <div className="btnColor rounded-md flex items-center px-6 py-3 justify-center gap-4 mt-0 border-none text-white cursor-pointer text-center w-fit">
                            SEE Panoplia IN ACTION
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <div className="row align-items-center greyOutline">
                <div className="col-sm-0 order-lg-last">
                  <div className="kountContentCol"></div>
                </div>
                <div className="col-sm-12">
                  <div className="kountContentCol">
                    <h2 className="header-text text-white flex justify-center md:justify-start">Complete strategy</h2>
                    <div className="formattedTextBlock">
                      <p className="header-description text-white opacity-75 px-3">
                        "Ecommerce threats are constantly emerging and evolving.
                        That’s why Panopila offers a complete strategy for fraud
                        management. We make sure you are always one step ahead
                        of fraudsters and opportunistic customers.&nbsp;
                      </p>
                    </div>
                    <div className="row iconBulletRow bulletStyle-icons">
                      <div className="col-auto">
                        <div className="colBGimg">
                          <img
                            className="b-lazy img-fluid b-loaded"
                            alt="customer experience"
                            width="76"
                            height="76"
                            src="../images/mac-image.svg"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <h3 className="header-text text-white ">
                          {" "}
                          ACCOUNT CREATION &amp; TAKEOVER
                        </h3>
                        <p className="header-description text-white opacity-75">
                           Detect{" "}
                          <strong>takeover and manipulation threats</strong>{" "}
                          with account protection. Block fake signups with new
                          account fraud prevention. And use loyalty program
                          fraud prevention to stop customers from abusing
                          promotions.
                        </p>
                      </div>
                    </div>
                    <div className="row iconBulletRow bulletStyle-icons">
                      <div className="col-auto">
                        <div className="colBGimg">
                          <img
                            className="b-lazy img-fluid b-loaded"
                            alt="Chargeback alerts"
                            width="80"
                            height="80"
                            src="../images/mac-image.svg"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <h3 className="header-text text-white ">
                          {" "}
                          FRAUDULENT TRANSACTIONS
                        </h3>
                        <p className="header-description text-white opacity-75">
                           Block <strong>unauthorized transactions</strong>{" "}
                          that damage your reputation, increase costs, and cause
                          extra work for your employees. Protect against any
                          fraud threat, including card testing attacks, resell
                          attempts, and personal gain.&nbsp;
                        </p>
                      </div>
                    </div>
                    <div className="row iconBulletRow bulletStyle-icons">
                      <div className="col-auto">
                        <div className="colBGimg">
                          <img
                            className="b-lazy img-fluid b-loaded"
                            alt="Panoplia icon shopping cart"
                            src="../images/mac-image.svg"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <h3 className="header-text text-white "> INVALID CHARGEBACKS</h3>
                        <p className="header-description text-white opacity-75">
                           Manage <strong>chargeback fraud</strong> along with
                          criminal fraud. Use friendly fraud prevention
                          techniques and dispute management software to reduce
                          the risk of invalid chargebacks. Chargeback solutions
                          can even challenge false fraud claims and help you
                          recover lost revenue.
                        </p>
                      </div>
                    </div>
                    <div className="row iconBulletRow bulletStyle-icons">
                      <div className="col-auto">
                        <div className="colBGimg">
                          <img
                            className="b-lazy img-fluid b-loaded"
                            alt="fraud detection, chargeback protection, consumer insights, identity verification"
                            src="../images/mac-image.svg"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <h3 className="header-text text-white ">
                          {" "}
                          NEW &amp; EMERGING ECOMMERCE THREATS
                        </h3>
                        <p className="header-description text-white opacity-75">
                           Confront <strong>emerging trends</strong>{" "}
                          proactively like refund fraud and elder financial
                          exploitation. Use data to detect and prevent
                          fraudsters from taking advantage of vulnerable
                          consumers, policy loopholes, or fraud-as-a-service
                          opportunities.&nbsp;&nbsp;
                        </p>
                      </div>
                    </div>
                    <div className="buttons flex justify-center md:justify-start">
                      <a href="#request-demo">
                        <div className="button solidBlue">
                          GET PROTECTION NOW
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol checklistColumns headerTextAlign-center">
                <p id="offers" className="preHeading">
                  RESULTS YOU DESERVE
                </p>
                <h2 className="header-text text-center text-white ">
                  What you can expect from Panopila
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center text-white opacity-75 px-3">
                    Our clients experience significant improvements in multiple
                    metrics. We don’t solve just part of the problem — we offer
                    a complete solution.&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row multicol justify-content-center textAlignLeft columnStyle-checklistColumns">
                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-checklistColumns checklist">
                    <div className="formattedTextBlock">
                      <ul className="text-white opacity-75 px-4">
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Increase</strong> approval rates.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Boost</strong> revenue and conversion rates.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Improve</strong> customer satisfaction.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Enhance</strong> efficiency and employee
                          morale.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Increase</strong> decision accuracy.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Protect</strong> business reputation and
                          longevity.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg col-md-6 col-sm-12">
                  <div className="kountContentCol d-flex flex-column h-100 mediaLocation-checklistColumns checklist">
                    <div className="formattedTextBlock">
                      <ul className="text-white opacity-75 px-4">
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Lower</strong> false positives.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Block</strong> fraud and chargebacks.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Reduce</strong> friction.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Reduce</strong> manual processes.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Eliminate</strong> hunches and guesses.
                        </li>
                        <li className="liCheck header-description">
                          <img src="../icons/tick-icon.svg" />
                          <strong>Avoid</strong> penalties and fines.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center bottomButtons">
                <div className="col-auto buttons">
                  <a href="#request-demo">
                    <div className="btnColor rounded-md flex items-start px-6 py-3 justify-start gap-4 mt-0 border-none text-white cursor-pointer">SCHEDULE A DEMO</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol fullWidthImageAbove headerTextAlign-center">
                <p id="offers" className="preHeading">
                  AWARD-WINNING
                </p>
                <h2 className="header-text text-center text-white ">
                  Proven-effective fraud prevention
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description  text-center text-white opacity-75">
                    Our technology has won countless awards and recognitions. We
                    are regularly named the industry’s best solution provider by
                    independent analysts. And our satisfied customers are eager
                    to share their praises.&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-fullWidthImageAbove ">
                <div className="flex justify-center textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Juniper Established Leader"
                    width="479"
                    height="545"
                    src="../images/mac-image.svg"
                  />
                </div>
                <div className="formattedTextBlock"></div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-fullWidthImageAbove ">
                <div className="flex justify-center textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Quadrant technology leader"
                    width="479"
                    height="545"
                    src="../images/mac-image.svg"
                  />
                </div>
                <div className="formattedTextBlock"></div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-fullWidthImageAbove ">
                <div className="flex justify-center textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="CNP Best Anti-Fraud Solution"
                    width="479"
                    height="545"
                    src="../images/mac-image.svg"
                  />
                </div>
                <div className="formattedTextBlock"></div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-fullWidthImageAbove ">
                <div className="flex justify-center textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="G2 Leader"
                    width="479"
                    height="545"
                    src="../images/mac-image.svg"
                  />
                </div>
                <div className="formattedTextBlock"></div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12 hidden md:flex">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-fullWidthImageAbove ">
                <div className="flex justify-center textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Capterra reviews"
                    width="479"
                    height="545"
                    src="../images/mac-image.svg"
                  />
                </div>
                <div className="formattedTextBlock"></div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center bottomButtons ">
            <div className="col-auto buttons">
              <div className="btnColor rounded-md flex items-start px-6 py-3 justify-start gap-4 mt-8 border-none text-white cursor-pointer">SEE IT FOR YOURSELF</div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="content-container">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="kountHeaderCol above headerTextAlign-center ">
                <p id="offers" className="preHeading">
                  GROWING WITH PANOPILA
                </p>
                <h2 className="header-text text-center text-white ">
                  More than just fraud prevention software
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-center text-white opacity-75 px-4">
                    Yes, Panopila is really good at detecting and stopping
                    fraudulent activities. But we are also capable of so much
                    more. Learn more about our additional features and
                    solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row multicol justify-content-center textAlignLeft columnStyle-above px-4">
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="panopila icon identity verification"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text text-white ">IDENTITY</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    Use Panopila’s digital identity data and consumer insights
                    to improve marketing initiatives. Add in identity
                    verification software and you’ll have unmatched intelligence
                    on who is interacting with your brand.&nbsp;
                  </p>
                </div>
                <div className="multicolLinkRow row align-items-end">
                  <a
                    className="trust-safety-info"
                    style={{ textDecoration: "none" }}
                  >
                    Check our identity solutions
                    <img src="../icons/arrow-icon.svg"></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="lock credit card"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text text-white">PAYMENTS</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    Enhance every aspect of your payment strategy. From strong
                    customer authentication and payment fraud protection to
                    authorization optimization and chargeback responses,
                    Panopila can do it all.&nbsp;
                  </p>
                </div>
                <div className="multicolLinkRow row align-items-end">
                  <a
                    className="trust-safety-info"
                    style={{ textDecoration: "none" }}
                  >
                    Check our payment solutions
                    <img src="../icons/arrow-icon.svg"></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-12">
              <div className="kountContentCol d-flex flex-column h-100 mediaLocation-above ">
                <div className="colBGimg textAlignLeft ">
                  <img
                    className="b-lazy img-fluid b-loaded"
                    alt="Account Protection Shield and Star"
                    width="1"
                    height="1"
                    src="../images/mac-image.svg"
                  />
                </div>
                <h3 className="header-text text-white ">COMPLIANCE</h3>
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    "Our regulatory compliance software offers full protection:
                    global watchlist search, sanctions screening, customer due
                    diligence, portfolio monitoring, and more. Adhere to rules
                    easily and confidently.&nbsp;
                  </p>
                </div>
                <div className="multicolLinkRow row align-items-end">
                  <a
                    className="trust-safety-info"
                    style={{ textDecoration: "none" }}
                  >
                    Check our compliance solutions
                    <img src="../icons/arrow-icon.svg"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center bottomButtons" style={{marginTop:"3rem"}}>
            <div className="col-auto buttons my-6">
              <button className="btnColor rounded-md flex items-start px-6 py-3 justify-center gap-4 mt-0 border-none text-white cursor-pointer">
                GET COMPLETE PROTECTION
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* second starts here */}
      {/* <div className="content-container">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-6 order-lg-last">
              <div className="kountContentCol">
                <div className="colIframe">
                  <div className="ratio ratio-16x9">
                    <iframe
                      className="b-lazy b-loaded"
                      
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      
                      src="https://www.youtube.com/embed/kiCOnxsBuyM"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="kountContentCol">
                <p id="offers" className="header">
                  CASE STUDY
                </p>
                <h2 className="header-text text-white ">
                  Real-life example of Panopila’s fraud protection
                </h2>
                <div className="formattedTextBlock">
                  <p className="header-description text-white opacity-75">
                    For decades, GNC’s purpose has been to help people around
                    the world live well. And in order to meet that goal, GNC
                    needs the right partners in place to make sure the business
                    is successful. And Panopila is one of those vital partners.
                    Panopila offers a complete solution to detect and prevent
                    fraud, delivering confidence at every point along the
                    customer journey.
                  </p>
                </div>
                <div className="buttons">
                  <a
                    className="trust-safety-info"
                    style={{ textDecoration: "none" }}
                  >
                    View all Panopila case studies
                    <img src="../icons/arrow-icon.svg"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
