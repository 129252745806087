import React, { useState } from "react";
import PopUp from "./PopUp";
export default function Industries() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  return (
    <>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div id="personalized-solution">INDUSTRIES</div>
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Trust and safety solutions for every industry
            </div>
            <div className="fraud-handling-info">
              Want to grow your brand and increase revenue without worrying
              about risks? Kount can help. We understand the challenges you face
              and offer solutions to confidently increase revenue while
              minimizing potential threats every step of the way.
            </div>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
          <div className="wallet-info">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Health and beauty
            </div>
            <div className="fraud-handling-info">
              Kount serves thousands of businesses in the health and beauty
              industry. Learn how customers like GNC use Kount to protect their
              brands from third-party resellers and achieve their companys'
              missions.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about solutions for health and beauty
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Healthcare
            </div>
            <div className="fraud-handling-info">
              Learn how Kount can help you safely verify and authenticate your
              patients while maintaining full compliance with all payment
              industry standards and HIPAA.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about solutions for health care
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
          <div className="wallet-info">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Online learning
            </div>
            <div className="fraud-handling-info">
              Kount has helped companies in the online learning space protect
              against all types of fraud — from card testing to account sharing
              and more. See how we can help your online learning business grow.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about solutions for online learning
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
        </div>
      </div>

      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Online gaming
            </div>
            <div className="fraud-handling-info">
              Account security is everything when it comes to gaming. Hear about
              how Kount helps companies like Drop-In Gaming secure the online
              gaming experience.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about solutions for online gaming
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
          <div className="wallet-info">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Restaurants
            </div>
            <div className="fraud-handling-info">
              In the restaurant industry, every dollar counts. Learn about how
              Kount can manage fraud for your restaurant and provide
              opportunities for growth.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about solutions for restaurants
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="wallet-info">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Streaming services
            </div>
            <div className="fraud-handling-info">
              Password sharing, account takeover, fake streams — no matter the
              type of fraud threat, Kount can help protect profits and grow your
              business.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about solutions for online streaming
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
        </div>
      </div>
      <div className="content-container" style={{ marginTop: "5rem" }}>
        <div className="transaction-image-wrapper">
          <div className="authorized-section" style={{ maxWidth: "38rem" }}>
            <img src="../images/mac-image.svg"></img>
          </div>
          <div className="wallet-info">
            <div
              id="adjust-text"
              className="d-flex align-items-start flex-column"
            >
              Direct sales
            </div>
            <div className="fraud-handling-info">
              See how Kount helps brands like Young Living Essential Oils grow
              their business with the least amount of risk possible.
            </div>
            <a className="trust-safety-info" style={{ textDecoration: "none" }}>
              Learn more about solutions for direct sales
              <img src="../icons/arrow-icon.svg"></img>
            </a>
          </div>
        </div>
      </div>
      <PopUp
        isVisible={isPopUpVisible}
        togglePopUp={togglePopUp}
        closePopUp={closePopUp}
      />
    </>
  );
}
