import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export const opportunityData = [
  {
    position: "Senior Software Engineer",
    modeOfWork: "On-site. Hyderabad, India.",
    icon: (
      <Link to="/careers/softwareengineer" style={{ color: "#CCCECF" }}>
        {" "}
        <FaArrowRight />
      </Link>
    ),
  },
  {
    position: "Hands On Tech Lead",
    modeOfWork: "On-site. Hyderabad, India.",
    icon: (
      <Link to="/careers/techlead" style={{ color: "#CCCECF" }}>
        {" "}
        <FaArrowRight />
      </Link>
    ),
  },
  {
    position: "Data Engineer",
    modeOfWork: "On-site. Hyderabad, India.",
    icon: (
      <Link to="/careers/dataengineer" style={{ color: "#CCCECF" }}>
        {" "}
        <FaArrowRight />
      </Link>
    ),
  },
];

export default function OpenPositions() {
  return (
    <>
      <div
        className="flex-col flex justify-center bg-background-dark items-center"
        style={{ marginTop: "0rem", paddingTop: "0rem" }}
      >
        <div
          className="grid grid-cols-1 lg:grid-cols-2 pt-6 lg:px-8 sm:pt-28"
          style={{
            background:
              "linear-gradient(30deg, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)",
          }}
        >
          <div
            className="wallet-info flex items-start justify-center px-[5px] md:px-4 lg:px-6"
            style={{ maxWidth: "100%" }}
          >
            <div className="text-[#35adf4] text-lg tracking-[0.3375rem] font-base">
              CAREERS
            </div>
            <div className="text-4xl lg:text-4xl text-white font-semibold">
              We're on the hunt for enthusiastic individuals who love what
              they're into.
            </div>
            <div className="fraud-handling-info">
              Embark on an Exciting Journey with Us! We're in Search of
              Enthusiastic Individuals Who Love What They're Into. Join Our Team
              and Let Your Passion Propel You to Success!
            </div>
          </div>
          <div className="mt-0 mb-8 md: flex justify-center">
            <img src="../images/vacancy-icon.png" alt="vacancy" className="" />
          </div>
        </div>
      </div>

      <div className="bg-[#020811] ">
        <h2 className="text-4xl text-white font-gilroy px-6 sm:px-20 pt-8 sm:pt-20">
          Opportunities Available
        </h2>
        <div className="flex flex-col mt-10 mx-6 gap-4 sm:mx-20">
          {opportunityData.map((opportunity, index) => (
            <div key={index} className="bg-[#1B2129] rounded-xl">
              <div className="p-4 flex sm:flex-row items-center justify-between">
                <div className="mb-0 sm:mb-0 sm:mr-4">
                  <h3 className="text-xl sm:text-2xl font-gilroy font-normal text-white">
                    {opportunity.position}
                  </h3>
                  <p
                    className="text-sm sm:text-base text-[#9A9CA0] font-gilroy"
                    style={{ marginBottom: "0rem", paddingBottom: "0.5rem" }}
                  >
                    {opportunity.modeOfWork}
                  </p>
                </div>
                <div className="text-xl">{opportunity.icon}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
