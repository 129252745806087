import React from 'react'

const PriceTable = ({ heading, description, price, features, prefix }) => {
    return (
        <div className="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg  shadow dark:border-gray-600 xl:p-8 bg-[#181818] text-white justify-between">
            <div>
                <h3 className="mb-4 text-2xl font-semibold">{heading}</h3>
                <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    {description}
                </p>
                {price ? <div className="flex justify-center items-baseline my-8">
                    <span className="mr-2 text-5xl font-extrabold">${price}</span>
                    <span className="text-gray-500 dark:text-gray-400">{prefix}</span>
                </div> : <div className="flex justify-center items-baseline my-10">
                    <span className="text-2xl">{prefix}</span>
                </div>}
                {/* List */}
                <ul role="list" className="mb-8 space-y-4 text-left">
                    {features.map((feature) => <li className="flex items-center space-x-3">
                        {/* Icon */}
                        <svg
                            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span>{feature}</span>
                    </li>)}

                </ul>
            </div>
            <a
                href="#"
                className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900 border-2 border-gray-600"
            >
                Get started
            </a>
        </div>
    )
}

export default PriceTable