import React, { useState } from "react";
import PopUp from "./PopUp";

import FintechIndustry from "Components/FintechIndustry";
import LiItems from "Components/LiItems";
import CompanyImage from "Components/CompanyImage";
import ai from "../src/img/ai.svg"
import ai3 from "../src/img/ai3.svg"
import ai4 from "../src/img/ai4.svg"
import ai2 from "../src/img/ai2.svg"
export default function Fintech() {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setPopUpVisible(!isPopUpVisible);
  };

  const closePopUp = () => {
    setPopUpVisible(false);
  };
  const data = [
    {
      id: 1,
      imageUrl: `${ai}`,
      title: 'EFFECTIVE',
      description: 'AI helps your experts spot anomalies and detect unknown crime patterns',
    },
    {
      id: 2,
      imageUrl: `${ai}`,
      title: 'EFFICIENT',
      description: '70% false positive reduction - your team can focus on priority tasks',
    },
    {
      id: 3,
      imageUrl: `${ai3}`,
      title: 'EXPLAINABLE',
      description: "All stakeholders can easily understand and trust our system's results"
    },
    {
      id: 4,
      imageUrl: `${ai4}`,
      title: 'EASY TO DEPLOY',
      description: 'Built with deployment options that can be customized to your needs',
    },
    // Add more entries as needed
  ];
  const dynamicColor = "#35ADF4";
  const companyImages = [
    'https://tse2.mm.bing.net/th?id=OIP.45QTz7h71wWjdg7wx4gjBwHaEK&pid=Api&P=0&h=220',
    'https://tse4.mm.bing.net/th?id=OIP.H08T0TDlvi8tYCYZFYJ_kgHaD4&pid=Api&P=0&h=220',
    'https://imgs.search.brave.com/HXTSkvP5SCjNXaqYkkP89AOVlI_UbjyRC0E-n_6-hps/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWFn/ZS5waXRjaGJvb2su/Y29tL010RzlWVjVo/Y1dEd3RqTUNnVmlC/M2RpRzBXOTE2MTU4/MDIxMTgzNTFfMjAw/eDIwMA',
    'https://www.musicinstrumentnews.co.uk/wp-content/uploads/2022/11/Weavr-logo.png',
    'https://www.musicinstrumentnews.co.uk/wp-content/uploads/2022/11/Weavr-logo.png',
  ];
  const companyImages2 = [
    'https://tse2.mm.bing.net/th?id=OIP.45QTz7h71wWjdg7wx4gjBwHaEK&pid=Api&P=0&h=220',
    'https://tse4.mm.bing.net/th?id=OIP.H08T0TDlvi8tYCYZFYJ_kgHaD4&pid=Api&P=0&h=220',
    'https://imgs.search.brave.com/HXTSkvP5SCjNXaqYkkP89AOVlI_UbjyRC0E-n_6-hps/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWFn/ZS5waXRjaGJvb2su/Y29tL010RzlWVjVo/Y1dEd3RqTUNnVmlC/M2RpRzBXOTE2MTU4/MDIxMTgzNTFfMjAw/eDIwMA',
    'https://www.musicinstrumentnews.co.uk/wp-content/uploads/2022/11/Weavr-logo.png',

  ];

  return (
    <>
      <div className="overflow-y-hidden  ">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 over mt-0 md:pt-[5rem] bg-[#020811]">

<div className="p-3 text-white mx-4 justify-center rounded-md md:order-1 flex flex-col gap-11" style={{ marginTop: "2rem" }}>
  <h1 className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">EASY AML COMPLIANCE AND FRAUD DETECTION</h1>
  <h1 className=" text-2xl md:text-4xl  lg:text-5xl text-white font-semibold">FOR FAST-GROWING FINTECHS </h1>
  <button className="btnColor rounded-md flex items-start px-6 py-2 md:px-8 md:py-3 justify-start gap-4 mt-0 border-none text-white w-fit font-semibold text-base md:text-lg">
   Contact Us
  </button>
</div>


<div className=" flex justify-center order-2 mt-8">
  <img src="https://hawk.ai/sites/default/files/2021-09/keyvisual-career_ani.svg" alt="Image Alt Text" className=" w-10/12 md:w-6/12 object-cover rounded-t-md md:rounded-t-md md:rounded-l-md" />
</div>

</div>

        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:p-10 p-4 lg:pt-[5rem]   ">

          <div className="p-3 text-white justify-center rounded-md md:order-1 flex flex-col gap-11" style={{ marginTop: "2rem" }}>
            <h1 className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium">EASY AML COMPLIANCE AND FRAUD DETECTION</h1>
            <h1 className="text-4xl lg:text-5xl text-white font-semibold">FOR FAST-GROWING FINTECHS
            </h1>
            <button className="btnColor rounded-md flex items-start px-6 py-2 md:px-8 md:py-3 justify-start gap-4 mt-0 border-none text-white w-fit font-semibold text-base md:text-lg">
              Contact Us
            </button>
          </div>


          <div className=" flex justify-center order-2 mt-8">
            <img src="https://hawk.ai/sites/default/files/2021-09/keyvisual-career_ani.svg" alt="Image Alt Text" className=" w-10/12 md:w-6/12 object-cover rounded-t-md md:rounded-t-md md:rounded-l-md" />
          </div>

        </div> */}


<div className="py-16 px-4 text-white mt-24 max-w-[100%]" style={{ background: 'linear-gradient(to bottom, #0f151c,#0f151c, #0f151c,#0f151c,#0f2930)' }}>
      <div className="text-center">
      <h6 className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium text-center mb-4">EXPERT INSIGHT</h6>
        <h2 className="mx-auto text-2xl md:text-4xl lg:text-5xl text-white font-semibold mb-4">THE FUTURE OF TRANSACTION MONITORING</h2>
        <p className="text-white text-left mx-auto">RaptorX:AI voiced key considerations on “Building future state of transaction monitoring <br className="hidden md:flex"></br> & reporting – emerging ways to detect & disrupt financial crime”, read the full panel discussion featuring HSBC, BNP Paribas and AlixPartners.</p>
      </div>
      
      <div className="flex justify-center items-center">
  <button className="btnColor rounded-md flex items-center px-2 py-2 md:px-8 md:py-3 justify-center gap-4 mt-0 border-none text-white w-fit font-semibold md:text-lg text-sm">
    Read The Pannel Discussion Overview
  </button>
</div>
    </div>




        <div className="grid grid-cols-1 md:grid-cols-2  lg:p-8 p-8    " style={{ marginTop: "3rem" }}>
          <div className="text-black md:order-2 mb-4 md:mb-0">
            <img
              src="https://hawk.ai/sites/default/files/2021-09/keyvisual-career_ani.svg"
              alt="Image Alt Text"
              className="object-cover rounded-t-md md:rounded-t-md md:rounded-l-md"
            />
          </div>
          <div className="p-3 text-white rounded-md md:order-1 flex flex-col">
            <h1 className="font-bold text-2xl md:text-4xl lg:text-5xl mb-2">
              THE CHALLENGE KEEPING FAST-GROWING FINTECHS UP AT NIGHT
            </h1>
            <p className="text-sm md:text-base lg:text-lg mb-4">
              Fintechs often focus on product improvement, customer experience, and innovation. Yet, AML compliance and Fraud detection/prevention challenges demand your scarce resources and are a roadblock to growth. Our quick-start Fincrime Surveillance Suite offers industry best practices, combined with AI, to significantly reduce manual overhead before it becomes a problem.
            </p>
            <h6 className="text-white text-sm md:text-base lg:text-lg mb-4">
              Compliance is complex, but it doesn't need to be inefficient, ineffective, or expensive.
            </h6>
            <button className="btnColor text-white w-full md:w-1/3 mt-3 px-3 py-3 rounded-md">
              REQUEST A DEMO
            </button>
          </div>
        </div>



        <h6 className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium lg:p-8 p-8 text-center    " style={{ marginBottom: 0 }}>BUILT IN GERMANY WITH DEEP INDUSTRY KNOWLEDGE</h6>
        <div className="flex flex-wrap gap-3 text-white justify-center    " style={{ paddingTop: "2rem" }}>
          <img
            src={`../images/bankingImg1.svg`}
            alt="Responsive Image"
            className="object-cover w-28 h-28 rounded-md"
          />
          <div>
            <h5>EFFECTIVE</h5>
            <p className="max-w-[11rem] opacity-70">AI helps your experts spot anomalies and detect unknown crime patterns</p>
          </div>
          <img
            src={`../images/bankingImg.svg`}
            alt="Responsive Image"
            className="object-cover w-28 h-28 rounded-md"
          />
          <div>
            <h5>EFFICIENT</h5>
            <p className="max-w-[11rem] opacity-70">70% false positive reduction – your team can focus on priority tasks</p>
          </div>
          <img
            src={`../images/bankingImg3.svg`}
            alt="Responsive Image"
            className="object-cover w-28 h-28 rounded-md"
          />
          <div>
            <h5>EXPLAINABLE</h5>
            <p className="max-w-[11rem] opacity-70">All stakeholders can easily understand and trust our system's results</p>
          </div>
          <img
            src={`../images/bankingImg4.svg`}
            alt="Responsive Image"
            className="object-cover w-28 h-28 rounded-md"
          />
          <div>
            <h5>EASY TO DEPLOY</h5>
            <p className="max-w-[11rem] opacity-70">Built with deployment options that can be customized to your needs</p>
          </div>
        </div>



        <div className="flex justify-center md:justify-start  ml-0   " style={{ paddingTop: "4rem", paddingLeft: "2rem" }}>
          <h6 className="text-[#35adf4] text-lg tracking-[0.3375rem] font-medium text-center  mb-0 mx-4 md:mr-0">WHAT FAST-GROWING FINTECHS NEED TO KNOW ABOUT AML</h6>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2    lg:p-8 p-8 " style={{ paddingTop: "-1rem" }}>


          <div className=" text-black md:order-2   " >
            <img src="https://hawk.ai/sites/default/files/2021-09/keyvisual-career_ani.svg" alt="Image Alt Text" className="object-cover rounded-t-md md:rounded-t-md md:rounded-l-md" />


          </div>
          <div className="p-3 text-white rounded-md flex    flex-col" style={{ marginTop: "3rem" }}>
            <h1 className="font-bold text-2xl md:text-4xl lg:text-5xl mb-2">
              THE KEY CONSIDERATIONS FOR FAST-GROWING FINTECHS LOOKING FOR AML SOLUTIONS
            </h1>
            <h6 className="text-white text-sm md:text-base lg:text-lg mb-4">
              Partner with compliance experts that can support your AML and Fraud Surveillance journey. Our package offers everything you need to get started:
            </h6>
            <ul className="list-disc text-sm md:text-base lg:text-lg mb-4">
              <li>Expert consulting</li>
              <li>Ready-to-use ruleset</li>
              <li>Guidelines & Best Practices</li>
            </ul>
            <h6 className="text-white text-sm md:text-base lg:text-lg">
              Our off-the-shelf package offers the technology you need to start addressing compliance, including:
            </h6>
            <LiItems />
            <h6 className="text-white text-sm md:text-base lg:text-lg mb-4">
              Our off-the-shelf package offers the technology you need to start addressing compliance, including:
            </h6>
            <ul className="list-disc text-sm md:text-base lg:text-lg">
              <li>AI False Positive Reduction - Constant learning delivering 70%+ false positives reduction, avoiding staff overheads</li>
              <li>Case Investigator - Workflows, 360° customer view, audit trail, and explanations</li>
              <li>Configuration Manager - No-code configuration of workflows, typologies & rules</li>
            </ul>
            <button className="btnColor text-white w-full md:w-1/3 mt-3 px-3 py-3 rounded-md">
              REQUEST A DEMO
            </button>
          </div>


        </div>




      </div>

    </>
  );
}
