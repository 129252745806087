import React from 'react'

const TickList = ({ heading, desc, list, icon }) => {
    return (
        
            <div className="d-flex flex-column h-100 checklist mt-16">
                <div className="transaction-title">
                    {icon && <img src={icon}></img>}
                    <h1>{heading}</h1>
                </div>
                <p className='text-[#B3B5B8] text-[16px] mt-2'>{desc}</p>
                <div className="formattedTextBlock header-description mt-4" style={{color:"#d1d1d1"}}>
                    <ul>
                        {list.map((item) => {
                            return <li className="flex items-center liWarning gap-3 mt-3">
                                <img src="icons/tick-icon.svg" />
                                <span className='text-[16px] text-[#B3B5B8]'>{item}</span>
                            </li>
                        })}


                    </ul>

                </div>
            </div>
        

    )
}

export default TickList