import React from 'react'

const BankingTickList = ({ heading, desc, list, listHead}) => {
    return (
        <div className="d-flex flex-column h-100 checklist mt-4">
                <div className="transaction-title">
                    <h1>{heading}</h1>
                </div>
                <p className='text-[#B3B5B8] text-[16px] mt-2'>{desc}</p>
                <p className='text-[#FFFFFFCC] text-[20px] mt-2'>{listHead}</p>
                <div className="formattedTextBlock header-description" style={{color:"white"}}>
                    <ul>
                        {list.map((item) => {
                            return <li className="flex items-center liWarning gap-3 mt-3">
                                <img src="../icons/tick-icon.svg" />
                                <span className='text-[16px] text-[#E6E6E7]'>{item}</span>
                            </li>
                        })}


                    </ul>

                </div>
            </div>
    )
}

export default BankingTickList