import React from 'react'

const NumberCard = ({ icon, heading, desc }) => {
    return (
        <div>
            <div className="transaction-title">
                <img src={icon} alt="Icon"></img>
                <h1>{heading}</h1>
            </div>
            <div className="fraud-handling-info mt-3">
                {desc}
            </div>
        </div>
    )
}

export default NumberCard